import { USER_PROFILE_REQUEST ,USER_PROFILE_SUCCESS ,USER_PROFILE_FAIL ,
         USER_PROFILE_REQUEST_UPDATE ,USER_PROFILE_SUCCESS_UPDATE,USER_PROFILE_FAIL_UPDATE
} from "../constants/profileConstants";

import { CHANGE_USER_LOGIN_REQUEST ,CHANGE_USER_LOGIN_SUCCESS ,CHANGE_USER_LOGIN_FAIL} from "../constants/profileConstants";

import axios from "axios";


export const getUerProfileAction=(user_id_id)=>async(dispatch)=>{
    try {

        dispatch({type:USER_PROFILE_REQUEST})

    
        const {data} = await axios.get(`api/user/profile/${user_id_id}`)

        dispatch({type:USER_PROFILE_SUCCESS , payload:data})

        localStorage.setItem('userProfileInfo' , JSON.stringify(data))
        
    } catch (error) {
        dispatch({
            type : USER_PROFILE_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    })
    }

}


export  const updateUserProfileAction=(profile)=>async(dispatch)=>{

    try {

        dispatch({type:USER_PROFILE_REQUEST_UPDATE})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.put(`api/updateUserProfileByUser/${profile.trans_code}/`,profile ,config)

        dispatch({type:USER_PROFILE_SUCCESS_UPDATE , payload:data})
        
    } catch (error) {

        dispatch({
            type : USER_PROFILE_FAIL_UPDATE , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export const changelogin = (username , password ) => async(dispatch)=>{

    try {

       dispatch({type:CHANGE_USER_LOGIN_REQUEST})

       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.post('api/users/login/' ,{'username':username , 'password':password } ,config)

       dispatch({type:CHANGE_USER_LOGIN_SUCCESS , payload:data})
       
    } catch (error) {

       dispatch({type:CHANGE_USER_LOGIN_FAIL , 
               payload:error.response && error.response.data.detail ?
               error.response.data.detail:
               error.message,
       })

       
    }  

}