import { USER_LOGIN_REQUEST
         , USER_LOGIN_SUCCESS
         , USER_LOGIN_FAIL 
 
         , USER_LOGOUT ,

         USER_REGISTER_REQUEST ,
         USER_REGISTER_SUCCESS ,
         USER_REGISTER_FAIL, 
         

         USER_DETAILS_REQUEST ,
         USER_DETAILS_SUCCESS ,
         USER_DETAILS_FAIL, 
         USER_DETAILS_RESET ,

         USER_UPDATE_PROFILE_SUCCESS,
         USER_UPDATE_PROFILE_REQUEST,
         USER_UPDATE_PROFILE_FAIL,

         USER_REQUEST,USER_SUCCESS,USER_FAIL ,

         USER_REQUEST_MENU ,USER_SUCCESS_MENU,USER_FAIL_MENU ,

         SELETED_USER_DETAIL_REQUEST ,SELETED_USER_DETAIL_SUCCESS ,SELETED_USER_DETAIL_FAIL ,

         USER_REQUEST_MENU_ITEMS ,USER_SUCCESS_MENU_ITEMS , USER_FAIL_MENU_ITEMS ,

         USER_REQUEST_MENU_ITEMS_ADMIN  ,USER_SUCCESS_MENU_ITEMS_ADMIN  ,USER_FAIL_MENU_ITEMS_ADMIN  ,

         USER_REQUEST_MENU_ITEMS_DELETE ,USER_SUCCESS_MENU_ITEMS_DELETE ,USER_FAIL_MENU_ITEMS_DELETE ,

         USER_REQUEST_UDATE_BRANNCH_LEVEL,USER_SUCCESS_UDATE_BRANNCH_LEVEL,USER_FAIL_UDATE_BRANNCH_LEVEL ,

         SELETED_PROFILE_DETAIL_REQUEST , SELETED_PROFILE_DETAIL_SUCCESS , SELETED_PROFILE_DETAIL_FAIL  ,


 } from "../constants/userConstants";

 import { USER_PROFILE_RESET  } from "../constants/profileConstants";

 import  {USER_DEPOSIT_TOTAL_RESET} from '../constants/customerAccountConstants';

 

 import axios from 'axios'


 //----------------------------------------------------------------LOGIN
 export const login = (username , password ) => async(dispatch)=>{

     try {

        dispatch({type:USER_LOGIN_REQUEST})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.post('api/users/login/' ,{'username':username , 'password':password } ,config)

        dispatch({type:USER_LOGIN_SUCCESS , payload:data})
        

        localStorage.setItem('userInfo' , JSON.stringify(data))

        
     } catch (error) {

        dispatch({type:USER_LOGIN_FAIL , 
                payload:error.response && error.response.data.detail ?
                error.response.data.detail:
                error.message,
        })

        
     }  

 }
 

 //---------------------------------------------------------------LOGOUR

 export const logout =() => (dispatch) =>{
   localStorage.removeItem('userInfo')
   localStorage.removeItem('userProfileInfo')
   localStorage.removeItem('bankInfo')
   localStorage.removeItem('branchsInfo')
   localStorage.removeItem('products')
   localStorage.removeItem('services')
   localStorage.removeItem('MemberInfo')
   localStorage.removeItem('MenuItems')
   localStorage.removeItem('AllUser')
   localStorage.removeItem('expireTime')

   dispatch({type:USER_LOGOUT})
   dispatch({type : USER_DETAILS_RESET})
   dispatch({type : USER_PROFILE_RESET})
   dispatch({type:USER_DEPOSIT_TOTAL_RESET})


 }




 //----------------------------------------------------------------REGISTER USER
 export const register = (name ,username ,loginstatus ,password , email) => async(dispatch) =>{
   try {
      dispatch({type:USER_REGISTER_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/user/register/' , 
      {'name': name , 'username':username ,'loginstatus':loginstatus , 'password':password ,'email':email } ,config)

      dispatch({type: USER_REGISTER_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

      
   } catch (error) {

      dispatch({type:USER_REGISTER_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const getUserDetails =(id) => async (dispatch , getState)=>{

   try {

      dispatch({type:USER_DETAILS_REQUEST})

      const {userLogin:{ userInfo } } = getState()

      const config = {headers : {'Content-type': 'application/json' ,  Authorization :`Bearer ${userInfo.token}`}}

     

      const {data} = await axios.get(`api/user/${id}/`, config)

      dispatch({type:USER_DETAILS_SUCCESS , payload:data})

   } catch (error) {

      dispatch({type:USER_DETAILS_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const updateUserProfile = (user)=> async (dispatch , getState) =>{

   try {

      dispatch({type:USER_UPDATE_PROFILE_REQUEST})

      const {userLogin:{ userInfo } ,} = getState()

      const config = {headers : {'Content-type': 'application/json' ,  Authorization :`Bearer ${userInfo.token}`}}

      const {data} = await axios.put('api/user/profile/update/' ,user ,config) 

      dispatch({type:USER_UPDATE_PROFILE_SUCCESS , payload:data})

      dispatch({type:USER_LOGIN_SUCCESS , payload:data})

      localStorage.setItem('userInfo' ,JSON.stringify(data))

      
   } catch (error) {

      dispatch({type:USER_UPDATE_PROFILE_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }


 }

 export const UserList= () =>async(dispatch) =>{

   try {

       dispatch({type:USER_REQUEST})

       const {data} = await axios.get('/api/getAllUser/')

       dispatch({type:USER_SUCCESS , payload:data})

       localStorage.setItem('AllUser' ,JSON.stringify(data))

       
   } catch (error) {

       dispatch({
           type : USER_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
       
   }

}


export const registerMenuItems = (path ,name ,icon ,user_id_id ,narration) => async(dispatch) =>{
   try {
      dispatch({type:USER_REQUEST_MENU})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveMenu/' , 
      {'path': path , 'name':name ,'icon':icon , 'user_id_id':user_id_id ,'narration':narration } ,config)

      dispatch({type: USER_SUCCESS_MENU, payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

      
   } catch (error) {

      dispatch({type:USER_FAIL_MENU , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const SeletedUserDetails =(id) => async (dispatch)=>{

   try {

      dispatch({type:SELETED_USER_DETAIL_REQUEST})

     
      const {data} = await axios.get(`api/getSelectedUserInfo/${id}/`)

      dispatch({type:SELETED_USER_DETAIL_SUCCESS , payload:data})

   } catch (error) {

      dispatch({type:SELETED_USER_DETAIL_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const UserMenuItemsActions =(user_id_id) => async (dispatch)=>{

   try {

      dispatch({type:USER_REQUEST_MENU_ITEMS})

     
      const {data} = await axios.get(`api/getUserMeunItems/?user_id_id=${user_id_id}`)

      
      dispatch({type:USER_SUCCESS_MENU_ITEMS , payload:data})

      localStorage.setItem('MenuItems' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:USER_FAIL_MENU_ITEMS , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 
 export const UserMenuItemsActionsAdmin =(user_id_id) => async (dispatch)=>{

   try {

      dispatch({type:USER_REQUEST_MENU_ITEMS_ADMIN})

     
      const {data} = await axios.get(`api/getUserMeunItemsAdmiv/?user_id_id=${user_id_id}`)

      
      dispatch({type:USER_SUCCESS_MENU_ITEMS_ADMIN , payload:data})

      localStorage.setItem('MenuItems' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:USER_FAIL_MENU_ITEMS_ADMIN , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const deleteUserMenuSelected=(trans_code)=>async(dispatch)=>{
   try {

       dispatch({type:USER_REQUEST_MENU_ITEMS_DELETE})

       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.delete(`/api/delete_UserMenuItem/${trans_code}/ `,config)

       dispatch({type:USER_SUCCESS_MENU_ITEMS_DELETE ,payload:data})


   } catch (error) {

       dispatch({
           type : USER_FAIL_MENU_ITEMS_DELETE , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
   }
} 



export  const updateUserBranchLevel=(profile)=>async(dispatch)=>{

   try {

       dispatch({type:USER_REQUEST_UDATE_BRANNCH_LEVEL})

       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/updateUserBranh_level/${profile.user_id_id}/`,profile ,config)

       dispatch({type:USER_SUCCESS_UDATE_BRANNCH_LEVEL , payload:data})
       
   } catch (error) {

       dispatch({
           type : USER_FAIL_UDATE_BRANNCH_LEVEL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
   }

} 



export const SeletedProfileDetails =(user_id_id) => async (dispatch)=>{

   try {

      dispatch({type:SELETED_PROFILE_DETAIL_REQUEST})

     
      const {data} = await axios.get(`api/getUserSelectedProfie/${user_id_id}/`)

      dispatch({type:SELETED_PROFILE_DETAIL_SUCCESS , payload:data})

   } catch (error) {

      dispatch({type:SELETED_PROFILE_DETAIL_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
