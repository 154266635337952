import { BANK_REQUEST_INFO ,BANK_SUCCESS_INFO ,BANK_FAIL_INFO ,BANK_RESET ,
    BANK_REQUEST_INFO_UPDATE , BANK_SUCCESS_INFO_UPDATE  ,BANK_FAIL_INFO_UPDATE  ,
    BRANCH_REGISTRATION_REQUEST ,BRANCH_REGISTRATION_SUCCESS ,BRANCH_REGISTRATION_FAIL,BRANCH_REGISTRATION_RESET ,
    BRANCH_REQUEST ,BRANCH_SUCCESS ,BRANCH_FAIL ,BRANCH_RESET ,
    BRANCH_DETAILS_REQUEST ,BRANCH_DETAILS_SUCCESS ,BRANCH_DETAILS_FAIL ,BRANCH_DETAILS_RESET ,

    BRANCH_DETAILS_REQUEST_UPDATE ,BRANCH_DETAILS_SUCCESS_UPDATE ,BRANCH_DETAILS_FAIL_UPDATE  , BRANCH_DETAILS_RESET_UPDATE ,

    PRODUCT_REGISTRATION_REQUEST , PRODUCT_REGISTRATION_SUCCESS,PRODUCT_REGISTRATION_FAIL,PRODUCT_REGISTRATION_RESET ,

    PRODUCT_REQUEST ,PRODUCT_SUCCESS ,PRODUCT_FAIL ,PRODUCT_RESET ,

    PRODUCT_REQUEST_UPDATE , PRODUCT_SUCCESS_UPDATE , PRODUCT_FAIL_UPDATE ,

    SERVICE_REGISTRATION_REQUEST ,SERVICE_REGISTRATION_SUCCESS ,SERVICE_REGISTRATION_FAIL ,SERVICE_REGISTRATION_RESET ,

    SERVICE_REQUEST ,SERVICE_SUCCESS ,SERVICE_FAIL ,SERVICE_RESET  ,

    SERVICE_REQUEST_UPDATE ,SERVICE_SUCCESS_UPDATE ,SERVICE_FAIL_UPDATE  , SERVICE_RESET_UPDATE,

    CHARTOFACCOUNT_SAVE_REQUEST,CHARTOFACCOUNT_SAVE_SUCCESS,CHARTOFACCOUNT_SAVE_FAIL,CHARTOFACCOUNT_SAVE_RESET ,

    CHARTOFACCOUNT_REQUEST ,CHARTOFACCOUNT_SUCCESS ,CHARTOFACCOUNT_FAIL ,CHARTOFACCOUNT_RESET

   

} from "../constants/settingConstants";




export const bankInfoReducer=(state={bankInfo:{}},action)=>{

    switch(action.type){
        case BANK_REQUEST_INFO:
            return{bankInfoLoading:true ,...state }

        case BANK_SUCCESS_INFO:
            return{bankInfoLoading:false ,bankInfo:action.payload}  

        case BANK_FAIL_INFO:
            return {bankInfoLoading:false , error:action.payload}

        case BANK_RESET:
            return {bankInfo:{}}
        
        default:
            return state

    }

}

export const updateBankInfo=(state={bankInfoReducerUpdate:{}},action)=>{

    switch(action.type){
        case BANK_REQUEST_INFO_UPDATE :
            return{bankInfoReducerUpdateLoading:true ,...state }

        case BANK_SUCCESS_INFO_UPDATE :
            return{bankInfoReducerUpdateLoading:false ,bankInfoReducerUpdate:action.payload}  

        case BANK_FAIL_INFO_UPDATE :
            return {bankInfoReducerUpdateLoading:false , error:action.payload}
        
        default:
            return state

    }

}


export const registerBranchReducer=(state={registerBranchInfo:{}},action)=>{

    switch(action.type){
        case BRANCH_REGISTRATION_REQUEST :
            return{registerBranchLoading:true  }

        case BRANCH_REGISTRATION_SUCCESS :
            return{registerBranchLoading:false , success:true , registerBranch:action.payload}  

        case BRANCH_REGISTRATION_FAIL :
            return {registerBranchLoading:false , error:action.payload}

        case BRANCH_REGISTRATION_RESET:
            return{registerBranchInfo:{}}
        
        default:
            return state

    }

}


export const branchDetailsReducer =(state ={ branchsInfo:[]} ,action)=>{
    switch(action.type){
        case BRANCH_REQUEST:
            return{loadingbranchs:true, ...state}
        
        case BRANCH_SUCCESS:
            return{loadingbranchs:false , branchsInfo:action.payload} 
        
        case BRANCH_FAIL:
            return{loadingbranchs:false , error:action.payload}


        case BRANCH_RESET:
            return {branchsInfo:{}}

        default:
            return state
    }

    
}



export const selectedBranchDetailsReducer =(state ={ selectedBranch:{}} ,action)=>{
    switch(action.type){
        case BRANCH_DETAILS_REQUEST:
            return{loadingselectedBranch:true, ...state}
        
        case BRANCH_DETAILS_SUCCESS:
            return{loadingselectedBranch:false , Brancsuccess:true , selectedBranch:action.payload} 
        
        case BRANCH_DETAILS_FAIL:
            return{loadingselectedBranch:false , error:action.payload}


        case BRANCH_DETAILS_RESET:
            return {selectedBranch:{}}

        default:
            return state
    }

    
}


export const branchReducerUpdate=(state={branchInfoUpdate:{}},action)=>{

    switch(action.type){
        case BRANCH_DETAILS_REQUEST_UPDATE :
            return{branchInfoUpdateLoading:true ,...state }

        case BRANCH_DETAILS_SUCCESS_UPDATE :
            return{branchInfoUpdateLoading:false , updatebranch:true , branchInfoUpdate:action.payload}  

        case BRANCH_DETAILS_FAIL_UPDATE :
            return {branchInfoUpdateLoading:false , error:action.payload}

        case BRANCH_DETAILS_RESET_UPDATE:
            return {branchInfoUpdate:{}}
        
        default:
            return state

    }

}


export const registerProductReducer=(state={registerProductInfo:{}},action)=>{

    switch(action.type){
        case PRODUCT_REGISTRATION_REQUEST :
            return{registerProductLoading:true  }

        case PRODUCT_REGISTRATION_SUCCESS :
            return{registerProductLoading:false , productSuccess:true , registerProductInfo:action.payload}  

        case PRODUCT_REGISTRATION_FAIL :
            return {registerProductLoading:false , error:action.payload}

        case PRODUCT_REGISTRATION_RESET:
            return{registerProductInfo:{}}
        
        default:
            return state

    }

}


export const productReducer =(state ={ products:[]} ,action)=>{
    switch(action.type){
        case PRODUCT_REQUEST:
            return{loadingproduct:true, ...state}
        
        case PRODUCT_SUCCESS:
            return{loadingproduct:false , products:action.payload} 
        
        case PRODUCT_FAIL:
            return{loadingproduct:false , error:action.payload}


        case PRODUCT_RESET:
            return {products:{}}

        default:
            return state
    }

    
}


export const updateProduct=(state={productUpdate:{}},action)=>{

    switch(action.type){
        case PRODUCT_REQUEST_UPDATE :
            return{productUpdateLoading:true ,...state }

        case PRODUCT_SUCCESS_UPDATE :
            return{productUpdateLoading:false ,productUpdate:action.payload}  

        case PRODUCT_FAIL_UPDATE :
            return {productUpdateLoading:false , error:action.payload}
        
        default:
            return state

    }

}


export const registerServiceReducer=(state={registerSerivceInfo:{}},action)=>{

    switch(action.type){
        case SERVICE_REGISTRATION_REQUEST :
            return{registerseriverLoading:true  }

        case SERVICE_REGISTRATION_SUCCESS :
            return{registerseriverLoading:false , success:true , registerSerivceInfo:action.payload}  

        case SERVICE_REGISTRATION_FAIL :
            return {registerseriverLoading:false , error:action.payload}

        case SERVICE_REGISTRATION_RESET:
            return{registerSerivceInfo:{}}
        
        default:
            return state

    }

}

export const seriveReducer =(state ={ services:[]} ,action)=>{
    switch(action.type){
        case SERVICE_REQUEST:
            return{loadingservices:true, ...state}
        
        case SERVICE_SUCCESS:
            return{loadingservices:false , services:action.payload} 
        
        case SERVICE_FAIL:
            return{loadingservices:false , errorservices:action.payload}


        case SERVICE_RESET:
            return {services:{}}

        default:
            return state
    }

    
}


export const updateService=(state={serviceUpdate:{}},action)=>{

    switch(action.type){
        case SERVICE_REQUEST_UPDATE :
            return{serviceUpdateLoading:true ,...state }

        case SERVICE_SUCCESS_UPDATE :
            return{serviceUpdateLoading:false ,serviceSucces:true ,serviceUpdate:action.payload}  

        case SERVICE_FAIL_UPDATE :
            return {serviceUpdateLoading:false , serviceError:action.payload}

        case SERVICE_RESET_UPDATE:
            return {serviceUpdate:{}}
        
        default:
            return state

    }

}


export const ChartOfAccountReducer =(state ={ chartOfAccount:[]} ,action)=>{
    switch(action.type){
        case CHARTOFACCOUNT_REQUEST:
            return{loadingchartOfAccount:true, ...state}
        
        case CHARTOFACCOUNT_SUCCESS:
            return{loadingchartOfAccount:false , chartOfAccount:action.payload} 
        
        case CHARTOFACCOUNT_FAIL:
            return{loadingchartOfAccount:false , error:action.payload}


        case CHARTOFACCOUNT_RESET:
            return {chartOfAccount:{}}

        default:
            return state
    }

    
}


export const saveChartOfAccount=(state={},action)=>{

    switch(action.type){
        case CHARTOFACCOUNT_SAVE_REQUEST :
            return{chartOfAccountSaveLoading:true  }

        case CHARTOFACCOUNT_SAVE_SUCCESS :
            return{chartOfAccountSaveLoading:false  , chartOfAccountsu:true ,chartOfAccountSave:action.payload}  

        case CHARTOFACCOUNT_SAVE_FAIL :
            return {chartOfAccountSaveLoading:false , error:action.payload}

        case CHARTOFACCOUNT_SAVE_RESET:
            return {chartOfAccountSave:{}}
        
        default:
            return state

    }

}