export const MEMBER_REGISTRATION_REQUEST='MEMBER_REGISTRATION_REQUEST'
export const MEMBER_REGISTRATION_SUCCESS='MEMBER_REGISTRATION_SUCCESS'
export const MEMBER_REGISTRATION_FAIL='MEMBER_REGISTRATION_FAIL'
export const MEMBER_REGISTRATION_RESET='MEMBER_REGISTRATION_RESET'


export const MEMBER_REQUEST='MEMBER_REQUEST'
export const MEMBER_SUCCESS='MEMBER_SUCCESS'
export const MEMBER_FAIL='MEMBER_FAIL'
export const MEMBER_RESET='MEMBER_RESET'

export const MEMBER_REQUEST_DETAILS='MEMBER_REQUEST_DETAILS'
export const MEMBER_SUCCESS_DETAILS='MEMBER_SUCCESS_DETAILS'
export const MEMBER_FAIL_DETAILS='MEMBER_FAIL_DETAILS'
export const MEMBER_RESET_DETAILS='MEMBER_RESET_DETAILS'


export const MEMBER_UPDATE_REQUEST='MEMBER_UPDATE_REQUEST'
export const MEMBER_UPDATE_SUCCESS='MEMBER_UPDATE_SUCCESS'
export const MEMBER_UPDATE_FAIL='MEMBER_UPDATE_FAIL'
export const MEMBER_UPDATE_RESET='MEMBER_UPDATE_RESET'