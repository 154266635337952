export const BANK_REQUEST_INFO='BANK_REQUEST_INFO'
export const BANK_SUCCESS_INFO='BANK_SUCCESS_INFO'
export const BANK_FAIL_INFO='BANK_FAIL_INFO'
export const BANK_RESET='BANK_RESET'


export const BANK_REQUEST_INFO_UPDATE='BANK_REQUEST_INFO_UPDATE'
export const BANK_SUCCESS_INFO_UPDATE='BANK_SUCCESS_INFO_UPDATE'
export const BANK_FAIL_INFO_UPDATE='BANK_FAIL_INFO_UPDATE'


export const BRANCH_REGISTRATION_REQUEST='BRANCH_REGISTRATION_REQUEST'
export const BRANCH_REGISTRATION_SUCCESS='BRANCH_REGISTRATION_SUCCESS'
export const BRANCH_REGISTRATION_FAIL='BRANCH_REGISTRATION_FAIL'
export const BRANCH_REGISTRATION_RESET='BRANCH_REGISTRATION_RESET'


export const BRANCH_REQUEST='BRANCH_REQUEST'
export const BRANCH_SUCCESS='BRANCH_SUCCESS'
export const BRANCH_FAIL='BRANCH_FAIL'
export const BRANCH_RESET='BRANCH_RESET'


export const BRANCH_DETAILS_REQUEST='BRANCH_DETAILS_REQUEST'
export const BRANCH_DETAILS_SUCCESS='BRANCH_DETAILS_SUCCESS'
export const BRANCH_DETAILS_FAIL='BRANCH_DETAILS_FAIL'
export const BRANCH_DETAILS_RESET='BRANCH_DETAILS_RESET'


export const BRANCH_DETAILS_REQUEST_UPDATE='BRANCH_DETAILS_REQUEST_UPDATE'
export const BRANCH_DETAILS_SUCCESS_UPDATE='BRANCH_DETAILS_SUCCESS_UPDATE'
export const BRANCH_DETAILS_FAIL_UPDATE='BRANCH_DETAILS_FAIL_UPDATE'
export const BRANCH_DETAILS_RESET_UPDATE='BRANCH_DETAILS_RESET_UPDATE'



export const PRODUCT_REGISTRATION_REQUEST='PRODUCT_REGISTRATION_REQUEST'
export const PRODUCT_REGISTRATION_SUCCESS='PRODUCT_REGISTRATION_SUCCESS'
export const PRODUCT_REGISTRATION_FAIL='PRODUCT_REGISTRATION_FAIL'
export const PRODUCT_REGISTRATION_RESET='PRODUCT_REGISTRATION_RESET'

export const PRODUCT_REQUEST='PRODUCT_REQUEST'
export const PRODUCT_SUCCESS='PRODUCT_SUCCESS'
export const PRODUCT_FAIL='PRODUCT_FAIL'
export const PRODUCT_RESET='PRODUCT_RESET'


export const PRODUCT_REQUEST_UPDATE='PRODUCT_REQUEST_UPDATE'
export const PRODUCT_SUCCESS_UPDATE='PRODUCT_SUCCESS_UPDATE'
export const PRODUCT_FAIL_UPDATE='PRODUCT_FAIL_UPDATE'
export const PRODUCT_RESET_UPDATE='PRODUCT_RESET_UPDATE'



export const SERVICE_REGISTRATION_REQUEST='SERVICE_REGISTRATION_REQUEST'
export const SERVICE_REGISTRATION_SUCCESS='SERVICE_REGISTRATION_SUCCESS'
export const SERVICE_REGISTRATION_FAIL='SERVICE_REGISTRATION_FAIL'
export const SERVICE_REGISTRATION_RESET='SERVICE_REGISTRATION_RESET'


export const SERVICE_REQUEST='SERVICE_REQUEST'
export const SERVICE_SUCCESS='SERVICE_SUCCESS'
export const SERVICE_FAIL='SERVICE_FAIL'
export const SERVICE_RESET='SERVICE_RESET'


export const SERVICE_REQUEST_UPDATE='SERVICE_REQUEST_UPDATE'
export const SERVICE_SUCCESS_UPDATE='SERVICE_SUCCESS_UPDATE'
export const SERVICE_FAIL_UPDATE='SERVICE_FAIL_UPDATE'
export const SERVICE_RESET_UPDATE='SERVICE_RESET_UPDATE'


export const CHARTOFACCOUNT_SAVE_REQUEST='CHARTOFACCOUNT_SAVE_REQUEST'
export const CHARTOFACCOUNT_SAVE_SUCCESS='CHARTOFACCOUNT_SAVE_SUCCESS'
export const CHARTOFACCOUNT_SAVE_FAIL='CHARTOFACCOUNT_SAVE_FAIL'
export const CHARTOFACCOUNT_SAVE_RESET='CHARTOFACCOUNT_SAVE_RESET'


export const CHARTOFACCOUNT_REQUEST='CHARTOFACCOUNT_REQUEST'
export const CHARTOFACCOUNT_SUCCESS='CHARTOFACCOUNT_SUCCESS'
export const CHARTOFACCOUNT_FAIL='CHARTOFACCOUNT_FAIL'
export const CHARTOFACCOUNT_RESET='CHARTOFACCOUNT_RESET'






