import React, { useState, useEffect, useRef } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetailsAction } from '../actions/customerAccountAction';
import { getMemberDetailsAction } from '../actions/memberAction';
import { FcHighPriority } from "react-icons/fc";
import { loadCustomerAccountAction } from '../actions/customerAccountAction';
import Loader from '../Components/Loader';
import moment from 'moment'
import { CustomerAccountAutoServicesAction } from '../actions/customerAccountAction';
import { LOAD_AUTO_RESET, CUSTOMER_ACCOUNTS_RESET_DETAILS } from '../constants/customerAccountConstants';
import { Modal } from 'react-bootstrap';
import { FcCheckmark } from "react-icons/fc";
import { FaUserLarge } from "react-icons/fa6";
import { PiSignatureThin } from "react-icons/pi";
import { AccountAountServicerAction } from '../actions/customerAccountAction';
import { ACCOUNT_AUTO_SERVICE_RESET } from '../constants/customerAccountConstants';
import { messageListAction } from '../actions/approvalAction';
import { ErrorMessage, successMessage } from './Message';
import { aprMessageAction } from '../actions/approvalAction';
import { APPROVAL_MESSAGE_SAVE_REST } from '../constants/approvalConstants';
import useSound from 'use-sound';
import messagersent from '../images/messagersent.wav'
import ImageUploading from "react-images-uploading";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import Camera from 'react-html5-camera-photo';
import { editCustomercompanysignatory, getAaccountSignatoryDetailsEditAction ,editCustomercompanysignatorycor ,changeAcountName } from '../actions/customerAccountAction';
import { FiEdit2 } from "react-icons/fi";
import { resizeFile } from '../Components/Message';


function CustomerAccount() {

    const dispatch = useDispatch()
    const [pic, setpic] = useState('')
    const [images, setImages] = React.useState([]);
    const [signature, setSignature] = useState('')
    const [picImages2, setpicImages2] = useState([]);
    const maxNumber = 69;
    const [ty, setty] = useState('')
    const [tyid, settyid] = useState('')
    const [showp, setShow] = useState(false)

    const [accname ,setaccname]=useState(false)

    const [newAccountName ,setnewAccountName]=useState('')

    const [getpic, setgetpic] = useState('')

    const [images1, setImages1] = useState([]);




    const [picImages1, setpicImages1] = useState('');

    const [picImages22, setpicImages22] = useState('');

    const [picImages3, setpicImages3] = useState('');


    const [assig1, setassig1] = useState('')

    const [assig2, setassig2] = useState('')

    const [assig3, setassig3] = useState('')


    const [na1, setna1] = useState('')
    const [tel1, settel1] = useState('')
    const [idty1, setidty1] = useState('')
    const [id1, setid1] = useState('')

    const [na2, setna2] = useState('')
    const [tel2, settel2] = useState('')
    const [idty2, setidty2] = useState('')
    const [id2, setid2] = useState('')


    const [na3, setna3] = useState('')
    const [tel3, settel3] = useState('')
    const [idty3, setidty3] = useState('')
    const [id3, setid3] = useState('')




    const handleCloseModalp = () => setShow(false);
    const handleShowmModalp = () => setShow(true);

    const [playOn] = useSound(
        messagersent,
        { volume: 0.25 }
    );



    const onChange = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages1(imageList);

        if (imageList.length < 1) {

            //setImage(bankInfo.image)

        } else {

            const image = await resizeFile(imageList[0].file);
            setSignature(image)
            //console.log(imageList[0].data_url);

        }

    };


    const onChangePic = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);

        if (imageList.length < 1) {

            setpicImages1('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setpicImages1(image)
            //console.log(imageList[0].data_url);

        }

    };

    const onChangePic2 = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);

        if (imageList.length < 1) {

            setpicImages22('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setpicImages22(image)
            //console.log(imageList[0].data_url);

        }

    };


    const onChangePic3 = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);

        if (imageList.length < 1) {

            setpicImages3('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setpicImages3(image)
            //console.log(imageList[0].data_url);

        }

    };

    const picOnChange2 = async (imageList, addUpdateIndex) => {
        // data for submit
        setpicImages2(imageList);

        if (imageList.length < 1) {

            setpic('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setpic(image)
            //console.log(imageList[0].data_url);

        }

    };


    const onChangesig1 = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);

        if (imageList.length < 1) {

            setassig1('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setassig1(image)
            //console.log(imageList[0].data_url);

        }

    };

    const onChangesig2 = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);

        if (imageList.length < 1) {

            setassig2('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setassig2(image)
            //console.log(imageList[0].data_url);

        }

    };

    const onChangesig3 = async (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);

        if (imageList.length < 1) {

            setassig3('')

        } else {

            const image = await resizeFile(imageList[0].file);
            setassig3(image)
            //console.log(imageList[0].data_url);

        }

    };



    const handleTakePhoto =  async (dataUri) => {

       

        if (getpic == 1) {

            
            setpicImages1(dataUri)
            


        } else if (getpic == 2) {


            setpicImages22(dataUri)

        } else if (getpic == 3) {

            
            setpicImages3(dataUri)

        } else {

            setpic(dataUri)
        }
    }

    const opencam1 = () => {
        setgetpic('1')
        setShow(true)
    }

    const opencam2 = () => {
        setgetpic('2')
        setShow(true)
    }

    const opencam3 = () => {
        setgetpic('3')
        setShow(true)
    }


    let save_aproval = "True"
    let dat = moment().format('MMMM Do YYYY, h:mm:ss a')
    const CustomerAccountDetailsReducer = useSelector(state => state.CustomerAccountDetailsReducer)
    const { CustomerAccountDetails, CustomerAccountDetailsLoading } = CustomerAccountDetailsReducer

    const MembereDetailsReducer = useSelector(state => state.MembereDetailsReducer)
    const { MemberDetailsInfo, MemberDetailssuccess, memberDetailsLoading } = MembereDetailsReducer


    const CustomerAccountNumberReducer = useSelector(state => state.CustomerAccountNumberReducer)
    const { accountNumber } = CustomerAccountNumberReducer


    const CustomerAccountReducer = useSelector(state => state.CustomerAccountReducer)
    const {customerAccounts, customerAccountsLoading } = CustomerAccountReducer


    const [show, setshow] = useState(false)
    const [showcor, setshowcor] = useState(false)


    const accountAutoServiceReducer = useSelector(state => state.accountAutoServiceReducer)
    const { accountAutoServicerLoading, accountAutoServicer } = accountAutoServiceReducer

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo } = userProfileReducer


    const ALLUserReducer = useSelector(state => state.ALLUserReducer)
    const { AllUser, UserLoading, UserSuccess } = ALLUserReducer

    const save_Message_Reducer = useSelector(state => state.save_Message_Reducer)
    const { messagesave, messagesaveLoading, savesuccess } = save_Message_Reducer

    const apro_Message_Reducer = useSelector(state => state.apro_Message_Reducer)
    const { aproMessageLoading, message, success } = apro_Message_Reducer

    const [sms, setsms] = useState([])
    const [Request, setRequest] = useState(false)

    const [Requestsms, setRequestsms] = useState(false)

    const [Requestsmsstop, setRequestsmsstop] = useState(false)

    const [Requestemail, setRequestemail] = useState(false)

    const [Requestemailstop, setRequestemailstop] = useState(false)

    const [RequestSMSChange, setRequestSMSChange] = useState(false)

    const [RequestEmailChange, setRequestEmailChange] = useState(false)

    const [prousers, setprousers] = useState([])

    const [approval_by, setapproval_by] = useState('')
    const [approval_by1, setapproval_by1] = useState('')
    const [approval_by2, setapproval_by2] = useState('')
    const [approval_by3, setapproval_by3] = useState('')

    const [whyMessageRequst, setwhyMessageRequst] = useState('')

    const [newtel, setnewtel] = useState('')

    const [tel, settel] = useState()

    const [email, setemail] = useState()

    const [eemail, seteemail] = useState([])

    const [newemail, setnewemail] = useState('')

    const handleCloseModal = () => {

        setshow(false)

    }

    const handleopenModal = () => {
        setgetpic(0)
        setshow(true)

    }

    const handleCloseModalcor = () => {
        setshowcor(false)
        setpicImages1(CustomerAccountDetails.picone)
    }

    const handleopenModalcor = () => {

        setshowcor(true)

    }

    const openRequest = () => {
        setRequest(true)
        setapproval_by('')
        setwhyMessageRequst('Request For Account Opening Authorization')
    }

    const closeRequest = () => {

        setwhyMessageRequst('Request For Account Opening Authorization')
        setRequest(false)
        clear_all()
    }

    const opensmsrequst = () => {
        setRequestsms(true)
        setwhyMessageRequst('Request For SMS alert on transactions')
    }

    const closesmsrequst = () => {
        setRequestsms(false)
        clear_all();
    }

    const opensmsrequstStop = () => {
        setRequestsmsstop(true)
        setwhyMessageRequst('Request For SMS alert stop on acctount transactions')
        settel(sms[0].tel)
    }

    const closesmsrequstStop = () => {
        setRequestsmsstop(false)
        clear_all();
    }

    const openemalirequst = () => {
        setRequestemail(true)
        setwhyMessageRequst('Request For Email alert on transactions')
    }

    const closeemailrequst = () => {
        setRequestemail(false)
        clear_all();
    }

    const openEmailrequstStop = () => {
        setRequestemailstop(true)
        setwhyMessageRequst('Request For Email alert stop on acctount transactions')
        setemail(eemail[0].email)
    }

    const closeEmairequstStop = () => {
        setRequestemailstop(false)
        clear_all();
    }


    const openChangeSMS = () => {
        setRequestSMSChange(true)
        setwhyMessageRequst('Request change of SMS alert number')
        settel(sms[0].tel)
    }

    const closeChangeSMS = () => {
        setRequestSMSChange(false)
        clear_all();
    }

    const openNewAccName = () => {
        setaccname(true)
        setwhyMessageRequst('Request change of Account Name')
        
    }

    const closeNewAccName = () => {
        setaccname(false)
        clear_all();
    }



    const openChangeEmail = () => {
        setRequestEmailChange(true)
        setwhyMessageRequst('Request change of email alert Address')
        setemail(eemail[0].email)
    }

    const closeChangeEmail = () => {
        setRequestEmailChange(false)
        clear_all();
    }



    useEffect(() => {

        dispatch(loadCustomerAccountAction())

        dispatch(messageListAction(userInfo.username))

        setprousers(AllUser.filter(obj => (obj.loginstatus == 'System Administrator' || obj.loginstatus == 'Branch Manager' || obj.loginstatus == 'Operations Manager'
            || obj.loginstatus == 'General Manager' || obj.loginstatus == 'Bank Administrator') && obj.username != userInfo.username))

        clear()

    }, [dispatch])




    useEffect(() => {
        setpic(!CustomerAccountDetails.picone ? '' : CustomerAccountDetails.picone)
        setSignature(!CustomerAccountDetails.signature ? '' : CustomerAccountDetails.signature)

        setty(CustomerAccountDetails.card_ty == '' ? MemberDetailsInfo.card_ty : CustomerAccountDetails.card_ty)
        settyid(CustomerAccountDetails.card_Id == '' ? MemberDetailsInfo.card_Id : CustomerAccountDetails.card_Id)


        setpicImages1(CustomerAccountDetails.picone)
        setassig1(CustomerAccountDetails.signature)

        setpicImages22(CustomerAccountDetails.pictwo)
        setassig2(CustomerAccountDetails.signature2)


        setpicImages3(CustomerAccountDetails.picthree)
        setassig3(CustomerAccountDetails.signature3)

        setna1(CustomerAccountDetails.na1)
        settel1(CustomerAccountDetails.tel1)
        setidty1(CustomerAccountDetails.card_ty)
        setid1(CustomerAccountDetails.card_Id)


        setna2(CustomerAccountDetails.na2)
        settel2(CustomerAccountDetails.tel2)
        setidty2(CustomerAccountDetails.card_ty_2)
        setid2(CustomerAccountDetails.card_Id_2)


        setna3(CustomerAccountDetails.na3)
        settel3(CustomerAccountDetails.tel3)
        setidty3(CustomerAccountDetails.card_ty_3)
        setid3(CustomerAccountDetails.card_Id_3)

    }, [CustomerAccountDetailsReducer ,showcor ,MembereDetailsReducer,show])



    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        // console.log(result)
    }


    const handleOnSelect = (accountNumber) => {
        // the item selected
        // console.log(accountNumber.account_number)
     
        try {

            //multiselectRefTracker.current.resetSelectedValues()
            dispatch(CustomerAccountAutoServicesAction(accountNumber.account_number))
            dispatch(getMemberDetailsAction(accountNumber.customer_id_id))
            dispatch(getCustomerDetailsAction(accountNumber.trans_code))
            dispatch(AccountAountServicerAction(accountNumber.account_number, save_aproval))

            // navigate(-1)
        } catch (error) {

        }

    }

    useEffect(() => {

        setsms(accountAutoServicer.filter(obj => obj.service_name == 'SMS alert on transactions' && obj.save_aproval == true))

        seteemail(accountAutoServicer.filter(obj => obj.service_name == 'Email alert on transactions' && obj.save_aproval == true))

    }, [accountAutoServiceReducer])



    const handleOnFocus = () => {
        //console.log('Focused')
    }

    const formatResult = (accountNumber) => {
        return (
            <>

                <span style={{ display: 'block', textAlign: 'left', paddingBottom: '5px' }}> {accountNumber.name} </span>
                <span style={{ display: 'block', textAlign: 'left', paddingBottom: '5px' }}> {accountNumber.account_name} </span>

            </>
        )
    }

    const clear = () => {

        dispatch({ type: LOAD_AUTO_RESET })
        dispatch({ type: CUSTOMER_ACCOUNTS_RESET_DETAILS })
        dispatch({ type: ACCOUNT_AUTO_SERVICE_RESET })

    }


    const sendMessage = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')

        } else {

            const apm = message.filter(obj => obj.table == 'Account Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'Account Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Account Edit Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Your Authorization or Approval Of This Account"


                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, ty, name, accnumber, '-', '-', '-'))

                closeRequest()

            }

        }


    }

    const clear_all = () => {
        settel('')
        setwhyMessageRequst('')
        setapproval_by('')
        setemail('')
        setnewtel('')
        setnewemail('')
        setpic('')
        setty('')
        settyid('')
        setSignature('')
        setnewAccountName('')
    }


    useEffect(() => {

        if (savesuccess) {
            successMessage('Request Loogged Successful')

            playOn()

            dispatch({ type: APPROVAL_MESSAGE_SAVE_REST })

            dispatch(messageListAction(userInfo.username))

            closeRequest()
            closesmsrequstStop()
            closeemailrequst()
            closeEmairequstStop()
            closesmsrequstStop()
            closeChangeSMS()
            handleCloseModal()
        }


    }, [save_Message_Reducer])

    const getcustmernumber = () => {

        settel(CustomerAccountDetails.sms_number)
    }

    const getcustmeremail = () => {

        setemail(MemberDetailsInfo.email)
    }


    const sendsmsRequst = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')

        } else if (tel == '' || !(tel.match('^[0-9]{10}$'))) {

            ErrorMessage('Please provide valid Telephone Number')

        } else {

            const apm = message.filter(obj => obj.table == 'SMS Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'SMS Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'SMS Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Authorization On SMS Alert"
                let acc_ty=CustomerAccountDetails.product_name
                

                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, tel, '-', '-'))

                //dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
                //,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type, "-", "-" ,  0 , dat,  level, sent_by ,tel ,'-'))
                closesmsrequst()
            }
        }

    }


    const sendemailRequst = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')

        } else if (email == '' || !email.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$')) {

            ErrorMessage('Please provide a valid Email')

        } else {

            const apm = message.filter(obj => obj.table == 'Email Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'Email Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Email Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Authorization On Email"
                let acc_ty=CustomerAccountDetails.product_name


                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, '-', email, '-'))

                // dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
                // ,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type, "-", "-" ,  0 , dat,  level, sent_by ,tel ,'-'))

                closeemailrequst()
            }
        }

    }


    const sendStopsmsRequst = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')


        } else {

            const apm = message.filter(obj => obj.table == 'SMS Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'SMS Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'SMS Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Stop Authorization On SMS Alert "
                let other_pk = sms[0].trans_code
                let acc_ty=CustomerAccountDetails.product_name

                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, tel, '-', other_pk))

                //dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
                //,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type, "-", "-" ,  0 , dat,  level, sent_by ,tel ,'-'))

                closesmsrequstStop()
            }
        }

    }

    const sendStopemailRequst = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')


        } else {

            const apm = message.filter(obj => obj.table == 'SMS Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'SMS Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'SMS Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Stop Authorization On Email Alert "
                let other_pk = eemail[0].trans_code
                let acc_ty=CustomerAccountDetails.product_name

                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, email, '-', other_pk))

                //dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
                //,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type, "-", "-" ,  0 , dat,  level, sent_by ,tel ,'-'))

                closeEmairequstStop()
            }
        }

    }


    const sendsmsChangeRequst = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')

        } else if (newtel == '' || !(newtel.match('^[0-9]{10}$'))) {

            ErrorMessage('Please provide valid Telephone Number')

        } else {

            const apm = message.filter(obj => obj.table == 'SMS Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'SMS Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'SMS Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Authorization to Change SMS Alert Number"
                let other_pk = sms[0].trans_code
                let acc_ty=CustomerAccountDetails.product_name

                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, newtel, '-', other_pk))

                //dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
                //,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type, "-", "-" ,  0 , dat,  level, sent_by ,tel ,'-'))

                closeChangeSMS()
            }
        }

    }


    const sendEmailChangeRequst = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')


        } else if (newemail == '' || !newemail.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$')) {

            ErrorMessage('Please provide a valid Email')

        } else {

            const apm = message.filter(obj => obj.table == 'SMS Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'SMS Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let pic = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'SMS Request'
                let message_sum = whyMessageRequst
                let ty = CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Authorization to Change Email Alert Address"
                let other_pk = eemail[0].trans_code
                let acc_ty=CustomerAccountDetails.product_name

                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, '-', newemail, other_pk))

                //dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
                //,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type, "-", "-" ,  0 , dat,  level, sent_by ,tel ,'-'))

                closeChangeEmail()
            }
        }

    }


    const signatoryRequst = () => {

        if (pic == '' || signature == '' || ty == '' || tyid == '' || approval_by == '') {

            ErrorMessage('All Required Fields Must be Filled')

        } else {

            const apm = message.filter(obj => obj.table == 'Signatory Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == 'Request For Account Signatory Change')

            if (apm.length > 0) {

                ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

            } else {


                let table = 'Signatory Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let picc = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Signatory Table'
                let message_sum = 'Request For Account Signatory Change'
                //let ty=CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Account Signatory Change"
                let acc_ty=CustomerAccountDetails.product_name



                dispatch(editCustomercompanysignatory(ty, tyid, signature, '', '', table_pk, '', '', '', '', pic, '', ''))

                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, picc, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, '', '-', ''))

                handleCloseModal()
            }
        }

    }


    const signatoryRequstcor1 = () => {

        if (na1 == '' || tel1 == '' || idty1 == '' || id1 == '' || picImages1 == '' || assig1 == '' || approval_by1 == '') {

            ErrorMessage('Account Signatory (1) Required Fields Must be Filled')

        } else if (tel1 == '' || !(tel1.match('^[0-9]{10}$'))) {

            ErrorMessage('Please provide valid Telephone Number')

        } else {

            const apm = message.filter(obj => obj.table == 'Signatory Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == 'Request For Account Signatory(1) Change')

            if (apm.length > 0) {

                ErrorMessage('Request For Account Signatory(1) Change Already Sent For Approval')

            } else {


                let table = 'Signatory Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let picc = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Signatory Table'
                let message_sum = 'Request For Account Signatory(1) Change'
                //let ty=CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Account Signatory Change"
                let acc_ty=CustomerAccountDetails.product_name



                dispatch(editCustomercompanysignatorycor(na1, tel1, idty1, id1, table_pk, picImages1 , assig1))


                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, picc, dat, dat2, title, message_sum, approval_by1, acc_ty, name, accnumber, '', '-', ''))

            }
        }

    }


    const signatoryRequstcor2 = () => {

        if (na2 == '' || tel2 == '' || idty2 == '' || id2 == '' || picImages22 == '' || assig2 == '' || approval_by2 == '') {

            ErrorMessage('Account Signatory (2) Required Fields Must be Filled')

        } else if (tel1 == '' || !(tel2.match('^[0-9]{10}$'))) {

            ErrorMessage('Please provide valid Telephone Number')

        } else {

            const apm = message.filter(obj => obj.table == 'Signatory Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == 'Request For Account Signatory(2) Change')

            if (apm.length > 0) {

                ErrorMessage('Request For Account Signatory(2) Change Already Sent For Approval')

            } else {


                let table = 'Signatory Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let picc = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Signatory Table'
                let message_sum = 'Request For Account Signatory(2) Change'
                //let ty=CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Account Signatory Change"
                let acc_ty=CustomerAccountDetails.product_name



                dispatch(editCustomercompanysignatorycor(na2, tel2, idty2, id2, table_pk, picImages22 , assig2))


                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, picc, dat, dat2, title, message_sum, approval_by2, acc_ty, name, accnumber, '', '-', ''))

            }
        }

    }


    const signatoryRequstcor3 = () => {

        if (na3 == '' || tel3 == '' || idty3 == '' || id3 == '' || picImages3 == '' || assig3 == '' || approval_by3 == '') {

            ErrorMessage('Account Signatory (3) Required Fields Must be Filled')

        } else if (tel3 == '' || !(tel3.match('^[0-9]{10}$'))) {

            ErrorMessage('Please provide valid Telephone Number')

        } else {

            const apm = message.filter(obj => obj.table == 'Signatory Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == 'Request For Account Signatory(3) Change')

            if (apm.length > 0) {

                ErrorMessage('Request For Account Signatory(3) Change Already Sent For Approval')

            } else {


                let table = 'Signatory Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let picc = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Signatory Table'
                let message_sum = 'Request For Account Signatory(3) Change'
                //let ty=CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For Account Signatory Change"
                let acc_ty=CustomerAccountDetails.product_name



                dispatch(editCustomercompanysignatorycor(na3, tel3, idty3, id3, table_pk, picImages3 , assig3))


                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, picc, dat, dat2, title, message_sum, approval_by3, acc_ty, name, accnumber, '', '-', ''))

                handleCloseModalcor()
            }
        }

    }


    const changeAccountName = () => {

        if (approval_by == '') {

            ErrorMessage('Please Select Authorization User')

        } else if (newAccountName == '') {

            ErrorMessage('Please Enter The New Account Name')

        } else {

            const apm = message.filter(obj => obj.table == 'Account Table' && obj.approval_status == false && obj.table_pk == CustomerAccountDetails.trans_code && obj.message_sum == whyMessageRequst)

            if (apm.length > 0) {

                ErrorMessage('Request change of Account Name Already Sent For Approval')

            } else {


                let table = 'Account Table'
                let level = 10
                let action = 1
                let sent_by = userInfo.username
                let table_pk = CustomerAccountDetails.trans_code;
                let branch = userProfileInfo.branch
                let picc = userProfileInfo.image
                let dat = moment().format('YYYY-MM-DD')
                let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
                let title = 'Account Table'
                let message_sum = whyMessageRequst
                //let ty=CustomerAccountDetails.acc_type
                let name = CustomerAccountDetails.account_name
                let accnumber = CustomerAccountDetails.account_number
                let RequestMessage = "Requesting For change of Account Name"
                let acc_ty=CustomerAccountDetails.product_name

                dispatch(changeAcountName( newAccountName,table_pk))


                dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, picc, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, '', '-', ''))

                closeNewAccName()
            }
        }

    }

    return (
        <div>
            <h3>Customer Account</h3>
            <hr />

            <div className='solcon overflow-auto vh-100'>
                <div className='container'>

                    {CustomerAccountDetailsLoading && <Loader />}
                    {memberDetailsLoading && <Loader />}
                    {customerAccountsLoading && <Loader />}
                   


                    <div className='row table_buttom_margin'>
                        <div className='col-md-5'>
                            <div className='card mb-2'>

                                <div className='card-header'>Account Infomation</div>
                                <div className='section profile'>

                                    <div className="card">

                                        <div className="card-body profile-card d-flex flex-column align-items-center">

                                            <header className="App-header mt-3">
                                                <div style={{ width: 300 }}>

                                                    <ReactSearchAutocomplete
                                                        items={customerAccounts}
                                                        // fuseOptions={{ keys: ["trans_code", "tel"] }}
                                                        // resultStringKeyName="tel"
                                                        onSearch={handleOnSearch}
                                                        onHover={handleOnHover}
                                                        onSelect={handleOnSelect}
                                                        onFocus={handleOnFocus}
                                                        onClear={clear}
                                                        autoFocus
                                                        formatResult={formatResult}
                                                        placeholder='Search Account Number'

                                                    />
                                                </div>
                                            </header>

                                            {

                                                //CustomerAccountDetails.length <=0 ?  'xxxxxxxxxxxxx' :  
                                                //<img width={100} height={100}  src={CustomerAccountDetails.signature} alt="Profile "/> 

                                            }

                                        </div>
                                        <div className="card-body">



                                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Account Number</label>
                                            <input name="Name" type="text" className="form-control mb-2 text-center" value={CustomerAccountDetails.length <= 0 ? 'xxxxxxxxxxxxx' : CustomerAccountDetails.account_number} />



                                            <div className='row'>
                                                <div className='col-md-7'>  
                                                <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Account Name</label>
                                                <input name="Name" type="text" className="form-control mb-2 text-center" value={CustomerAccountDetails.length <= 0 ? 'xxxxxxxxxxxxx' : CustomerAccountDetails.account_name} />
                                                </div>

                                                  {CustomerAccountDetails.length <= 0 ? ''
                                                   : 
                                                   <>
                                                    <div className='col-md-5'>
                                                <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Change Account Name</label>
                                                  <button onClick={openNewAccName} className="form-control"><FiEdit2  className='preview-btn' /></button>  
                                                  
                                                </div>
                                                </>}
                                               
                                            </div>



                                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Account Type</label>
                                            <input name="Name" type="text" className="form-control mb-2 text-center" value={CustomerAccountDetails.length <= 0 ? 'xxxxxxxxxxxxx' : CustomerAccountDetails.acc_type} />




                                            <label className='lable text-center' for='Utility'><samp className='text-danger'>* </samp>Bank Product</label>
                                            <input name="Name" type="text" className="form-control mb-1 text-center" value={CustomerAccountDetails.length <= 0 ? 'xxxxxxxxxxxxx' : CustomerAccountDetails.product_name} />



                                            <div className="card-body profile-card pt-3  d-flex flex-column align-items-center list-group">

                                                {CustomerAccountDetails.length <= 0 ? '' :

                                                    CustomerAccountDetails.save_aproval ?
                                                        (
                                                            <button className="list-group-item btn-class mt-3"> Account Is Approve <FcCheckmark size={25} /> Request to Close Account </button>

                                                        )

                                                        :

                                                        <button onClick={openRequest} className="list-group-item btn-class mt-3">  Account Unapproved <FcHighPriority size={25} /> Request Account Approval</button>


                                                }
                                            </div>
                                        </div>

                                        {
                                            CustomerAccountDetails.acc_type == 'Individual Account' ?
                                                <button onClick={handleopenModal} className="show_info_btn">View Customer Account Details</button>

                                                :

                                                CustomerAccountDetails.acc_type == 'Corporate Account' ?

                                                    <button onClick={handleopenModalcor} className="show_info_btn">View Customer Account Details</button>

                                                    :
                                                    ''
                                        }
                                    </div>

                                </div>


                            </div>
                        </div>


                        <div className='col-md-7 '>

                            <h5>Account Services</h5>
                            <hr />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="income_s "
                                            checked={sms.length <= 0 ? false : true} />
                                        <label className="form-check-label" for="Auto">SMS alert on transactions</label>
                                    </div>

                                    <label className='lable text-center mt-3 ' for='Utility'><samp className='text-danger'>*</samp> Alerter's Telephone Number </label>
                                    <input name="Name" type="text" className="form-control mb-2 text-center" value={sms.length <= 0 ? '' : sms[0].tel} />

                                    {
                                        sms.length <= 0 ?

                                            <button onClick={opensmsrequst} className="show_info_btn mb-4">Request SMS Alert</button>
                                            :

                                            <>
                                                <button onClick={opensmsrequstStop} className="show_info_btn mb-2 ">Stop SMS Alert</button>
                                                <button onClick={openChangeSMS} className="show_info_btn mb-4">Change SMS Alert Number</button>
                                            </>
                                    }



                                </div>
                                <div className='col-md-6'>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="income_s"
                                            checked={eemail.length <= 0 ? false : true} />
                                        <label className="form-check-label" for="Auto">Email alert on transactions</label>
                                    </div>

                                    <label className='lable text-center mt-3 ' for='Utility'><samp className='text-danger'>*</samp> Alerter's Email Address</label>
                                    <input name="Name" type="text" className="form-control mb-2 text-center" value={eemail.length <= 0 ? '' : eemail[0].email} />


                                    {
                                        eemail.length <= 0 ?

                                            <button onClick={openemalirequst} className="show_info_btn mb-4">Request Email Alert</button>
                                            :

                                            <>
                                                <button onClick={openEmailrequstStop} className="show_info_btn mb-2 ">Stop Email Alert</button>
                                                <button onClick={openChangeEmail} className="show_info_btn mb-4">Change SMS Alert Number</button>
                                            </>
                                    }


                                </div>


                            </div>
                            <hr />

                            <div className='row'>

                                <div className='col-md-6 mt-4'>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="income_s"
                                        />
                                        <label className="form-check-label" for="Auto">Mobile money transactions</label>
                                    </div>

                                    <button className="show_info_btn mb-4 ">Request Mobile Money</button>





                                </div>

                                <div className='col-md-6 mt-4'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="income_s"
                                        />
                                        <label className="form-check-label" for="Auto">Internet Banking</label>
                                    </div>


                                    <button className="show_info_btn mb-4 ">Request Internet Banking</button>

                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </div>



            <Modal size="lg" show={show} onHide={handleCloseModal} dialogClassName="my-modal-small" backdrop="static" keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Customer Account Infomation</Modal.Title>
                </Modal.Header>
                <Modal.Body >


                    <div className='row'>
                        <h5 className='mb-3'>Current Account Signatory's Information</h5>
                        <div className='col-md-2'>

                            <samp><samp className='text-danger'>* </samp> Picture</samp>

                            {
                                !CustomerAccountDetails.picone ?

                                    <FaUserLarge size={120} className="p-2 mb-3 card-img-top embed-responsive-item" />

                                    : <img className="card-img-top embed-responsive-ite card-img-top embed-responsive-item" width="100" height="120" src={CustomerAccountDetails.picone} />

                            }

                            <samp><samp className='text-danger'>* </samp> Signature</samp>



                            {
                                !CustomerAccountDetails.signature ?

                                    <PiSignatureThin size={120} className="p-2 mb-3  card-img-top embed-responsive-item" />

                                    : <img className="card-img-top embed-responsive-item" width="100" height="120" src={CustomerAccountDetails.signature} />

                            }

                        </div>



                        <div className='col-md-4'>

                            <div className='card mt-4'>
                                <div className='card-body'>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Account Number:</div>
                                        <div className="col-lg-6 col-md-6">{CustomerAccountDetails.account_number}</div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Account Name:</div>
                                        <div className="col-lg-6 col-md-6">{CustomerAccountDetails.account_name}</div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Account Type:</div>
                                        <div className="col-lg-6 col-md-6">{CustomerAccountDetails.product_name}</div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Telephone Number:</div>
                                        <div className="col-lg-6 col-md-6">{MemberDetailsInfo.tel}</div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Identification:</div>
                                        <div className="col-lg-6 col-md-6">{MemberDetailsInfo.card_ty}</div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Identification Number:</div>
                                        <div className="col-lg-6 col-md-6">{MemberDetailsInfo.card_Id}</div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-lg-6 col-md-6 label">Branch:</div>
                                        <div className="col-lg-6 col-md-6">{CustomerAccountDetails.branch}</div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className='col-md-6'>

                            <div className='card mt-4'>
                                <div className='card-body'>
                                    <div className='card-title'></div>

                                    <div className='row'>
                                        <h5 className='mb-3'>New Account Signatory's Information</h5>
                                        <div className='col-md-6'>
                                            <strong for="Address" className="col-md-4 col-lg-4 col-form-label"><samp className='text-danger'>* </samp> New Picture</strong>

                                            <div className="embed-responsive embed-responsive-16by9">
                                                {!pic == '' ? (<img width="120" alt="Card image cap" src={pic} />)
                                                    : (<FaUserLarge size={120} className="p-2 mb-3 card-img-top embed-responsive-item" />)}

                                            </div>
                                            <button onClick={handleShowmModalp} className='show_info_btn mt-2'>  Open Camera</button>

                                            <ImageUploading
                                                multiple
                                                value={picImages2}
                                                onChange={picOnChange2}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            picImages2.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-3'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse For Pictuer
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">

                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>




                                        </div>
                                        <div className='col-md-6'>
                                            <strong for="Address" className="col-md-4 col-lg-4 col-form-label"><samp className='text-danger'>* </samp> Upload New Signature</strong>
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images.length == 1 ? '' : (
                                                                <button className='show_info_btn mb-3 '
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse for Signature
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">
                                                                <img src={signature} alt="" width="120" />
                                                                <div className="image-item__btn-wrapper mb-3">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>

                                            <hr />

                                            <lable for='Login Status'> <samp className='text-danger'>* </samp> Type Of Identification</lable>
                                            <select required className='form-control mb-3' value={ty} onChange={(e) => setty(e.target.value)} >
                                                <option selected value="Select" >Select</option>
                                                <option value="Voters Card">Voters Card</option>
                                                <option value="Ghana Card">Ghana Card</option>
                                                <option value="Drivers License">Drivers License</option>
                                                <option value="Passport">Passport</option>
                                                <option value="SSNIT Card">SSNIT Card</option>
                                            </select>


                                            <label for='Surname'><samp className='text-danger'>* </samp> Identification Number </label>
                                            <input name="Name" required type="text" className="form-control mb-3" value={tyid} onChange={(e) => settyid(e.target.value)} />


                                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                                            <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                                                <option selected disabled value="" >Select</option>
                                                {prousers.map((users) => (
                                                    <option value={users.username}>{users.name} - {users.loginstatus}</option>
                                                ))}

                                            </select>



                                        </div>
                                    </div>

                                </div>
                            </div>
                            <button onClick={signatoryRequst} className="btn-class mt-3">Send Account Signatory's Requst For Change</button>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>



            <Modal size="lg" show={Request} onHide={closeRequest} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>



                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendMessage} className="show_info_btn">Request Account Authorization</button>




                </Modal.Body>

            </Modal>




            <Modal size="lg" show={Requestsms} onHide={closesmsrequst} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <div className='row'>
                        <div className='col-md-8'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Alerter's Telephone Number</label>
                            <input name="Name" type="text" className="form-control mb-4" value={tel} onChange={(e) => settel(e.target.value)} />
                        </div>



                        <div className='col-md-4 mb-4'>


                            <button className="show_info_btn mt-3" onClick={getcustmernumber} ><FcCheckmark className='preview-btn' />Use Member's Number</button>
                        </div>

                    </div>


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendsmsRequst} className="show_info_btn">Request SMS Alert </button>


                </Modal.Body>

            </Modal>


            <Modal size="lg" show={Requestemail} onHide={closeemailrequst} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <div className='row'>

                        <div className='col-md-8'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Alerter's Email Address</label>
                            <input name="Name" type="text" className="form-control mb-4" value={email} onChange={(e) => setemail(e.target.value)} />
                        </div>

                        <div className='col-md-4 mb-4'>


                            <button className="show_info_btn mt-3" onClick={getcustmeremail} ><FcCheckmark className='preview-btn' /> Use Member's Email</button>
                        </div>
                    </div>


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendemailRequst} className="show_info_btn">Request Email Alert </button>


                </Modal.Body>

            </Modal>




            <Modal size="lg" show={Requestsmsstop} onHide={closesmsrequstStop} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Alerter's Telephone Number</label>
                    <input name="Name" type="text" className="form-control mb-4" value={tel} />

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendStopsmsRequst} className="show_info_btn">Request SMS Stop</button>


                </Modal.Body>

            </Modal>



            <Modal size="lg" show={Requestemailstop} onHide={closeEmairequstStop} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>

                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Alerter's Email Address</label>
                    <input name="Name" type="text" className="form-control mb-4" value={email} />

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendStopemailRequst} className="show_info_btn">Request Email Stop</button>


                </Modal.Body>

            </Modal>


            <Modal size="lg" show={RequestSMSChange} onHide={closeChangeSMS} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Alerter's Telephone Number</label>
                            <input name="Name" type="text" className="form-control mb-4" value={tel} />
                        </div>
                        <div className='col-md-6'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>New Alerter's Telephone Number</label>
                            <input name="Name" type="text" className="form-control mb-4" value={newtel} onChange={(e) => setnewtel(e.target.value)} />
                        </div>
                    </div>


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendsmsChangeRequst} className="show_info_btn">Request Change of SMS Alert Number</button>


                </Modal.Body>

            </Modal>

            <Modal size="lg" show={RequestEmailChange} onHide={closeChangeEmail} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Alerter's Email Address</label>
                            <input name="Name" type="text" className="form-control mb-4" value={email} />
                        </div>
                        <div className='col-md-6'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>New Alerter's Email Address</label>
                            <input name="Name" type="text" className="form-control mb-4" value={newemail} onChange={(e) => setnewemail(e.target.value)} />
                        </div>
                    </div>


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={sendEmailChangeRequst} className="show_info_btn">Request Change of Email Alert Number</button>


                </Modal.Body>

            </Modal>


            <Modal size="lg" show={showp} onHide={handleCloseModalp} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Take picture</Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <Camera onTakePhoto={(dataUri) => {handleTakePhoto(dataUri) }}
                        idealResolution={{ width: 680, height: 460 }} />


                </Modal.Body>

            </Modal>

            <Modal size="lg" show={accname} onHide={closeNewAccName} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Send Request </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {aproMessageLoading && <Loader />}
                    {messagesaveLoading && <Loader />}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst} />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Account Name</label>
                            <input name="Name" type="text" className="form-control mb-4" value={CustomerAccountDetails.account_name} />
                        </div>
                        <div className='col-md-6'>
                            <label className='lable' for='Utility'><samp className='text-danger'>* </samp>New Account Name</label>
                            <input name="Name" type="text" className="form-control mb-4" value={newAccountName} onChange={(e) => setnewAccountName(e.target.value)} />
                        </div>
                    </div>


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat} />


                    <button onClick={changeAccountName} className="show_info_btn">Request Change of Account Name</button>


                </Modal.Body>

            </Modal>



            <Modal size="lg" show={showcor} onHide={handleCloseModalcor} dialogClassName="my-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Customer Account Infomation</Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="card">
                                <div className='card-header'>Account Signatory's (1)</div>
                                <div className="card-body profile-card">


                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Name</label>
                                    <input name="Name" type="text" className="form-control mb-2" value={na1} onChange={(e) => setna1(e.target.value)} />

                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Telephone Number</label>
                                    <input name="Name" type="text" className="form-control mb-2" value={tel1} onChange={(e) => settel1(e.target.value)} />


                                    <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
                                    <select required className='form-control mb-2' value={idty1} onChange={(e) => setidty1(e.target.value)}  >
                                        <option selected value="Select" >Select</option>
                                        <option value="Voters Card">Voters Card</option>
                                        <option value="Ghana Card">Ghana Card</option>
                                        <option value="Drivers License">Drivers License</option>
                                        <option value="Passport">Passport</option>
                                        <option value="SSNIT Card">SSNIT Card</option>
                                    </select>

                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Identification Number</label>
                                    <input name="Name" type="text" className="form-control  mb-2" value={id1} onChange={(e) => setid1(e.target.value)} />

                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <samp><samp className='text-danger'>* </samp> Picture</samp>
                                            <div className="card mt-2">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    {!picImages1 ? (<FaUserLarge size={120} className="p-2 mb-3 card-img-top embed-responsive-item" />)
                                                        : <img className="card-img-top embed-responsive-item" width="100" height="120" src={picImages1} />
                                                    }
                                                </div>
                                            </div>

                                            <ImageUploading
                                                multiple
                                                value={images1}
                                                onChange={onChangePic}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images1.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-2'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse Pictuer
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image1, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>

                                            <button onClick={opencam1} className="show_info_btn m-1 mt-2">Open Camera</button>


                                        </div>
                                        <div className='col-md-6'>

                                            <samp><samp className='text-danger'>* </samp> Signature</samp>
                                            <div className="card mt-2">
                                                <div className="embed-responsive embed-responsive-16by9"></div>
                                                {
                                                    ! assig1 ?

                                                        <PiSignatureThin size={120} className="p-2 mb-3  card-img-top embed-responsive-item" />

                                                        : <img className="card-img-top embed-responsive-item" width="100" height="120" src={assig1} />

                                                }

                                            </div>

                                            <ImageUploading
                                                multiple
                                                value={images1}
                                                onChange={onChangesig1}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images1.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-2'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse Signature
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image1, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>

                                        <hr className='mt-4'/>

                                        
                                    </div>

                                    
                                    <label className='' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                                            <select required className='form-control' value={approval_by1} onChange={(e) => setapproval_by1(e.target.value)}>
                                                <option selected disabled value="" >Select</option>
                                                {prousers.map((users) => (
                                                    <option value={users.username}>{users.name} - {users.loginstatus}</option>
                                                ))}

                                            </select>
                                    

                                        <button onClick={signatoryRequstcor1} className="show_info_btn mt-4">Change Account Signatory's (1) Infomation</button>


                                </div>

                            </div>

                        </div>


                        <div className='col-md-4'>
                            <div className="card">
                                <div className='card-header'>Account Signatory's (2)</div>
                                <div className="card-body profile-card">
                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Name</label>
                                    <input name="Name" type="text" className="form-control mb-2" value={na2} onChange={(e) => setna2(e.target.value)} />

                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Telephone Number</label>
                                    <input name="Name" type="text" className="form-control mb-2" value={tel2} onChange={(e) => settel2(e.target.value)} />


                                    <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
                                    <select required className='form-control mb-2' value={idty2} onChange={(e) => setidty2(e.target.value)}  >
                                        <option selected value="Select" >Select</option>
                                        <option value="Voters Card">Voters Card</option>
                                        <option value="Ghana Card">Ghana Card</option>
                                        <option value="Drivers License">Drivers License</option>
                                        <option value="Passport">Passport</option>
                                        <option value="SSNIT Card">SSNIT Card</option>
                                    </select>

                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Identification Number</label>
                                    <input name="Name" type="text" className="form-control  mb-2" value={id2} onChange={(e) => setid2(e.target.value)} />

                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <samp><samp className='text-danger'>* </samp> Picture</samp>
                                            <div className="card mt-2">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    {!picImages22 ? (<FaUserLarge size={120} className="p-2 mb-3 card-img-top embed-responsive-item" />)
                                                        : <img className="card-img-top embed-responsive-item" width="100" height="120" src={picImages22} />
                                                    }
                                                </div>
                                            </div>

                                            <ImageUploading
                                                multiple
                                                value={images1}
                                                onChange={onChangePic2}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images1.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-2'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse Pictuer
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image1, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>

                                            <button onClick={opencam2} className="show_info_btn m-1 mt-2">Open Camera</button>


                                        </div>
                                        <div className='col-md-6'>

                                            <samp><samp className='text-danger'>* </samp> Signature</samp>
                                            <div className="card mt-2">
                                                <div className="embed-responsive embed-responsive-16by9"></div>
                                                {
                                                    !CustomerAccountDetails.signature ?

                                                        <PiSignatureThin size={120} className="p-2 mb-3  card-img-top embed-responsive-item" />

                                                        : <img className="card-img-top embed-responsive-item" width="100" height="120" src={assig2} />

                                                }

                                            </div>

                                            <ImageUploading
                                                multiple
                                                value={images1}
                                                onChange={onChangesig2}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images1.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-2'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse Signature
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image1, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>

                                        <hr className='mt-4'/>
                                    </div>

                                    
                                    <label className='' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                                            <select required className='form-control' value={approval_by2} onChange={(e) => setapproval_by2(e.target.value)}>
                                                <option selected disabled value="" >Select</option>
                                                {prousers.map((users) => (
                                                    <option value={users.username}>{users.name} - {users.loginstatus}</option>
                                                ))}

                                            </select>
                                    

                                        <button onClick={signatoryRequstcor2} className="show_info_btn mt-4">Change Account Signatory's (2) Infomation</button>
                                </div>
                            </div>
                        </div>



                        <div className='col-md-4'>
                            <div className="card">
                                <div className='card-header'>Account Signatory's (3)</div>
                                <div className="card-body profile-card">

                                    <label className='lable' for='Utility'>Name</label>
                                    <input name="Name" type="text" className="form-control mb-2" value={na3} onChange={(e) => setna3(e.target.value)} />

                                    <label className='lable' for='Utility'>Telephone Number</label>
                                    <input name="Name" type="text" className="form-control mb-2" value={tel3} onChange={(e) => settel3(e.target.value)} />


                                    <lable className='lable' for='Login Status'> Type Of Identification</lable>
                                    <select required className='form-control mb-2' value={idty3} onChange={(e) => setidty3(e.target.value)}  >
                                        <option selected value="Select" >Select</option>
                                        <option value="Voters Card">Voters Card</option>
                                        <option value="Ghana Card">Ghana Card</option>
                                        <option value="Drivers License">Drivers License</option>
                                        <option value="Passport">Passport</option>
                                        <option value="SSNIT Card">SSNIT Card</option>
                                    </select>

                                    <label className='lable' for='Utility'>Identification Number</label>
                                    <input name="Name" type="text" className="form-control  mb-2" value={id3} onChange={(e) => setid3(e.target.value)} />

                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <samp> Picture</samp>
                                            <div className="card mt-2">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    {!picImages3 ? (<FaUserLarge size={120} className="p-2 mb-3 card-img-top embed-responsive-item" />)
                                                        : <img className="card-img-top embed-responsive-item" width="100" height="120" src={picImages3} />
                                                    }
                                                </div>
                                            </div>

                                            <ImageUploading
                                                multiple
                                                value={images1}
                                                onChange={onChangePic3}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images1.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-2'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse Pictuer
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image1, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>

                                            <button onClick={opencam3} className="show_info_btn m-1 mt-2">Open Camera</button>


                                        </div>
                                        <div className='col-md-6'>

                                            <samp> Signature</samp>
                                            <div className="card mt-2">
                                                <div className="embed-responsive embed-responsive-16by9"></div>
                                                {
                                                    !assig3 ?

                                                        <PiSignatureThin size={120} className="p-2 mb-3  card-img-top embed-responsive-item" />

                                                        : <img className="card-img-top embed-responsive-item" width="100" height="120" src={assig3} />

                                                }

                                            </div>

                                            <ImageUploading
                                                multiple
                                                value={images1}
                                                onChange={onChangesig3}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", 'PNG']}
                                                className='mt-4'
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            images1.length == 1 ? '' : (
                                                                <button className='show_info_btn mt-2'
                                                                    style={isDragging ? { color: "red" } : null}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Browse Signature
                                                                </button>
                                                            )

                                                        }

                                                        &nbsp;

                                                        {imageList.map((image1, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='edit-btn ' onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate /></button>
                                                                    <button className='del-btn ms-3' onClick={() => onImageRemove(index)}>  <MdDeleteForever /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>

                                        <hr className='mt-4'/>
                                    </div>
                                    
                                    <label className='' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                                            <select required className='form-control' value={approval_by3} onChange={(e) => setapproval_by3(e.target.value)}>
                                                <option selected disabled value="" >Select</option>
                                                {prousers.map((users) => (
                                                    <option value={users.username}>{users.name} - {users.loginstatus}</option>
                                                ))}

                                            </select>
                                    

                                        <button onClick={signatoryRequstcor3} className="show_info_btn mt-4">Change Account Signatory's (3) Infomation</button>
                                </div>
                            </div>
                        </div>

                    </div>



                </Modal.Body>

            </Modal>





        </div>
    )
}

export default CustomerAccount