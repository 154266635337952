import { NumericFormat } from 'react-number-format';


export  const cashGH=(value)=> {
    return (
      <NumericFormat
        value={value}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'GH₵ '}
       // renderText={formattedValue => <Text>{formattedValue}</Text>} // <--- Don't forget this!
      />
    );
  }


  export  const cash=(value)=> {
    return (
      <NumericFormat
        value={value}
        displayType={'text'}
        thousandSeparator={true}
        //prefix={'GH₵'}
        //renderText={formattedValue => <Text>{formattedValue}</Text>} // <--- Don't forget this!
      />
    );
  }

 export const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'USD'
    }).format(value);