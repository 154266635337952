import { USER_PROFILE_REQUEST ,USER_PROFILE_SUCCESS ,USER_PROFILE_FAIL ,USER_PROFILE_RESET
        ,USER_PROFILE_REQUEST_UPDATE ,USER_PROFILE_SUCCESS_UPDATE,USER_PROFILE_FAIL_UPDATE

} from "../constants/profileConstants";

import { CHANGE_USER_LOGIN_REQUEST ,CHANGE_USER_LOGIN_SUCCESS ,CHANGE_USER_LOGIN_FAIL ,CHANGE_USER_LOGOUT } from "../constants/profileConstants";

export const userProfileReducer=(state={userProfileInfo:{}},action)=>{

    switch(action.type){
        case USER_PROFILE_REQUEST:
            return{userProfileInfoLoading:true ,...state }

        case USER_PROFILE_SUCCESS:
            return{userProfileInfoLoading:false ,userProfileInfo:action.payload}  

        case USER_PROFILE_FAIL:
            return {userProfileInfoLoading:false , error:action.payload}

        case USER_PROFILE_RESET:
            return {userProfileInfo:{}}
        
        default:
            return state

    }

}

export const userProfileReducerUpdate=(state={userProfileInfoUpdate:{}},action)=>{

    switch(action.type){
        case USER_PROFILE_REQUEST_UPDATE :
            return{userProfileInfoUpdateLoading:true ,...state }

        case USER_PROFILE_SUCCESS_UPDATE :
            return{userProfileInfoUpdateLoading:false ,userProfileInfoUpdate:action.payload}  

        case USER_PROFILE_FAIL_UPDATE :
            return {userProfileInfoUpdateLoading:false , error:action.payload}
        
        default:
            return state

    }

}


export const changeUserLoginReducer = (state = {changeUserInfo:{},success:false } , action) => {

    switch (action.type) {

        case CHANGE_USER_LOGIN_REQUEST:
            return { changeloading: true ,success:false ,error:false};
        
        case CHANGE_USER_LOGIN_SUCCESS:
            return { changeloading: false ,changeUserInfo: action.payload , success:true };
        
        case CHANGE_USER_LOGIN_FAIL:
            return { changeloading: false , error:action.payload , success:false };

        case CHANGE_USER_LOGOUT:
            return {}

        default:
            return state

    }

}