import { MEMBER_REGISTRATION_REQUEST,MEMBER_REGISTRATION_SUCCESS ,MEMBER_REGISTRATION_FAIL ,MEMBER_REGISTRATION_RESET 
    ,MEMBER_REQUEST ,MEMBER_SUCCESS,MEMBER_FAIL ,MEMBER_RESET ,
    MEMBER_REQUEST_DETAILS ,MEMBER_SUCCESS_DETAILS ,MEMBER_FAIL_DETAILS ,MEMBER_RESET_DETAILS ,

    MEMBER_UPDATE_REQUEST ,MEMBER_UPDATE_SUCCESS,MEMBER_UPDATE_FAIL,MEMBER_UPDATE_RESET
} from "../constants/memberConstants";



export const registerMembereReducer=(state={registerMemberInfo:{}},action)=>{

    switch(action.type){
        case MEMBER_REGISTRATION_REQUEST :
            return{registermemberLoading:true  }

        case MEMBER_REGISTRATION_SUCCESS :
            return{registermemberLoading:false , success:true , registerMemberInfo:action.payload}  

        case MEMBER_REGISTRATION_FAIL :
            return {registermemberLoading:false , error:action.payload}

        case MEMBER_REGISTRATION_RESET:
            return{registerMemberInfo:{}}
        
        default:
            return state

    }

}



export const MembereReducer=(state={MemberInfo:[]},action)=>{

    switch(action.type){
        case MEMBER_REQUEST :
            return{memberLoading:true }

        case MEMBER_SUCCESS :
            return{memberLoading:false , memberSuccess:true , MemberInfo:action.payload}  

        case MEMBER_FAIL :
            return {memberLoading:false , MemberError:action.payload}

        case MEMBER_RESET:
            return{MemberInfo:{}}
        
        default:
            return state

    }

}


export const MembereDetailsReducer=(state={MemberDetailsInfo:{}},action)=>{

    switch(action.type){
        case MEMBER_REQUEST_DETAILS :
            return{memberDetailsLoading:true ,...state  }

        case MEMBER_SUCCESS_DETAILS :
            return{memberDetailsLoading:false , MemberDetailssuccess:true , MemberDetailsInfo:action.payload}  

        case MEMBER_FAIL_DETAILS :
            return {memberDetailsLoading:false , error:action.payload}

        case MEMBER_RESET_DETAILS:
            return{MemberDetailsInfo:{}}
        
        default:
            return state

    }

}


export const MembereUpdateReducer=(state={},action)=>{

    switch(action.type){
        case MEMBER_REQUEST :
            return{memberupdateLoading:true  }

        case MEMBER_UPDATE_SUCCESS :
            return{memberDetailsLoading:false , MemberDetailssuccess:true , MemberDetailsInfo:action.payload}  

        case MEMBER_UPDATE_FAIL :
            return {memberDetailsLoading:false , error:action.payload}

        case MEMBER_UPDATE_RESET:
            return{}
        
        default:
            return state

    }

}
