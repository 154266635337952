export const USER_PROFILE_REQUEST ='USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS ='USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL ='USER_PROFILE_FAIL'
export const USER_PROFILE_RESET='USER_PROFILE_RESET'


export const USER_PROFILE_REQUEST_UPDATE ='USER_PROFILE_REQUEST_UPDATE'
export const USER_PROFILE_SUCCESS_UPDATE ='USER_PROFILE_SUCCESS_UPDATE'
export const USER_PROFILE_FAIL_UPDATE ='USER_PROFILE_FAIL_UPDATE'


export const CHANGE_USER_LOGIN_REQUEST = 'CHANGE_USER_LOGIN_REQUEST'
export const CHANGE_USER_LOGIN_SUCCESS = 'CHANGE_USER_LOGIN_SUCCESS'
export const CHANGE_USER_LOGIN_FAIL= 'CHANGE_USER_LOGIN_FAIL'
export const CHANGE_USER_LOGOUT = 'CHANGE_USER_LOGOUT'