export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL= 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'



export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'



export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS='USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL='USER_DETAILS_FAIL'
export const USER_DETAILS_RESET= 'USER_DETAILS_RESET'



export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS='USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL='USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'



export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS='USER_SUCCESS'
export const USER_FAIL='USER_FAIL'
export const USER_RESET = 'USER_RESET'


export const USER_REQUEST_MENU = 'USER_REQUEST_MENU'
export const USER_SUCCESS_MENU='USER_SUCCESS_MENU'
export const USER_FAIL_MENU='USER_FAIL_MENU'
export const USER_RESET_MENU = 'USER_RESET_MENU'



export const SELETED_USER_DETAIL_REQUEST = 'SELETED_USER_DETAIL_REQUEST'
export const SELETED_USER_DETAIL_SUCCESS='SELETED_USER_DETAIL_SUCCESS'
export const SELETED_USER_DETAIL_FAIL='SELETED_USER_DETAIL_FAIL'
export const SELETED_USER_DETAIL_RESET = 'SELETED_USER_DETAIL_RESET'


export const USER_REQUEST_MENU_ITEMS = 'USER_REQUEST_MENU_ITEMS'
export const USER_SUCCESS_MENU_ITEMS='USER_SUCCESS_MENU_ITEMS'
export const USER_FAIL_MENU_ITEMS='USER_FAIL_MENU_ITEMS'
export const USER_RESET_MENU_ITEMS = 'USER_RESET_MENU_ITEMS'


export const USER_REQUEST_MENU_ITEMS_ADMIN = 'USER_REQUEST_MENU_ITEMS_ADMIN '
export const USER_SUCCESS_MENU_ITEMS_ADMIN ='USER_SUCCESS_MENU_ITEMS_ADMIN '
export const USER_FAIL_MENU_ITEMS_ADMIN ='USER_FAIL_MENU_ITEMS_ADMIN '
export const USER_RESET_MENU_ITEMS_ADMIN  = 'USER_RESET_MENU_ITEMS_ADMIN '



export const USER_REQUEST_MENU_ITEMS_DELETE = 'USER_REQUEST_MENU_ITEMS_DELETE'
export const USER_SUCCESS_MENU_ITEMS_DELETE  ='USER_SUCCESS_MENU_ITEMS_DELETE'
export const USER_FAIL_MENU_ITEMS_DELETE ='USER_FAIL_MENU_ITEMS_DELETE '
export const USER_RESET_MENU_ITEMS_DELETE   = 'USER_RESET_MENU_ITEMS_DELETE '


export const USER_REQUEST_UDATE_BRANNCH_LEVEL = 'USER_REQUEST_UDATE_BRANNCH_LEVEL'
export const USER_SUCCESS_UDATE_BRANNCH_LEVEL  ='USER_SUCCESS_UDATE_BRANNCH_LEVEL'
export const USER_FAIL_UDATE_BRANNCH_LEVEL ='USER_FAIL_UDATE_BRANNCH_LEVEL'
export const USER_RESET_UDATE_BRANNCH_LEVEL   = 'USER_RESET_UDATE_BRANNCH_LEVEL'


export const SELETED_PROFILE_DETAIL_REQUEST = 'SELETED_PROFILE_DETAIL_REQUEST'
export const SELETED_PROFILE_DETAIL_SUCCESS='SELETED_PROFILE_DETAIL_SUCCESS'
export const SELETED_PROFILE_DETAIL_FAIL='SELETED_PROFILE_DETAIL_FAIL'
export const SELETED_PROFILE_DETAIL_RESET = 'SELETED_PROFILE_DETAIL_RESET'