import React ,{useState ,useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch ,useSelector } from 'react-redux';
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2'
import moment from 'moment'
import { SaveNewVault,LoadVaultInfo ,getVaultDetailsAction } from '../actions/vaultAction';
import { VAULT_SET_RESET } from '../constants/vaultConstants';
import { FcHighPriority } from "react-icons/fc";
import { FcCheckmark } from "react-icons/fc";
import { approvalAction  } from '../actions/approvalAction';
import { APPROVAL_SENT_REST } from '../constants/approvalConstants';


function VaultSetup() {

    const [CreatNewVault ,setCreatNewVault]=useState(false)

    const ChartOfAccountReducer = useSelector(state => state. ChartOfAccountReducer)
    const {loadingchartOfAccount ,chartOfAccountsu ,chartOfAccount}= ChartOfAccountReducer


    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo }=userProfileReducer


    const createVaultReducer = useSelector(state => state.createVaultReducer)
    const { creatValutLoading ,vaultsu ,creatValut }=createVaultReducer


    const vaultReducer = useSelector(state => state.vaultReducer)
    const { vaultLoading ,vault}=vaultReducer


    const VaultDetailsReducer = useSelector(state => state.VaultDetailsReducer)
    const { VaultDetailsInfo ,vaultDetailsLoading ,vaultDetailssuccess}=VaultDetailsReducer


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

    const approvalsReducer = useSelector(state => state.approvalsReducer)
    const { approvalsu }=approvalsReducer


    const [map_acc_name ,setaccount]=useState('Select')
    const [map_acc_code ,setacc_code]=useState('')
    const [map_acc_type ,setacc_type]=useState('')
    const [map_acc_statment ,setacc_statement]=useState('')
    const [code ,setCode]=useState('')
    const [name ,setname]=useState('')
    const [balance ,setbalance ]=useState('')
    const [branch ,setbranch]=useState(userProfileInfo.branch)


    const dispatch=useDispatch()

useEffect(()=>{


  if(vaultsu){
    successMessage('Vault sucessfully Created')
    dispatch({type:VAULT_SET_RESET})
    celar()

    dispatch(LoadVaultInfo())
  }

},[createVaultReducer])


useEffect(()=>{

  dispatch(LoadVaultInfo())

},[dispatch])

useEffect(()=>{

  if(approvalsu){

    successMessage('Vault Information Loogged For De-Activation Authorization ')

    dispatch({type:APPROVAL_SENT_REST})
  }

},[approvalsReducer])




const celar=()=>{
  setCode('')
  setname('')
  setbalance('')
  setaccount('Select')
}
  
      const createNewVaultFunctionClose=() =>{
        setCreatNewVault(false)
        
        celar()
        }
  
  
        const successMessage=(message) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: message ,
            showConfirmButton: false,
            timer: 1500
          });
        }
      
      
        const successError=(message) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: message ,
            showConfirmButton: false,
            timer: 1500000
            
          
          });
      
        }
  
  
     

const saveVault=(e)=>{

  e.preventDefault()

  if(code=='' || name=='' || map_acc_name=='Select' || balance=='' ){

    successError('Please All Required Fields Must Be Fill Out')

  }else {

    let dat2 =moment().format('YYYY-MM-DD')
    let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
    let level=userProfileInfo.Authorisation_Level
    let save_by=userInfo.username
    


    dispatch(SaveNewVault(code ,name ,balance,dat ,dat2 ,map_acc_code ,map_acc_name ,map_acc_statment ,map_acc_type ,branch ,level ,save_by))
  }

}

const mapAccount=(e)=>{

    const selectedAccount=chartOfAccount.find(obj=>obj.name==e.target.value)
    console.log(selectedAccount)

   setaccount(selectedAccount.name)
 
   setacc_code(selectedAccount.acc_code)
   setacc_type(selectedAccount.acc_type)
   setacc_statement(selectedAccount.acc_statement)
  }

  const getVaultDetails=(va)=>{

    dispatch(getVaultDetailsAction(va))


    setCreatNewVault(true)
  }

  const makeInActive=(code ,name)=>{
   
    

      // Vault Account Creations

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Make Vault In-Active",
        text: "Are You Sure You Want to Make The Selected Vault In-Active ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

          //console.log(getuser)
          let level=userProfileInfo.Authorisation_Level
          let table='Vault_Account'
          let action=7
          let sent_by=userInfo.username
          let narration="Authorization To De-Activate " + name +" Vault Information With Vault Code " + code
          let table_pk=code;
          let branch=userProfileInfo.branch
    
  
      dispatch(approvalAction(table ,level ,action ,sent_by ,narration ,table_pk ,branch))
    
     
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    
  }

  const makeActive=(code ,name)=>{
   
    

    // Vault Account Creations

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Make Vault Active",
      text: "Are You Sure You Want to Make The Selected Vault Active ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
   

  }).then((result) => {

      if (result.isConfirmed) {

        //console.log(getuser)
        let level=userProfileInfo.Authorisation_Level
        let table='Vault_Account'
        let action=8
        let sent_by=userInfo.username
        let narration="Authorization To Activate " + name +" Vault Information With Vault Code " + code
        let table_pk=code;
        let branch=userProfileInfo.branch
  

    dispatch(approvalAction(table ,level ,action ,sent_by ,narration ,table_pk ,branch))
  
   
          

      } else if (result.dismiss === Swal.DismissReason.cancel) {

     
      }
      
  });

  
}

  useEffect(()=>{

    if(vaultDetailssuccess ){

      setCode(VaultDetailsInfo.code)
      setname(VaultDetailsInfo.name)
      setbalance(VaultDetailsInfo.balance)
      setaccount(VaultDetailsInfo.map_acc_name)
      
    }

  },[VaultDetailsReducer])

  return (
    <div>
        
        <h3>Vault Setup</h3>

                    
        <hr/>

        <button onClick={() => {setCreatNewVault(true)}} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Vault </button>


        <div className='solcon overflow-auto vh-100'>

        {creatValutLoading && <Loader/>}
        {vaultLoading && <Loader/>}
        {vaultDetailsLoading && <Loader/>}
    
      
        <div className='bra row me-2'>

                {vault.map(va=>(

                <div className='col-md-4 mb-4'>
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">{va.name}</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Vault Code : {va.code}</li>
                            <li className="list-group-item">Vault Branch : {va.branch}</li>
                            <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
                            {va.save_aproval ? 
                            ( 
                              <button onClick={()=>makeInActive(va.code ,va.name)} className="list-group-item btn-class"> Vault Is Active <FcCheckmark /> Make Vault In-Active</button>
                              
                            )
                           
                              : 

                              <button  onClick={()=>makeActive(va.code ,va.name)} className="list-group-item btn-class"> Vault Is In-Active <FcHighPriority /> Make Vault Active</button>
                             
                              
                              }
                            </div>
                        </ul>

                        </div>
                        <button onClick={()=>getVaultDetails(va.code)}   className='show_info_btn'>View Vault Details</button>
                    </div>
                    </div>

                                
                ))}

                </div>
                
        </div>



    <Modal size='lg' show={CreatNewVault} onHide={createNewVaultFunctionClose} backdrop='static' keyboard={false}>

    <Modal.Header  closeButton>
            <Modal.Title>Vault Infomation</Modal.Title>
        </Modal.Header>

    <Modal.Body>

      <form onSubmit={saveVault}>


      <div className='row mt-3'>

        <div className='col-md-6'>
        <div className='form-group'>
            <label className='lable' for='service code'><samp className='text-danger'>* </samp>Vault Code</label>
            <input type='number' value={code} onChange={(e)=>setCode(e.target.value)} required className='form-control' name='service code'/>
          
        </div>
        </div>


      <div className='col-md-6'>
      <div className='form-group'>

      <label className='lable' for='service name'><samp className='text-danger'>* </samp>Vault Name</label>
      <input type='type' value={name} onChange={(e)=>setname(e.target.value)}  required className='form-control' name='service Name'/>

      </div>
      </div>



      </div>


      <div className='row mt-4'>


        <div className='col-md-6'>
        <div className='form-group '>
            <label className='lable' for='product Name'><samp className='text-danger'>* </samp> Map Accounts</label>
                                        
            <select required  className='form-control'onChange={mapAccount} value={map_acc_name}  >
             <option required disabled selected value="Select" >Select</option>
              {chartOfAccount.map((chart)=>(
              <option value={chart.name}>{chart.name}</option>
              ))} 
                                                        
           </select>
        </div>
        </div>


      <div className='col-md-6'>
      <div className='form-group'>

        <label className='lable' for='service name'><samp className='text-danger'>* </samp>Vault Start-up Amount</label>
      <input type='number' value={balance}  onChange={(e)=>setbalance(e.target.value)} required className='form-control' name='service Name'/>

      </div>
      </div>

      </div>


        <button type='submit' className="show_info_btn mt-4">Save Vault Information</button> 
        
    

      </form>

    </Modal.Body>


</Modal>

      
    </div>
  )
}

export default VaultSetup
