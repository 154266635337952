import React  ,{useState ,useEffect}from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { getMemberDetailsAction } from '../actions/memberAction';
import Stepper from "awesome-react-stepper";
import Multiselect from 'multiselect-react-dropdown';
import ImageUploading from "react-images-uploading";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { Modal } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { MEMBER_RESET_DETAILS } from '../constants/memberConstants';
import { FcCheckmark } from "react-icons/fc";
import { getMemberCorAaccountDetailsAction ,getMemberCorAaccountSignatoryDetailsAction } from '../actions/customerAccountAction';

function SearchMemberInfomation() {

    const navigate = useNavigate();

    const [showsearch ,setshowsearch]=useState(true)

    const dispatch=useDispatch()

    const opensearch=()=>{
      setshowsearch(true)
    }
  
    const closersearch=()=>{
      //setshowsearch(false)
       navigate(-1)
       dispatch({type:MEMBER_RESET_DETAILS})
    }

    const MembereReducer = useSelector(state => state. MembereReducer)
    const {MemberInfo ,memberLoading ,memberSuccess ,MemberError}= MembereReducer

    const [getaprovemembers ,setGetaprovemembers]=useState()

    const handleOnSearch = (string, results) => {
      // onSearch will have as the first callback parameter
      // the string searched and for the second the results.
      //console.log(string, results)
    }
  
    const handleOnHover = (result) => {
      // the item hovered
     // console.log(result)
    }

 
  
    const handleOnSelect = (MemberInfo) => {
      // the item selected
      //console.log(MemberInfo.id)
      dispatch(getMemberDetailsAction(MemberInfo.id))

      try {

        dispatch(getMemberCorAaccountDetailsAction(MemberInfo.id))
        dispatch(getMemberCorAaccountSignatoryDetailsAction(MemberInfo.id))
        
      } catch (error) {
        
      }
      
     
      navigate(-1)
      //closersearch()
      
     
    }
  
    const handleOnFocus = () => {
      //console.log('Focused')
    }

    const formatResult = (MemberInfo) => {
      return (
        <>
         
          <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {MemberInfo.save_aproval==1 ? MemberInfo.name :''} </span>
          <span style={{ display: 'block', textAlign: 'left' }}> {MemberInfo.save_aproval==1 ? MemberInfo.tel :''}</span>
          
        </>
      )
    }

    useEffect(()=>{

      setGetaprovemembers(MemberInfo.filter(obj=>obj.save_aproval==true))

    

    },[])

  return (
    <div>

<Modal fullscreen={true} show={showsearch} onHide={closersearch} backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
             <Modal.Title>Search Member Infomation</Modal.Title>
              </Modal.Header>
               <Modal.Body className='d-flex justify-content-center' >
                    
                <header className="App-header mb-3">
                    <div style={{ width: 600 }}>
                     
                      <ReactSearchAutocomplete
                        items={getaprovemembers}
                      // fuseOptions={{ keys: ["trans_code", "tel"] }}
                      // resultStringKeyName="tel"
                      
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      placeholder='Search Member Name'
                      />
                    </div>
                  </header>
              
                  
              </Modal.Body>
                   
    </Modal>
      
    </div>
  )
}

export default SearchMemberInfomation
