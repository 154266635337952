import { createStore , combineReducers  ,applyMiddleware } from  'redux'

import {thunk} from 'redux-thunk'

import { composeWithDevTools } from "@redux-devtools/extension";

import { userLoginReducer ,userRegisterReducer  ,userDetailsReducer ,userUpdateProfileReducer ,ALLUserReducer,userMenuReducer 
     ,SelectedUserInfo ,userMenuItems ,userMenuItemsAdmin ,deleteUserMenuItem,userUpdateBranchLevelReducer ,SelectedProfileInfo} from './reducers/userReducers'

import { userProfileReducer ,userProfileReducerUpdate} from './reducers/profileReducer';

import { changeUserLoginReducer } from './reducers/profileReducer';

import { bankInfoReducer ,updateBankInfo ,registerBranchReducer ,branchDetailsReducer ,selectedBranchDetailsReducer ,branchReducerUpdate ,
     registerProductReducer ,productReducer ,updateProduct ,registerServiceReducer ,seriveReducer ,updateService 
     ,ChartOfAccountReducer ,saveChartOfAccount
} from './reducers/settingReducer';

import { registerMembereReducer , MembereReducer ,MembereDetailsReducer } from './reducers/memberReducer';

import { approvalReducer ,approveMemberSaveReducer ,memberApproveReducer ,approvalsReducer ,apro_Message_Reducer ,save_Message_Reducer ,user_Message_Reducer } from './reducers/approvalReducer';

import { customerAccountUpdateReducer ,CustomerAccountReducer ,createBankAccountReducer ,CustomerAccountDetailsReducer ,CustomerAccountTypeDetailsReducer ,
     
     CustomerAccountNumberReducer ,saveAutoService ,CustomerAutoServiceReducer ,DepositReducer,accountTransactionReducer ,CustomerAccountBalanceReducer ,USERAccountBalanceReducer
  ,createCorAccountReducer ,createsignatoryReducer ,CorporateAccountDetailsReducer ,CorporateAccountsignatoryDetailsReducer ,accountAutoServiceReducer ,CustomerAccountTrancationReducer} from './reducers/customerAccountReducer';

 import { createVaultReducer ,vaultReducer  ,VaultDetailsReducer ,aprovelVaultReducer ,branchVaultReducer ,VaultBalanceReducer ,createUserVaultReducer 
     ,TreasureTodayReducer ,createUserVaultSettingReducer ,TreasureTodaySettingReducer ,saveVaultTransactionReducer ,updateUserVaultReducer ,UserTreasureTodayReducer} from './reducers/vaultReducer';



const reducer = combineReducers({

     //User
     userLogin:userLoginReducer ,

     userRegister :userRegisterReducer ,
 
     userDetails : userDetailsReducer ,
 
     userUpdateProfile:userUpdateProfileReducer ,

     ALLUserReducer:ALLUserReducer ,

     userMenuReducer ,userMenuReducer,

     SelectedUserInfo:SelectedUserInfo ,

     userMenuItems:userMenuItems ,

     userMenuItemsAdmin:userMenuItemsAdmin,

     deleteUserMenuItem:deleteUserMenuItem,

     userUpdateBranchLevelReducer:userUpdateBranchLevelReducer,

     SelectedProfileInfo:SelectedProfileInfo ,

     //userprofile

     userProfileReducer:userProfileReducer ,

     userProfileReducerUpdate:userProfileReducerUpdate ,

     changeUserLoginReducer:changeUserLoginReducer ,

     

     //Settings
     bankInfoReducer:bankInfoReducer ,

     updateBankInfo:updateBankInfo ,

     registerBranchReducer:registerBranchReducer ,

     branchDetailsReducer:branchDetailsReducer ,

     selectedBranchDetailsReducer:selectedBranchDetailsReducer ,

     branchReducerUpdate:branchReducerUpdate ,

     registerProductReducer:registerProductReducer ,

     productReducer:productReducer ,

     updateProduct:updateProduct ,

     registerServiceReducer:registerServiceReducer ,

     seriveReducer:seriveReducer ,

     updateService:updateService ,

     registerMembereReducer:registerMembereReducer ,

     MembereReducer:MembereReducer ,

     MembereDetailsReducer : MembereDetailsReducer,

     saveChartOfAccount:saveChartOfAccount,

     ChartOfAccountReducer:ChartOfAccountReducer,

     CorporateAccountDetailsReducer:CorporateAccountDetailsReducer,

     CorporateAccountsignatoryDetailsReducer:CorporateAccountsignatoryDetailsReducer,

   //approvals
     approvalReducer:approvalReducer ,

     approveMemberSaveReducer:approveMemberSaveReducer ,

     memberApproveReducer:memberApproveReducer,

     approvalsReducer:approvalsReducer,

     apro_Message_Reducer:apro_Message_Reducer,

     save_Message_Reducer:save_Message_Reducer,

     user_Message_Reducer :user_Message_Reducer,


     //Customer Account 
     createBankAccountReducer:createBankAccountReducer,

     customerAccountUpdateReducer:customerAccountUpdateReducer ,

     CustomerAccountReducer:CustomerAccountReducer ,

     CustomerAccountDetailsReducer:CustomerAccountDetailsReducer ,

     CustomerAccountTypeDetailsReducer:CustomerAccountTypeDetailsReducer ,

     CustomerAccountNumberReducer:CustomerAccountNumberReducer ,

     saveAutoService:saveAutoService ,

     CustomerAutoServiceReducer:CustomerAutoServiceReducer ,

     createCorAccountReducer:createCorAccountReducer ,

     createsignatoryReducer:createsignatoryReducer,

     accountAutoServiceReducer:accountAutoServiceReducer,

     CustomerAccountTrancationReducer:CustomerAccountTrancationReducer ,


     //Deposit
     DepositReducer:DepositReducer ,

     accountTransactionReducer:accountTransactionReducer ,

     CustomerAccountBalanceReducer:CustomerAccountBalanceReducer ,

     USERAccountBalanceReducer:USERAccountBalanceReducer,

     //vault

     createVaultReducer:createVaultReducer ,

     vaultReducer:vaultReducer ,

     VaultDetailsReducer:VaultDetailsReducer ,

     aprovelVaultReducer:aprovelVaultReducer ,

     branchVaultReducer:branchVaultReducer ,

     VaultBalanceReducer:VaultBalanceReducer ,

     createUserVaultReducer:createUserVaultReducer ,

     TreasureTodayReducer:TreasureTodayReducer ,

     createUserVaultSettingReducer:createUserVaultSettingReducer ,

     TreasureTodaySettingReducer:TreasureTodaySettingReducer ,

     saveVaultTransactionReducer:saveVaultTransactionReducer ,

     updateUserVaultReducer:updateUserVaultReducer ,

     UserTreasureTodayReducer:UserTreasureTodayReducer
})


const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null


const userProfileInfoFromStorage = localStorage.getItem('userProfileInfo') ?
    JSON.parse(localStorage.getItem('userProfileInfo')) : null

    
const bankInfoFromStorage = localStorage.getItem('bankInfo') ?
     JSON.parse(localStorage.getItem('bankInfo')) : null


const branchsInfoFromStorage = localStorage.getItem('branchsInfo') ?
     JSON.parse(localStorage.getItem('branchsInfo')) : null


const productfoFromStorage = localStorage.getItem('products') ?
     JSON.parse(localStorage.getItem('products')) : null


const servicefoFromStorage = localStorage.getItem('services') ?
     JSON.parse(localStorage.getItem('services')) : null


const memberFromStorage = localStorage.getItem('MemberInfo') ?
     JSON.parse(localStorage.getItem('MemberInfo')) : null

const userFromStorage = localStorage.getItem('AllUser') ?
     JSON.parse(localStorage.getItem('AllUser')) : null


const menuFromStorage = localStorage.getItem('MenuItems') ?
     JSON.parse(localStorage.getItem('MenuItems')) : null



const chartOfAccountFromStorage = localStorage.getItem('chartOfAccount') ?
    JSON.parse(localStorage.getItem('chartOfAccount')) : null


const AccountNumberFromStorage = localStorage.getItem('accountNumber') ?
    JSON.parse(localStorage.getItem('accountNumber')) : null


const autoServicesFromStorage = localStorage.getItem('autoServices') ?
    JSON.parse(localStorage.getItem('autoServices')) : null


     
const TreasureTodayReducerFromStorage = localStorage.getItem('TreasureToday') ?
    JSON.parse(localStorage.getItem('TreasureToday')) : null
     
     
  
const initalState = {

     userLogin:{userInfo:userInfoFromStorage} ,

     userProfileReducer:{userProfileInfo:userProfileInfoFromStorage} ,

     bankInfoReducer:{bankInfo:bankInfoFromStorage},

     branchDetailsReducer:{branchsInfo:branchsInfoFromStorage} ,

     productReducer:{products:productfoFromStorage} ,

     seriveReducer:{services:servicefoFromStorage} ,

     MembereReducer:{MemberInfo:memberFromStorage} ,

     ALLUserReducer:{AllUser:userFromStorage} ,

     userMenuItems:{MenuItems:menuFromStorage},

     ChartOfAccountReducer:{chartOfAccount:chartOfAccountFromStorage} ,

     CustomerAccountNumberReducer:{accountNumber:AccountNumberFromStorage} ,

     TreasureTodayReducer:{TreasureToday:TreasureTodayReducerFromStorage}



     //CustomerAutoServiceReducer:{autoServices:autoServicesFromStorage }
     
   
}

const middleware = [thunk]

const store = createStore(reducer , initalState ,composeWithDevTools(applyMiddleware(...middleware)))

export default store