import React ,{useState ,useEffect}from 'react'
import Stepper from "awesome-react-stepper";
import { Modal } from 'react-bootstrap';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css'
import { registerMember  ,memberList ,getMemberDetailsAction ,memberUpdateActions} from '../actions/memberAction';
import { MEMBER_REGISTRATION_RESET } from '../constants/memberConstants';
import { useDispatch ,useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import Loader from '../Components/Loader';
import DataTable from 'react-data-table-component'
import { BiSolidReport } from "react-icons/bi";
import { MdOutlineEdit } from "react-icons/md";
import { approvalAction ,approveMemberActions } from '../actions/approvalAction';
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import ImageUploading from "react-images-uploading";
import { resizeFile } from '../Components/Message';

function Membership() {

    var zeroFill = require('zero-fill')

    const [creatNewMemeber ,SetCreatNewMemeber]=useState(false)
    const [show, setShow] = useState(false);


    const handleCloseModal = () => setShow(false);
    const handleShowmModal = () => setShow(true);

    const dispatch=useDispatch()

    const cloaseAddMember =()=>{
        SetCreatNewMemeber(false)
        setshowMemberInfo(false)
    }

    const OpenAddMember =()=>{
        SetCreatNewMemeber(true)

        if(showMemberInfo){

          

        }else {

            clear()

        }
       
    }


    const successMessage=(message) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message ,
          showConfirmButton: false,
          timer: 10000
        });
      }
    
    
    const ErrorMessage=(message) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: message ,
          showConfirmButton: false,
          timer: 1500000
          
        
        });
    
      }

    const registerMembereReducer = useSelector(state => state. registerMembereReducer)
    const {registerMemberInfo ,registermemberLoading ,error ,success}= registerMembereReducer
    


    const MembereReducer = useSelector(state => state. MembereReducer)
    const {MemberInfo ,memberLoading ,memberSuccess ,MemberError}= MembereReducer

    const [memberDate , setMemberDate]=useState(MemberInfo) 

    const searchMember =(e)=>{

        setsearch(e.target.value)

        const newData =MemberInfo.filter(row=>{

           
      return row.name.toLowerCase().match(e.target.value.toLowerCase())
            
        })
        setMemberDate(newData)
      }


    const MembereDetailsReducer = useSelector(state => state. MembereDetailsReducer)
    const {MemberDetailsInfo ,MemberDetailssuccess ,memberDetailsLoading}= MembereDetailsReducer

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo }=userProfileReducer
 
    const approveMemberSaveReducer = useSelector(state => state. approveMemberSaveReducer)
    const {approvMemberSaveLoading ,approvMemberSave  }= approveMemberSaveReducer

    const [memberid ,SetMemberid]=useState('')
    const [pic ,setpic]=useState('')
    const [sex ,SetSex]=useState('Select')
    const [name ,setname]=useState('')
    const [r_add ,setr_add]=useState('')
    const [pob ,setpob]=useState('')
    const[reg ,setreg]=useState('')
    const [nland ,setnland]=useState('')
    const[tel ,settel]=useState('')
    const[email ,setemail]=useState('')
    const[dob ,setdob]=useState('')
    const [city ,setcity]=useState('')
    const [edu ,setedu]=useState('')
    const[mar ,setmar]=useState('Select')
    const[card_Id ,setcard_Id]=useState('')
    const[card_ty,setcard_ty]=useState('Select')
    const[card_is_dat ,setcard_is_dat]=useState('')
    const[card_ex_date,setcard_ex_date]=useState('')


    const [income_s ,setincome_s]=useState('')
    const [b_long ,Setb_long]=useState('')
    const [bus ,setbus]=useState('')
    const [b_reg ,setb_reg]=useState('')
    const [b_reg_no ,setb_reg_no]=useState('')
    const[dat_r ,setdat_r]=useState('')
    const [dat_in ,setdat_in]=useState('')
    const[nat_b ,setnat_b]=useState('')
    const[founder ,setfounder]=useState('')
    const[found_con ,setfound_con]=useState('')
    const [bus_loc ,setbus_loc]=useState('')

    const [nk1 ,setnk1]=useState('')
    const[nkc1 ,setnkc1]=useState('')
    const[nkr1 ,setnkr1]=useState('')
    const[nkb1 ,setnkb1]=useState('')

    const [nk2 ,setnk2]=useState('')
    const[nkc2 ,setnkc2]=useState('')
    const[nkr2 ,setnkr2]=useState('')
    const[nkb2 ,setnkb2]=useState('')
    const [save_by ,setsave_by]=useState(userInfo.username)
    const [branch ,setbranch]=useState(userProfileInfo.branch)
    const [level ,setlevel]=useState(userProfileInfo.Authorisation_Level)
    const [search ,setsearch]=useState('')

    const[other_info ,setother_info]=useState('')


const membercolumns =[
    {
     name:'Id' ,
     selector:row=>zeroFill(7,row.trans_code) ,
     width: "100px" 
     
     ,wrap:true 

    } ,

     {
      name:'Name' ,
      selector:row=>row.name,
      width: "270px" 
      ,wrap:true
     },

     {
      name:'Gender',
      selector:row=>row.sex ,
      width: "90px" 
      ,wrap:true ,
      sortable:true
     },

     {
        name:'Age',
        selector:row=>row.age ,
        width: "70px" 
        ,wrap:true ,
        sortable:true
       },

     {
        name:'Address',
        selector:row=>row.r_add ,
        width: "100px" 
        ,wrap:true ,
        sortable:true
       },

       
       {
        name:'Telephone',
        selector:row=>row.tel ,
        width: "140px" 
        ,wrap:true ,
        sortable:true
       },
     
       {
        name:'Card Id',
        selector:row=>row.card_Id ,
        width: "200px" 
        ,wrap:true ,
        sortable:true
       },

       {name:'Image' , 
       selector:row=> <img className="rounded-circle"  alt='' width={40} height={40}  src={row.pic}  /> , 
       width:"80px" 
    
    } ,

       {
       name:'Edit' , cell:row=> <button onClick={()=>selete_member_details(row)} className='btt'> <MdOutlineEdit className='preview-btn'/> </button>,
       width:"70px"
      }  ,

      {
        name:'Report' , cell:row=> <button className='btt'> <BiSolidReport className='preview-btn'/> </button>,
       width:"70px"
      }  ,

      {
        
       name:'Active' , cell:row=> <div className="form-check form-switch">
       <input class="form-check-input" type="checkbox"  checked={row.save_aproval}  />
      
     </div>,
       width:"70px"
      }  ,
    

]

const [showMemberInfo ,setshowMemberInfo]=useState(false)

const selete_member_details=(row )=>{

    setshowMemberInfo(true)
    dispatch(getMemberDetailsAction(row.trans_code))

   
}


useEffect(()=>{

    if(MemberDetailssuccess & showMemberInfo){

        SetMemberid(MemberDetailsInfo.trans_code)
        setname(MemberDetailsInfo.name)
        setpic(MemberDetailsInfo.pic)
        SetSex(MemberDetailsInfo.sex)
        setr_add(MemberDetailsInfo.r_add)
        setpob(MemberDetailsInfo.pob)
        setreg(MemberDetailsInfo.reg)
        setnland(MemberDetailsInfo.nland)
        settel(MemberDetailsInfo.tel)
        setemail(MemberDetailsInfo.email)
        setdob(MemberDetailsInfo.dob)
        setcity(MemberDetailsInfo.city)
        setedu(MemberDetailsInfo.edu)
        setmar(MemberDetailsInfo.mar)
        setcard_Id(MemberDetailsInfo.card_Id)


        setincome_s(MemberDetailsInfo.income_s)
        Setb_long(MemberDetailsInfo.b_long)
        setbus(MemberDetailsInfo.bus)
        setb_reg(MemberDetailsInfo.b_reg)
        setb_reg_no(MemberDetailsInfo.b_reg_no)
        setdat_r(MemberDetailsInfo.dat_r)
        setdat_in(MemberDetailsInfo.dat_in)
        setnat_b(MemberDetailsInfo.nat_b)
        setfounder(MemberDetailsInfo.founder)
        setfound_con(MemberDetailsInfo.found_con)
        setbus_loc(MemberDetailsInfo.bus_loc)

        setnk1(MemberDetailsInfo.nk1)
        setnkc1(MemberDetailsInfo.nkc1)
        setnkr1(MemberDetailsInfo.nkr1)
        setnkb1(MemberDetailsInfo.nkb1)

        setnk2(MemberDetailsInfo.nk2)
        setnkc2(MemberDetailsInfo.nkc2)
        setnkr2(MemberDetailsInfo.nkr2)
        setnkb2(MemberDetailsInfo.nkb2)

        setcard_ty(MemberDetailsInfo.card_ty)
        setcard_is_dat(MemberDetailsInfo.card_is_dat)
        setcard_ex_date(MemberDetailsInfo.card_ex_date)

        setother_info(MemberDetailsInfo.other_info)

        setpic(MemberDetailsInfo.pic)
        

        OpenAddMember()
        setshowMemberInfo(true)

    }


    if(success){

        if(MemberDetailsInfo.trans_code==memberid){

            successMessage('Member Information Successfully Updated')
            cloaseAddMember()

        }else {

            successMessage('Member Information Successfully Loogged For Authorization')

        }

        

       dispatch({type: MEMBER_REGISTRATION_RESET})

        dispatch(memberList())

         clear() 
  
    }

    if(approvMemberSave){

        dispatch(memberList())
    }
   

},[MembereDetailsReducer ,registerMemberInfo ,approveMemberSaveReducer ])


useEffect(()=>{

    setMemberDate(MemberInfo)

},[MemberInfo])


const handleTakePhoto=(dataUri)=>{
    setpic(dataUri)
    console.log(pic)
}


const save_step = ()=>{
    
    if(name=='' || sex=='Select' || r_add=='' || nland=='' || tel=='' || card_Id=='' || pob=='' || reg=='' || email=='' || dob=='' || city=='' || edu=='' || mar=='Select' 
        || card_ty=='Select' || card_is_dat==''|| card_ex_date==''){

        ErrorMessage('Please All Required Fields Must Be Fill Out')
        
    }else if(!(tel.match('^[0-9]{10}$'))){

        ErrorMessage('Please provide valid Telephone Number')

    }else if(!email.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$')){

        ErrorMessage('Please provide valid Email')

    }else if(dob==''){

        ErrorMessage('Please provide valid Date Of Birth')
    
        
    }else {

        if(MemberDetailsInfo.trans_code==memberid){

            let level=MemberDetailsInfo.level
            let save_aproval=MemberDetailsInfo.save_aproval
            let edit_aproval=false
            let edit_by='-'

            console.log(reg)

            dispatch(memberUpdateActions({trans_code:memberid,
                sex ,name ,r_add ,pob ,reg ,nland ,tel ,email ,dob ,city ,edu ,mar ,card_Id ,
                income_s , b_long ,bus , b_reg , b_reg_no , dat_r , dat_in , nat_b , founder , found_con , bus_loc ,
                nk1 , nkc1 , nkr1 , nkb1 , nk2 , nkc2 , nkr2 , nkb2 ,pic ,other_info , card_ty , card_is_dat , card_ex_date ,level
            }))

            
            dispatch(approveMemberActions({trans_code:MemberDetailsInfo.trans_code ,
                save_aproval ,
                edit_aproval,
                edit_by,
                level
            }))

           

        }else {

            dispatch(registerMember(sex ,name ,r_add ,pob ,reg ,nland ,tel ,email ,dob ,city ,edu ,mar ,card_Id ,
                income_s , b_long ,bus , b_reg , b_reg_no , dat_r , dat_in , nat_b , founder , found_con , bus_loc ,
                nk1 , nkc1 , nkr1 , nkb1 , nk2 , nkc2 , nkr2 , nkb2 ,pic ,other_info , card_ty , card_is_dat , card_ex_date ,save_by ,branch,level
             ))

             
        }
    }
   
    
}

const clear =()=>{
    setpic('')
    SetSex('Select')
    setname('')
    setr_add('')
    setpob('')
    setreg('')
    setnland('')
    settel('')
    setemail('')
    setdob('')
    setcity('')
    setedu('')
    setmar('Select')
    setcard_Id('')
    SetMemberid('')

    setincome_s('')
    Setb_long('')
    setbus('')
    setb_reg('')
    setb_reg_no('')
    setdat_r('')
    setdat_in('')
    setnat_b('')
    setfounder('')
    setfound_con('')
    setbus_loc('')

    setnk1('')
    setnkc1('')
    setnkr1('')
    setnkb1('')

    setnk2('')
    setnkc2('')
    setnkr2('')
    setnkb2('')
    setcard_ty('Select')
    setcard_is_dat('')
    setcard_ex_date('')

    setother_info('')
}

const maxNumber = 69;

const [picImages2, setpicImages2] = useState([]);


const picOnChange2 = async (imageList, addUpdateIndex) => {
    // data for submit
    setpicImages2(imageList);

    if(imageList.length < 1){

      setpic('')

    }else {

    const image = await resizeFile(imageList[0].file);        
      setpic(image)
     
    }
   
  };


    
  return (
    <div>

<h3>Membership Infomation</h3>

<hr/>

<div className='solcon overflow-auto vh-100'>

<div className='container'>


<button onClick={OpenAddMember} className='btn-class py-2 px-4 mt-2 mb-3'>Add New Member Information</button>

<div className='row'>
        <div className='col-md-6'>
        <div className='form-group '>
                    
        <input value={search} onChange={searchMember} type='text' name='product Name' required className='form-control search__input mt-3 mb-3' placeholder='Search'  />
        </div>
        </div>
    </div>


<div className='card table_buttom_margin  '>
    
            {memberLoading && <Loader/>}   
            {registermemberLoading && <Loader/>}

            {memberDetailsLoading && <Loader/>}

             <DataTable className='rdt_Table' columns={membercolumns}  data={memberDate=='' ? MemberInfo : memberDate}   
             selectableRowsHighlight 
             highlightOnHover
              fixedHeader
               pagination 
                paginationPerPage={10}
                //onSelectedRowsChange={getSelectedSubjects}
               // selectableRows  
                 //selectableRowSelected={getPatientInfo} 
                //</div> onRowClicked={getPatientInfo}
                responsive={true}
                >
 
             </DataTable>
     
</div>



  <Modal size='lg' show={creatNewMemeber} onHide={cloaseAddMember} backdrop='static' keyboard={false}>

        <Modal.Header  closeButton>
                <Modal.Title><h4>Member Infomation</h4></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                
                    <Stepper
                    strokeColor="#00000"
                    fillStroke="#012970"
                    activeColor="#06A3DA"
                    activeProgressBorder="2px solid #06A3DA"
                    defaultActiveStep='true'
                    
                    submitBtn={<button className="btn-class">Save</button>}
                    continueBtn={<button className="show_info_btn">Next Page</button>}
                    backBtn={<button className="show_info_btn">Previous Page</button>}
                    onSubmit={

                        (step) =>  save_step() //alert(`Thank you!!! Final Step -> ${step}`)

                    }
                    >
                  
                    <div className="stepperSubDiv">

                        <h3>Personal Details</h3>

                        <div className="row mt-4 mb-3">

                        <div className="col-md-8 col-lg-8">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Full Name <samp className='text-primary'> [ Surname / First Name / Middle Name(s) ]</samp> </label>
                          <input name="Name" required type="text" className="form-control" value={name}
                             onChange={(e)=>setname(e.target.value)} />
                        </div>


                        <div className="col-md-4 col-lg-4">
                        <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Gender</lable>
                              <select required  className='form-control' value={sex} onChange={(e)=>SetSex(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Famale">Famale</option>
                              </select>
                        </div>

                  
                        </div>
                        
                    
                        <div className="row mb-3">

                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Residential Address </label>
                                <input name="Name" required type="text" className="form-control" value={r_add} 
                                onChange={(e)=>setr_add(e.target.value)} />
                            </div>


                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Place Of Birth</label>
                                <input name="Name" required type="text" className="form-control" value={pob}  
                                onChange={(e)=>setpob(e.target.value)}/>
                            </div>


                            </div>


                            <div className="row mb-3">

                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Region </label>
                            <select required  className='form-control' value={reg} onChange={(e)=>setreg(e.target.value)} >
                                  <option selected value="" >Select</option>
                                  <option value="Northern">Northern</option>
                                  <option value="Ashanti">Ashanti</option>
                                  <option value="Western">Western</option>
                                  <option value="Volta">Volta</option>
                                  <option value="Eastern">Eastern</option>
                                  <option value="Upper West">Upper West</option>
                                  <option value="Upper East">Upper East</option>
                                  <option value="Greater Accra">Greater Accra</option>
                                  <option value="Savannah">Savannah</option>
                                  <option value="North East">North East</option>
                                  <option value="Bono East">Bono East</option>
                                  <option value="Oti">Oti</option>
                                  <option value="Ahafo">Ahafo</option>
                                  <option value="Bono">Bono</option>
                                  <option value="Western North">Western North</option>
                              </select>
                            </div>


                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Nearest Landmark</label>
                            <input name="Name"  required  type="text" className="form-control" value={nland} 
                            onChange={(e)=>setnland(e.target.value)} />
                            </div>
                            
                            </div>



                            <div className="row mb-3">

                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Telephone Number </label>
                            <input name="number" required type="number" className="form-control"  value={tel} 
                            onChange={(e)=>settel(e.target.value)}/>
                            </div>


                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Email</label>
                            <input name="email" type="email" className="form-control" value={email} 
                            onChange={(e)=>setemail(e.target.value)} />
                            </div>


                            </div>



                            <div className="row mb-3">

                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Date Of Birth </label>
                            <input name="number" type="date" className="form-control" value={dob} 
                            onChange={(e)=>setdob(e.target.value)} />
                            </div>


                            <div className="col-md-6 col-lg-6">
                            <label className='lable' for='Surname'><samp className='text-danger'>* </samp>City</label>
                            <input name="Place" type="text" className="form-control" value={city} 
                            onChange={(e)=>setcity(e.target.value)}/>
                            </div>

                            </div>



                        <div className="row mb-3">

                        <div className="col-md-6 col-lg-6">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Educational Status</label>
                          <select required  className='form-control' value={edu} onChange={(e)=>setedu(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Tertiary">Tertiary</option>
                                  <option value="Secondary">Secondary</option>
                                  <option value="Others">Others</option>
                              </select>
                        </div>


                        <div className="col-md-6 col-lg-6">
                        <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Marital Status</lable>
                              <select required  className='form-control' value={mar} onChange={(e)=>setmar(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Married">Married</option>
                                  <option value="Single">Single</option>
                                  <option value="Divorce">Divorce</option>
                              </select>
                        </div>

                  
                        </div>


                        <div className="row mb-3">

                        <div className="col-md-6 col-lg-6">
                          <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
                                <select required  className='form-control' value={card_ty} onChange={(e)=>setcard_ty(e.target.value)} >
                                    <option selected value="Select" >Select</option>
                                    <option value="Voters Card">Voters Card</option>
                                    <option value="Ghana Card">Ghana Card</option>
                                    <option value="Drivers License">Drivers License</option>
                                    <option value="Passport">Passport</option>
                                    <option value="SSNIT Card">SSNIT Card</option>
                                </select>
                          </div>


                        <div className="col-md-6 col-lg-6">
                        <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Id N0.</label>
                        <input name="Name"  required  type="text" className="form-control" value={card_Id} 
                        onChange={(e)=>setcard_Id(e.target.value)} />
                        </div>


                        </div>

                        <div className="row">

                        <div className="col-md-6 col-lg-6">
                        <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Id Issue Date </label>
                        <input name="date" type="date" className="form-control" value={card_is_dat} onChange={(e)=>setcard_is_dat(e.target.value)} />
                        </div>


                        <div className="col-md-6 col-lg-6">
                        <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Id Expiry Date</label>
                        <input name="date" type="date" className="form-control" value={card_ex_date} onChange={(e)=>setcard_ex_date(e.target.value)}/>
                        </div>

                        </div>


                    </div>

                    

                    <div className="stepperSubDiv">
                    <h3>Economic Activities /Corporate Details</h3>

                    <div className="row mt-3 mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 lable">Employment Status:</label>
                        
                        <div className="col-md-3 col-lg-3">

                        <div className="form-check">
                            <input className="form-check-input"  type="radio" name="income_s"  value='Self Employed' 
                            checked={income_s=='Self Employed'} onChange={(e)=>setincome_s(e.target.value)}/>
                            <label className="form-check-label" for="Auto">Self Employed</label>
                        </div>
                           
                        </div>

                        <div className="col-md-4 col-lg-4">

                        <div className="form-check">
                                  <input className="form-check-input"  type="radio" name="income_s"  value='Employed By A Company'
                                  checked={income_s=='Employed By A Company'} onChange={(e)=>setincome_s(e.target.value)}/>
                                  <label className="form-check-label" for="Auto">Employed By A Company</label>
                                </div>
                           
                        </div>

                        </div>


                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">If Self Employed How Long?:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="text" className="form-control" value={b_long} onChange={(e)=>Setb_long(e.target.value)} />
                        </div>
                        </div>

                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Name Of Business:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="text" className="form-control" value={bus} onChange={(e)=>setbus(e.target.value)}/>
                        </div>
                        </div>

                        <div className="row mb-2">
                        <label for="Address" className="col-md-4 col-lg-4 lable">Is Your Business Registered?:</label>
                        
                        <div className="col-md-3 col-lg-3">

                        <div className="form-check">
                            <input className="form-check-input"  type="radio" name="b_reg"  value='Yes' 
                            checked={b_reg=='Yes'} onChange={(e)=>setb_reg(e.target.value)}/>
                            <label className="form-check-label" for="Auto">Yes</label>
                        </div>
                           
                        </div>

                        <div className="col-md-4 col-lg-4">

                        <div className="form-check">
                                  <input className="form-check-input"  type="radio" name="b_reg"  value='No'  
                                  checked={b_reg=='No'} onChange={(e)=>setb_reg(e.target.value)}/>
                                  <label className="form-check-label" for="Auto">No</label>
                                </div>
                           
                        </div>

                        </div>

                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Business Registration Number:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="number" type="text" className="form-control" value={b_reg_no} 
                            onChange={(e)=>setb_reg_no(e.target.value)} />
                        </div>
                        </div>

                        <div className="row  mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Date Of Registration:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="date" className="form-control" value={dat_r}
                             onChange={(e)=>setdat_r(e.target.value)} />
                        </div>
                        </div>

                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Date Of Incorporation:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="date" className="form-control" value={dat_in}
                             onChange={(e)=>setdat_in(e.target.value)}/>
                        </div>
                        </div>

                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Nature Of Business:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="text" className="form-control" value={nat_b} 
                            onChange={(e)=>setnat_b(e.target.value)}/>
                        </div>
                        </div>

                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Founders separate by comma:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="text" className="form-control" value={founder} 
                            onChange={(e)=>setfounder(e.target.value)} />
                        </div>
                        </div>

                        <div className="row mb-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Contacts separate by comma:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="text" className="form-control" value={found_con} 
                            onChange={(e)=>setfound_con(e.target.value)} />
                        </div>
                        </div>

                        <div className="row mt-3">
                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Business Location:</label>
                        <div className="col-md-8 col-lg-8">
                            <input name="Name" type="text" className="form-control" value={bus_loc} 
                            onChange={(e)=>setbus_loc(e.target.value)} />
                        </div>
                        </div>



                    </div>
                    <div className="stepperSubDiv">
                    <h3>Next Of Kin And Beneficiary</h3>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-header'>1st Beneficiary</div>
                                <div className='card-body'>

                                    <div className="row">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Full Name:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="text" className="form-control" value={nk1} 
                                            onChange={(e)=>setnk1(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Contact:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="number" className="form-control" value={nkc1}
                                             onChange={(e)=>setnkc1(e.target.value )}/>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Relation:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="text" className="form-control" value={nkr1}
                                             onChange={(e)=>setnkr1(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Benefits %:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="text" className="form-control"  value={nkb1} 
                                            onChange={(e)=>setnkb1(e.target.value)}/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>



                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-header'>2nd Beneficiary</div>
                                <div className='card-body'>

                                    <div className="row">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Full Name:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="text" className="form-control" value={nk2}
                                             onChange={(e)=>setnk2(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Contact:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="number" className="form-control" value={nkc2} 
                                            onChange={(e)=>setnkc2(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Relation:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="text" className="form-control" value={nkr2} 
                                            onChange={(e)=>setnkr2(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Benefits %:</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="Name" type="text" className="form-control" value={nkb2} 
                                            onChange={(e)=>setnkb2(e.target.value)} />
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>




                    </div>

                  

                    </div>

                    <div className="stepperSubDiv">
                    <h3>Image / Other Infomation</h3>

                    <button className='show_info_btn mt-2' onClick={handleShowmModal}>  Open Camera</button>

                    <ImageUploading 
                    multiple
                    value={picImages2}
                    onChange={picOnChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            picImages2.length ==1 ? '' :(
                                <button className='show_info_btn mt-3'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse For Pictuer
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                           
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>
                    
                    
                    <div className='row'>
                    <div className='col-md-4'>

                    <div className="card mt-2">
                    <div className="embed-responsive embed-responsive-16by9">
                        {pic !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item"  src={pic} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item" src="/images/profile-img.jpg" />)}
                    </div>
                    </div>



                    <button className='btn-closee mt-3' > <i class="fa-solid fa-broom"></i> clear Pictuer</button>

                        </div>

                    <div className='col-md-8'>

                    <div className="row">
                            <strong for="Address" className="col-md-4 col-lg-4 col-form-label">Other Information:</strong>
                            <div className="col-md-10 col-lg-10">
                                <textarea  className='other_info' rows="8" cols="60" value={other_info} 
                                onChange={(e)=>setother_info(e.target.value)}>  
                                 
                                </textarea>  
                          </div>
                        </div>

                    </div>
                    </div>


                    </div>

                    
                    </Stepper>    
                
            </Modal.Body> 

    </Modal>


    <Modal size="lg" show={show} onHide={handleCloseModal}  backdrop="static" keyboard={false}>                                                                                                                                 
                    <Modal.Header closeButton>
                    <Modal.Title>Take picture</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        
                    <Camera onTakePhoto={(dataUri)=>{handleTakePhoto(dataUri)}} 
                    idealResolution = {{width: 680, height: 460}}  />


                    </Modal.Body>
                   
    </Modal>


    </div>

</div>
    </div>
  )
}

export default Membership
