import { MEMBER_REGISTRATION_REQUEST,MEMBER_REGISTRATION_SUCCESS ,MEMBER_REGISTRATION_FAIL 
    ,MEMBER_REQUEST ,MEMBER_SUCCESS,MEMBER_FAIL ,
    MEMBER_REQUEST_DETAILS ,MEMBER_SUCCESS_DETAILS ,MEMBER_FAIL_DETAILS ,
 } from "../constants/memberConstants";

import axios from "axios";


export const registerMember = (sex ,name ,r_add ,pob ,reg ,nland,tel ,email,dob,city,edu,mar,card_Id ,income_s ,b_long ,bus ,b_reg ,b_reg_no ,dat_r ,dat_in ,nat_b ,founder ,found_con ,bus_loc ,nk1 ,nkc1 ,nkr1 ,nkb1 ,nk2 ,nkc2 ,nkr2 ,nkb2 ,pic ,other_info,card_ty,card_is_dat,card_ex_date,save_by ,branch,level) => async(dispatch) =>{
    try {
       dispatch({type:MEMBER_REGISTRATION_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/registermember/' , 
       {'sex': sex , 'name':name, 'r_add':r_add ,'pob':pob , 'reg':reg ,'nland':nland ,'tel':tel ,'email':email ,
       'dob':dob,'city':city,'edu':edu ,'mar':mar,'card_Id':card_Id ,'income_s':income_s ,'b_long':b_long ,'bus':bus
       ,'b_reg':b_reg ,'b_reg_no':b_reg_no ,'dat_r':dat_r ,'dat_in':dat_in ,'nat_b':nat_b ,'founder':founder ,
       'found_con':found_con ,'bus_loc':bus_loc ,'nk1':nk1 ,'nkc1':nkc1 , 'nkr1':nkr1 , 'nkb1':nkb1
       ,'nk2':nk2 ,'nkc2':nkc2 , 'nkr2':nkr2 , 'nkb2':nkb2 ,'pic':pic ,'other_info':other_info,'card_ty':card_ty ,
       'card_is_dat':card_is_dat,'card_ex_date':card_ex_date ,'save_by':save_by,'branch':branch,'level':level} ,config)
 
       dispatch({type: MEMBER_REGISTRATION_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
    } catch (error) {
 
       dispatch({type:MEMBER_REGISTRATION_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }



 export const memberList= () =>async(dispatch) =>{

    try {

        dispatch({type:MEMBER_REQUEST})

        const {data} = await axios.get('/api/getMembers/')

        dispatch({type:MEMBER_SUCCESS , payload:data})

        localStorage.setItem('MemberInfo' ,JSON.stringify(data))

        
    } catch (error) {

        dispatch({
            type : MEMBER_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
        
    }

}


export const getMemberDetailsAction=(trans_code)=>async(dispatch)=>{
    try {

        dispatch({type:MEMBER_REQUEST_DETAILS})

    
        const {data} = await axios.get(`api/getmemberInfo/${trans_code}`)

        dispatch({type:MEMBER_SUCCESS_DETAILS , payload:data})

       // localStorage.setItem('bankInfo' , JSON.stringify(data))
        
    } catch (error) {
        dispatch({
            type : MEMBER_FAIL_DETAILS , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
    }

}


export const memberUpdateActions =(member) => async (dispatch)=>{

    try {
 
       dispatch({type:MEMBER_REGISTRATION_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/updateMemberInfo/${member.trans_code}/`,member ,config)
 
       
       dispatch({type:MEMBER_REGISTRATION_SUCCESS , payload:data})
 
      
    } catch (error) {
 
       dispatch({type:MEMBER_REGISTRATION_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


export const updateMemberInfoAccount =(member) => async (dispatch)=>{

    try {
 
       dispatch({type:MEMBER_REGISTRATION_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/updateMemberInfoAccount/${member.trans_code}/`,member ,config)
 
       
       dispatch({type:MEMBER_REGISTRATION_SUCCESS , payload:data})
 
      
       
    } catch (error) {
 
       dispatch({type:MEMBER_REGISTRATION_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }