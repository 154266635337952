export const APPROVAL_REQUEST='APPROVAL_REQUEST'
export const APPROVAL_SUCCESS='APPROVAL_SUCCESS'
export const APPROVAL_FAIL='APPROVAL_FAIL'
export const APPROVAL_REST='APPROVAL_REST'


export const APPROVAL_MEMBER_SAVE_REQUEST='APPROVAL_MEMBER_SAVE_REQUEST'
export const APPROVAL_MEMBER_SAVE_SUCCESS='APPROVAL_MEMBER_SAVE_SUCCESS'
export const APPROVAL_MEMBER_SAVE_FAIL='APPROVAL_MEMBER_SAVE_FAIL'
export const APPROVAL_MEMBER_SAVE_FAIL_REST='APPROVAL_MEMBER_SAVE_FAIL_REST'


export const APPROVAL_SAVE_REQUEST='APPROVAL_SAVE_REQUEST'
export const APPROVAL_SAVE_SUCCESS='APPROVAL_SAVE_SUCCESS'
export const APPROVAL_SAVE_FAIL='APPROVAL_SAVE_FAIL'
export const APPROVAL_SAVE_REST='APPROVAL_SAVE_REST'


export const APPROVAL_SENT_REQUEST='APPROVAL_SENT_REQUEST'
export const APPROVAL_SENT_SUCCESS='APPROVAL_SENT_SUCCESS'
export const APPROVAL_SENT_FAIL='APPROVAL_SENT_FAIL'
export const APPROVAL_SENT_REST='APPROVAL_SENT_REST'


export const APPROVAL_MESSAGE_SENT_REQUEST='APPROVAL_MESSAGE_SENT_REQUEST'
export const APPROVAL_MESSAGE_SENT_SUCCESS='APPROVAL_MESSAGE_SENT_SUCCESS'
export const APPROVAL_MESSAGE_SENT_FAIL='APPROVAL_MESSAGE_SENT_FAIL'
export const APPROVAL_MESSAGE_SENT_REST='APPROVAL_MESSAGE_SENT_REST'


export const APPROVAL_MESSAGE_SAVE_REQUEST='APPROVAL_MESSAGE_SAVE_REQUEST'
export const APPROVAL_MESSAGE_SAVE_SUCCESS='APPROVAL_MESSAGE_SAVE_SUCCESS'
export const APPROVAL_MESSAGE_SAVE_FAIL='APPROVAL_MESSAGE_SAVE_FAIL'
export const APPROVAL_MESSAGE_SAVE_REST='APPROVAL_MESSAGE_SAVE_REST'

export const USER_MESSAGE_REQUEST='USER_MESSAGE_REQUEST'
export const USER_MESSAGE_SUCCESS='USER_MESSAGE_SUCCESS'
export const USER_MESSAGE_FAIL='USER_MESSAGE_FAIL'
export const USER_MESSAGE_REST='USER_MESSAGE_REST'