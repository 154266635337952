
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './bootstrap-tap.css'
import { Provider } from 'react-redux';
import store from './store';
 
import {HashRouter} from 'react-router-dom'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
 
    <HashRouter >
      <App />
    </HashRouter>
   
   
  </Provider>
       
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

