import React ,{useState ,useEffect} from 'react'
import { IoLogoCodepen } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FcCopyright } from "react-icons/fc";
import { MdGpsFixed } from "react-icons/md";
import { FcCallback } from "react-icons/fc";
import { FcFeedback } from "react-icons/fc";
import { Modal } from 'react-bootstrap';
import { FcCheckmark } from "react-icons/fc";
import { registerBranch } from '../actions/settingAction';
import { useSelector ,useDispatch } from 'react-redux'
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2'
import { BRANCH_REGISTRATION_RESET  , BRANCH_DETAILS_RESET ,BRANCH_DETAILS_RESET_UPDATE} from '../constants/settingConstants';
import { listBranch ,getSelectedBranchDetails ,updateBranchAction} from '../actions/settingAction';

function BankBranch() {

const dispatch =useDispatch()

const [showCreateBranch ,setshowCreateBranch ]=useState(false)


const registerBranchReducer = useSelector(state => state. registerBranchReducer)
const {registerBranchInfo ,registerBranchLoading ,error ,success}= registerBranchReducer


const  branchDetailsReducer = useSelector(state => state. branchDetailsReducer)
const {branchsInfo ,loadingbranchs }= branchDetailsReducer


const  selectedBranchDetailsReducer = useSelector(state => state. selectedBranchDetailsReducer)
const {selectedBranch ,loadingselectedBranch ,Brancsuccess }= selectedBranchDetailsReducer


const  branchReducerUpdate = useSelector(state => state. branchReducerUpdate)
const {branchInfoUpdate ,branchInfoUpdateLoading ,updatebranch }= branchReducerUpdate


const [status ,setstatus] = useState(false)

const showCreateBranchOpen=()=>{
    setshowCreateBranch(true)
}

const setshowCreateBranchClose =()=>{
    setshowCreateBranch(false)

    setBranchCode('')
    setName('')
    setLocation('')
    setAddress('')
    setPhone('')
    setEmail('')
    setstatus(false)

    
}

const successMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: message ,
      showConfirmButton: false,
      timer: 1500
    });
  }


  const successError=(message) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: message ,
      showConfirmButton: false,
      timer: 1500000
      
    
    });



  }


const [branchCode ,setBranchCode]= useState()
const [name ,setName]= useState()
const [location ,setLocation]=useState()
const [address ,setAddress]=useState()
const [phone ,setPhone]=useState()
const [email ,setEmail]=useState()

useEffect(()=>{

        if(success){

            successMessage('Branch Created Successfully')
            
            setBranchCode('')
            setName('')
            setLocation('')
            setAddress('')
            setPhone('')
            setEmail('')

            dispatch({type:BRANCH_REGISTRATION_RESET})

        }

        if(error){

            successError('Error Registring Branch. Please Check Branch Code')
            dispatch({type:BRANCH_REGISTRATION_RESET})

        }

        if(updatebranch){

            successMessage('Branch Updated Successfully')

            dispatch({type:BRANCH_DETAILS_RESET_UPDATE})
            
            setshowCreateBranchClose()

        }
    


        dispatch(listBranch())

   

},[success ,error ,registerBranchInfo ,updatebranch])


useEffect(()=>{

    if(Brancsuccess){

     showCreateBranchOpen()

      setBranchCode(selectedBranch.branch_code)
      setName(selectedBranch.name)
      setLocation(selectedBranch.location)
      setAddress(selectedBranch.address)
      setPhone(selectedBranch.phone)
      setEmail(selectedBranch.email)

      setstatus(true)

        dispatch({type: BRANCH_DETAILS_RESET})

    }
   

},[ selectedBranch])


const saveBranch=(e)=>{

    e.preventDefault()

    if( !(branchCode.match('^[0-9]{2}$'))){
            
        Swal.fire({
            icon: 'error',
            title: 'Invalid Branch Code',
            text: 'Please provide valid Branch Code',
            footer: 'Error Please Fix This'
          })

    }else if(!(phone.match('^[0-9]{10}$'))) {

        Swal.fire({
            icon: 'error',
            title: 'Invalid phone number',
            text: 'Please provide valid phone number',
            footer: 'Error Please Fix This'
          })


    }else{

        if(status){

            //Update
            dispatch(updateBranchAction({branch_code:branchCode ,
                name,
                location,
                address,
                phone,
                email

            }))
        }else {

            //Save

            dispatch(registerBranch(branchCode ,name ,location ,address , phone ,email))

        }

       

    }

    

    

}

const getbranchdetails =(branch_code)=>{

  dispatch(getSelectedBranchDetails(branch_code))
  
}



return (

<div>


<h3>Branch Information</h3>

<hr/>

<button onClick={showCreateBranchOpen} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Branch </button>

<div className='solcon overflow-auto vh-100'>

{loadingbranchs && <Loader/>}

<div className='bra row me-2'>

{branchsInfo.map(branchs=>(

<div className='col-md-4 mb-4'>
    <div className="card">
        <div className="card-body">
        <h4 className="card-title"><FcCheckmark/> {branchs.name}</h4>
        <ul className="list-group">
            <li className="list-group-item"><i></i> <FcCopyright/> Branch Code : {branchs.branch_code}</li>
            <li className="list-group-item"><i ></i> <FaLocationDot/> Location: {branchs.location}</li>
            <li className="list-group-item"><i ></i> <MdGpsFixed/> Address: {branchs.address}</li>
            <li className="list-group-item"><i ></i> <FcCallback/> Phone Contact: {branchs.phone}</li>
            <li className="list-group-item"><i ></i> <FcFeedback/> Office Email: {branchs.email}</li>
            
        </ul>

        </div>
        <button  onClick={()=>getbranchdetails(branchs.branch_code)} className='show_info_btn'>View Branch Details</button>
    </div>
</div>


                
))}

</div>


  

   <Modal size='lg' show={showCreateBranch} onHide={setshowCreateBranchClose} backdrop="static" keyboard={false}>

    <Modal.Header  closeButton>
        <Modal.Title>Branch Information</Modal.Title>
    </Modal.Header>

    <Modal.Body>

        <form onSubmit={saveBranch}>

      
        {registerBranchLoading && <Loader/>}

        {loadingselectedBranch && <Loader/>}

        {branchInfoUpdateLoading && <Loader/>}
        

            <div className='form-group mb-4'>
                <label for=' Branch Code'>Branch Code:</label>
                {status ? 
                
                <input  type='number' name='Branch Code' required className='form-control'  value={branchCode} 
                onChange={(e)=>setBranchCode(e.target.value)}  disabled/> 
                :
                <input  type='number' name='Branch Code' required className='form-control'  value={branchCode} 
                onChange={(e)=>setBranchCode(e.target.value)} />
                
                }
               

            </div>

            <div className='form-group mb-4'>
                <label for=' Branch Code'>Branch Name:</label>
                <input type='text' name='Branch Name' required className='form-control' value={name} onChange={(e)=>setName(e.target.value)}/>

            </div>

            <div className='form-group mb-4'>
                <label for=' Branch Code'>Branch Location:</label>
                <input type='text' name='Branch Location' required className='form-control' value={location} onChange={(e)=>setLocation(e.target.value)} />

            </div>

            <div className='form-group mb-4'>
                <label for=' Branch Code'>Branch Address:</label>
                <input type='text' name='Branch Address' required className='form-control' value={address} onChange={(e)=>setAddress(e.target.value)} />

            </div>

            <div className='form-group mb-4'>
                <label for='Office Contect'>Office Phone Contact:</label>
                <input type='number' name='Office Contect' required className='form-control' value={phone} onChange={(e)=>setPhone(e.target.value)}/>

            </div>

            <div className='form-group mb-4'>
                <label for='Office Email'>Office Email:</label>
                <input type='email' name='Office Contect' required className='form-control' value={email} onChange={(e)=>setEmail(e.target.value)} />

            </div>

            { status ? 
             <button type='submit' className='show_info_btn'>Update Selected Branch Information</button>
              : 
              <button type='submit' className='show_info_btn'>Save </button>
               }

            

           
        </form>


    </Modal.Body>

   </Modal>
   
      
    </div>
    </div>
  )
}

export default BankBranch
