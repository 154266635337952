import { BANK_REQUEST_INFO ,BANK_SUCCESS_INFO ,BANK_FAIL_INFO
        ,BANK_REQUEST_INFO_UPDATE , BANK_SUCCESS_INFO_UPDATE  ,BANK_FAIL_INFO_UPDATE  ,
        BRANCH_REGISTRATION_REQUEST ,BRANCH_REGISTRATION_SUCCESS ,BRANCH_REGISTRATION_FAIL ,
        BRANCH_REQUEST ,BRANCH_SUCCESS ,BRANCH_FAIL  ,
        BRANCH_DETAILS_REQUEST ,BRANCH_DETAILS_SUCCESS ,BRANCH_DETAILS_FAIL  ,
        BRANCH_DETAILS_REQUEST_UPDATE ,BRANCH_DETAILS_SUCCESS_UPDATE ,BRANCH_DETAILS_FAIL_UPDATE ,

        PRODUCT_REGISTRATION_REQUEST , PRODUCT_REGISTRATION_SUCCESS,PRODUCT_REGISTRATION_FAIL,

        PRODUCT_REQUEST ,PRODUCT_SUCCESS ,PRODUCT_FAIL ,

        PRODUCT_REQUEST_UPDATE , PRODUCT_SUCCESS_UPDATE , PRODUCT_FAIL_UPDATE ,
        
        SERVICE_REGISTRATION_REQUEST ,SERVICE_REGISTRATION_SUCCESS ,SERVICE_REGISTRATION_FAIL ,

        SERVICE_REQUEST ,SERVICE_SUCCESS ,SERVICE_FAIL   ,

        SERVICE_REQUEST_UPDATE ,SERVICE_SUCCESS_UPDATE ,SERVICE_FAIL_UPDATE ,

        CHARTOFACCOUNT_SAVE_REQUEST,CHARTOFACCOUNT_SAVE_SUCCESS,CHARTOFACCOUNT_SAVE_FAIL,

        CHARTOFACCOUNT_REQUEST ,CHARTOFACCOUNT_SUCCESS ,CHARTOFACCOUNT_FAIL 
        
  } from "../constants/settingConstants";

import axios from "axios";

export const getBankAction=(trans_code)=>async(dispatch)=>{
    try {

        dispatch({type:BANK_REQUEST_INFO})

    
        const {data} = await axios.get(`api/getBankInfo/${trans_code}`)

        dispatch({type:BANK_SUCCESS_INFO , payload:data})

        localStorage.setItem('bankInfo' , JSON.stringify(data))
        
    } catch (error) {
        dispatch({
            type : BANK_FAIL_INFO , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
    }

}


export  const updateBankInfoAction=(bank)=>async(dispatch)=>{

    try {

        dispatch({type:BANK_REQUEST_INFO_UPDATE})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.put(`api/updateBankInfo/${bank.trans_code}/`,bank ,config)

        dispatch({type:BANK_SUCCESS_INFO_UPDATE , payload:data})
        
    } catch (error) {

        dispatch({
            type : BANK_FAIL_INFO_UPDATE , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export const registerBranch = (branch_code ,name ,location ,address ,phone , email) => async(dispatch) =>{
    try {
       dispatch({type:BRANCH_REGISTRATION_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/registerbranch/' , 
       {'branch_code': branch_code , 'name':name, 'location':location ,'address':address , 'phone':phone ,'email':email } ,config)
 
       dispatch({type: BRANCH_REGISTRATION_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
       
    } catch (error) {
 
       dispatch({type:BRANCH_REGISTRATION_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


export const listBranch= () =>async(dispatch) =>{

    try {

        dispatch({type:BRANCH_REQUEST})

        const {data} = await axios.get('/api/getbranch/')

        dispatch({type:BRANCH_SUCCESS , payload:data})

        localStorage.setItem('branchsInfo' ,JSON.stringify(data))

        
    } catch (error) {

        dispatch({
            type : BRANCH_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
        
    }

}


export  const getSelectedBranchDetails=(branch_code)=>async(dispatch)=>{

    try {

        dispatch({type:BRANCH_DETAILS_REQUEST})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.get(`api/getBranchDetails/${branch_code}` )

        dispatch({type:BRANCH_DETAILS_SUCCESS , payload:data})
        
    } catch (error) {

        dispatch({
            type : BRANCH_DETAILS_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export  const updateBranchAction=(branch)=>async(dispatch)=>{

    try {

        dispatch({type:BRANCH_DETAILS_REQUEST_UPDATE})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.put(`api/updateBranchInfo/${branch.branch_code}/`,branch ,config)

        dispatch({type:BRANCH_DETAILS_SUCCESS_UPDATE , payload:data})
        
    } catch (error) {

        dispatch({
            type : BRANCH_DETAILS_FAIL_UPDATE , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export const registerProduct = (product_code ,product_name ,Charges_Per_Month_ind  ,Charges_Per_Month_corpor ,idr_ind ,idr_corpor ,mob_ind  ,mob_corpor ,withdraw_count_ind  ,withdraw_count_corpor ,cot_ind  ,cot_corpor ,account ,acc_code ,acc_type,acc_statement) => async(dispatch) =>{
    try {
       dispatch({type:PRODUCT_REGISTRATION_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/registerProduct/' , 
       {'product_code': product_code , 'product_name':product_name, 'Charges_Per_Month_ind':Charges_Per_Month_ind , 'Charges_Per_Month_corpor':Charges_Per_Month_corpor ,
       'idr_ind':idr_ind ,'idr_corpor':idr_corpor ,'mob_ind':mob_ind ,'mob_corpor':mob_corpor ,'withdraw_count_ind':withdraw_count_ind ,'withdraw_count_corpor':withdraw_count_corpor ,
        'cot_ind':cot_ind ,'cot_corpor':cot_corpor ,'account':account,'acc_code':acc_code,'acc_type':acc_type,'acc_statement':acc_statement} ,config)
 
       dispatch({type: PRODUCT_REGISTRATION_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
       
    } catch (error) {
 
       dispatch({type:PRODUCT_REGISTRATION_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


export const productList= () =>async(dispatch) =>{

    try {

        dispatch({type:PRODUCT_REQUEST})

        const {data} = await axios.get('/api/getProduct/')

        dispatch({type:PRODUCT_SUCCESS , payload:data})

        localStorage.setItem('products' ,JSON.stringify(data))

        
    } catch (error) {

        dispatch({
            type : PRODUCT_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
        
    }

}



export  const updateProductAction=(product)=>async(dispatch)=>{

    try {

        dispatch({type:PRODUCT_REQUEST_UPDATE})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.put(`api/updateProduct/${product.product_code}/`,product ,config)

        dispatch({type:PRODUCT_SUCCESS_UPDATE , payload:data})
        
    } catch (error) {

        dispatch({
            type : PRODUCT_FAIL_UPDATE , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export const registerservice = (service_code ,service_name ,Charges_ind  ,Charges_corpor ,Service_type ,account ,acc_code ,acc_type ,acc_statement) => async(dispatch) =>{
    try {
       dispatch({type:SERVICE_REGISTRATION_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/registerService/' , 
       {'service_code': service_code , 'service_name':service_name, 'Charges_ind':Charges_ind ,
        'Charges_corpor':Charges_corpor ,'Service_type':Service_type ,'account':account  ,'acc_code':acc_code ,'acc_type':acc_type ,'acc_statement':acc_statement} ,config)
 
       dispatch({type: SERVICE_REGISTRATION_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
    } catch (error) {
 
       dispatch({type:SERVICE_REGISTRATION_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }



export const serviceList= () =>async(dispatch) =>{

    try {

        dispatch({type:SERVICE_REQUEST})

        const {data} = await axios.get('/api/getProductService/')

        dispatch({type:SERVICE_SUCCESS , payload:data})

        localStorage.setItem('services' ,JSON.stringify(data))

        
    } catch (error) {

        dispatch({
            type : SERVICE_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
        
    }

}


export  const updateSerivceAction=(service)=>async(dispatch)=>{

    try {

        dispatch({type:SERVICE_REQUEST_UPDATE})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.put(`api/updateservice/${service.service_code}/`,service ,config)

        dispatch({type:SERVICE_SUCCESS_UPDATE , payload:data})
        
    } catch (error) {

        dispatch({
            type : SERVICE_FAIL_UPDATE , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export const saveChartOfAccountAction = (acc_type ,acc_statement ,detail_type ,name ,description ,acc_code ) => async(dispatch) =>{
    try {
       dispatch({type:CHARTOFACCOUNT_SAVE_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/saveChartOfAccount/' , 
       {'acc_type': acc_type , 'acc_statement':acc_statement, 'detail_type':detail_type ,'name':name ,'description':description ,'acc_code':acc_code } ,config)
 
       dispatch({type: CHARTOFACCOUNT_SAVE_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
       
    } catch (error) {
 
       dispatch({type:CHARTOFACCOUNT_SAVE_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


  export  const updateChartOfAccountAction=(chartOfAccount)=>async(dispatch)=>{

    try {

        dispatch({type:CHARTOFACCOUNT_SAVE_REQUEST})

        const config = {header : {'content-type': 'application/json'} } 

        const {data} = await axios.put(`api/updateChartofAccount/${chartOfAccount.trans_code}/`,chartOfAccount ,config)

        dispatch({type:CHARTOFACCOUNT_SAVE_SUCCESS , payload:data})
        
    } catch (error) {

        dispatch({
            type : CHARTOFACCOUNT_SAVE_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
    }

} 


export const chartOfAccountList= () =>async(dispatch) =>{

    try {

        dispatch({type:CHARTOFACCOUNT_REQUEST})

        const {data} = await axios.get('/api/getAllChartOfAccount/')

        dispatch({type:CHARTOFACCOUNT_SUCCESS , payload:data})

        localStorage.setItem('chartOfAccount' ,JSON.stringify(data))

        
    } catch (error) {

        dispatch({
            type : CHARTOFACCOUNT_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
        
    }

}