import React,{useEffect,useState} from 'react'
import { AloadApprovalAction ,approveMemberActions,approveSaveActions } from '../actions/approvalAction'
import { useDispatch ,useSelector } from 'react-redux';
import DataTable from 'react-data-table-component'
import Loader from '../Components/Loader';
import { FcCheckmark } from "react-icons/fc";
import { FcHighPriority } from "react-icons/fc";
import { FcViewDetails } from "react-icons/fc";
import moment from 'moment'
import Swal from 'sweetalert2'
import { ApproveCreateCustomerAccounAction  } from '../actions/customerAccountAction';
import { approveAutoServicerActions } from '../actions/approvalAction';
import { vaultUpdateActions } from '../actions/vaultAction';

function Approvals() {

  let approval_status="False"

  const dispatch=useDispatch()


  const userProfileReducer = useSelector(state => state.userProfileReducer)
  const { userProfileInfo }=userProfileReducer


  const userLogin = useSelector(state => state.userLogin)
  const { userInfo }=userLogin

  const approvalReducer = useSelector(state => state.approvalReducer)
  const {ApprovalsLoading ,loadApprovals  }= approvalReducer


  const approveMemberSaveReducer = useSelector(state => state.approveMemberSaveReducer)
 

  const MembereReducer = useSelector(state => state.MembereReducer)
  const {MemberInfo }= MembereReducer

  const customerAccountUpdateReducer = useSelector(state => state.customerAccountUpdateReducer)
  


  const saveAutoService = useSelector(state => state.saveAutoService)
 

  const aprovelVaultReducer = useSelector(state => state.aprovelVaultReducer)
 



  const successMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: message ,
      showConfirmButton: false,
      timer: 10000
    });
  }


const ErrorMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: message ,
      showConfirmButton: false,
      timer: 1500000
      
    
    });

  }

  

  const approv_Selected_Row=(row)=>{

      //console.log('Membership Save Action')
    if(row.action==1){

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Membership Registration Approval",
        text: "Are You Sure You Want to Approve The Selected Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

         // const getuser = MemberInfo.filter(member => member.trans_code===row.table_pk)

          let save_aproval=true
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
          let edit_aproval=false
          let edit_by=''
          let level=userProfileInfo.Authorisation_Level
          //console.log(save_by)
    
          dispatch(approveMemberActions({trans_code:row.table_pk ,
            save_aproval ,
            edit_aproval,
            edit_by,
            level
        }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Membership Registration Successfully Approved')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });


    }



    //Membership Information Edit Approval
    if(row.action==2){

      //console.log('Membership Save Action')


      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Membership Information Edit Approval",
        text: "Are You Sure You Want to Approve The Selected Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

          const getuser = MemberInfo.filter(member => member.trans_code===row.table_pk)

          //console.log(getuser)
            
          let save_aproval=getuser[0].save_aproval
          let level=row.level
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
          let edit_aproval=true
          let edit_by=getuser[0].edit_by
          //console.log(save_by)
    
       
        dispatch(approveMemberActions({trans_code:row.table_pk  ,
          save_aproval ,
          edit_aproval,
          edit_by,
          level
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Membership Information Edit Successfully Approved ')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });


      

 

    }





    //Customer Account Creation Approval
    if(row.action==3){

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Customer Account Creation Approval",
        text: "Are You Sure You Want to Approve The Selected Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

         

          //console.log(getuser)
            
          let save_aproval=true
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
          let edit_aproval=false
          let edit_by=''
          let level=userProfileInfo.Authorisation_Level
          //console.log(save_by)
    
       
        dispatch(ApproveCreateCustomerAccounAction({trans_code:row.table_pk  ,
          save_aproval ,
          edit_aproval,
          edit_by,
          level
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Customer Account Creation Loogged For Authorization Successfully Approved ')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    }



    if(row.action==4){

      //Enable Digital Services Approval

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Digital Services Approval",
        text: "Are You Sure You Want to Approve The Selected Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

         

          //console.log(getuser)
            
          let save_aproval=true
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
          let edit_aproval=false
          let edit_by=''
          let level=userProfileInfo.Authorisation_Level
          //console.log(save_by)
    
       
        dispatch(approveAutoServicerActions({trans_code:row.table_pk  ,
          save_aproval ,
          edit_aproval,
          edit_by,
          level
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))


    
      successMessage('Digital Services Authorization Successfully Approved ')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    }



    if(row.action==5){

      // Cancel Digital Services Approval)


      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Digital Services Approval",
        text: "Are You Sure You Want to Approve The Selected Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

         

          //console.log(getuser)
            
          let save_aproval=false
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
          let edit_aproval=false
          let edit_by=''
          let level=userProfileInfo.Authorisation_Level
          //console.log(save_by)
    
       
        dispatch(approveAutoServicerActions({trans_code:row.table_pk  ,
          save_aproval ,
          edit_aproval,
          edit_by,
          level
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Digital Services Authorization Successfully Approved ')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    }


    
    if(row.action==6){

      // Vault Account Creations

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Vault Creation Approval",
        text: "Are You Sure You Want to Approve The Selected Created Vault Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

          //console.log(getuser)
            
          let save_aproval=true
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
          
    
       
        dispatch(vaultUpdateActions({code:row.table_pk  ,
          save_aproval 
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Vault Information Authorization Successfully Approved ')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    }


     
    if(row.action==7){

      // De-activate Vault Account

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Vault De-Activation Approval",
        text: "Are You Sure You Want to Approve The Selected Created Vault Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

          //console.log(getuser)
            
          let save_aproval=false
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
         
    
       
        dispatch(vaultUpdateActions({code:row.table_pk  ,
          save_aproval 
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Vault Information Authorization Successfully Approved ')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    }

    if(row.action==8){

      // Activate Vault Account 

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Vault Activation Approval",
        text: "Are You Sure You Want to Approve The Selected Created Vault Transcation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     

    }).then((result) => {

        if (result.isConfirmed) {

          //console.log(getuser)
            
          let save_aproval=true
          let approval_by=userInfo.username
          let approval_status=true
          let approval_date=moment().format('MMMM Do YYYY, h:mm:ss a')
         
    
       
        dispatch(vaultUpdateActions({code:row.table_pk  ,
          save_aproval 
      }))
    
        dispatch(approveSaveActions({trans_code:row.trans_code ,
          approval_by ,
          approval_status,
          approval_date
      }))
    
      successMessage('Vault Information Authorization Successfully Approved')
            

        } else if (result.dismiss === Swal.DismissReason.cancel) {

       
        }
        
    });

    }


  }


  const approvalcolumns =[
    {
     name:'Id' ,
     selector:row=>row.trans_code ,
     wrap:true ,
     sortable:true,
     width: "90px" 
     
     ,wrap:true 

    } ,

     {
      name:'Narration' ,
      selector:row=>row.narration,
      width: "350px" 
      ,wrap:true
     },

     {
      name:'Request By',
      selector:row=>row.sent_by ,
      width: "120px" 
      ,wrap:true ,
      sortable:true
     },

     {
        name:'Branch',
        selector:row=>row.branch ,
        width: "100px" 
        ,wrap:true ,
        sortable:true
       },

     {
        name:'Request Date',
        selector:row=>row.reg_date ,
        width: "200px" 
        ,wrap:true ,
        sortable:true
       },

     
       {
        name:'Access' , cell:row=> <div className="form-check form-switch">
          {userProfileInfo.Authorisation_Level  > row.level ?
           <input class="form-check-input" type="checkbox"  checked={true} /> 
           : <input class="form-check-input" type="checkbox"  checked={false} /> }
       
      </div>,
        width:"80px"
       }  ,
     

      {
        
        name:'Approve' , cell:row=> <div className="form-check form-switch">
          {userProfileInfo.Authorisation_Level  > row.level ?
          <button onClick={()=>approv_Selected_Row(row)} className='btt' > <FcCheckmark className='preview-btn'/> </button> 
           :<input class="form-check-input" type="checkbox"  checked={false} /> }
       
      </div>,
        width:"100px"
       }  ,


      {
        
        name:'Decline' , cell:row=> <div className="form-check form-switch">
          {userProfileInfo.Authorisation_Level  > row.level ?
          <button className='btt' > <FcHighPriority className='preview-btn'/> </button> 
           :<input class="form-check-input" type="checkbox"  checked={false} /> }
       
      </div>,
        width:"100px"
       }  ,


       {
        
        name:'View' , cell:row=> <div className="form-check form-switch">
          {userProfileInfo.Authorisation_Level  > row.level ?
          <button className='btt' > <FcViewDetails className='preview-btn'/> </button> 
           :<input class="form-check-input" type="checkbox"  checked={false} /> }
       
      </div>,
        width:"100px"
       }
      
  
  ]

  

useEffect(()=>{

  dispatch(AloadApprovalAction(approval_status))

},[approveMemberSaveReducer ,customerAccountUpdateReducer,saveAutoService ,aprovelVaultReducer])

  return (
    <div>

    <h3>Pending Approvals</h3>

    <hr/>

    <div className='container'>
    <div className='solcon overflow-auto vh-100'>

      
<div className='card table_buttom_margin '>
    
        
    {ApprovalsLoading && <Loader/>}   
     <DataTable className='rdt_Table' columns={approvalcolumns}  data={loadApprovals=='' ? loadApprovals : loadApprovals}   
     selectableRowsHighlight 
     highlightOnHover
      fixedHeader
       pagination 
        paginationPerPage={10}
        //onSelectedRowsChange={getSelectedSubjects}
        selectableRows  
         //selectableRowSelected={getPatientInfo} 
        //</div> onRowClicked={getPatientInfo}
        responsive={true}
        >

     </DataTable>

     
</div>

    </div>
    </div>
      
    </div>
  )
}

export default Approvals
