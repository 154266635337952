import React , {useState ,useEffect } from 'react'
import { login } from '../actions/userAction';
import { useDispatch,useSelector } from 'react-redux';
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import startccu from '../images/startccu.jpg'



function Login() {

    const [username ,setUsername] = useState();
    const [password ,setPassword] = useState();

    const dispatch =useDispatch();

    const userLogin = useSelector(state=>state.userLogin)
    const {error , loading  ,userInfo ,loginSucces } = userLogin


    const loginuser=(e)=>{
        e.preventDefault();

        dispatch(login(username ,password))
    }


  return (
    
    <div className='body'>

        <div className='container'>

            
            <div className='row content align-items-center h-100'>

           
                <div className='col-md-6 mx-auto'>
                     
                
                <img className='img-fluid' alt='Responsive image' src={startccu}/>
                
                    
                </div>

                <div className='col-md-6'>

                    <h1 className='signin-text'>Welcome !!</h1>

                    <form onSubmit={loginuser}>
                        {error && <Message variant={'danger'}>{error}</Message>}
                        {loading && <Loader/>}
                        <div className='form-group mt-4 pb-1'>
                            <label for='username'>Username:</label>
                            <input type='text' name='username' className='form-control' value={username} onChange={(e)=>setUsername(e.target.value)} />
                        </div>
                        
                        <div className='form-group mt-3'>
                            <label for='password'>Password</label>
                            <input type='password' name='password' className='form-control' value={password} onChange={(e)=>setPassword(e.target.value)} />
                        </div>
                        <button type='submit' className='btn-class mt-4'>Login</button>
                    </form>

                </div>

            </div>

        </div>
      
    </div>
  )
}

export default Login
