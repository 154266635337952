import React ,{useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap';
import { saveChartOfAccountAction ,updateChartOfAccountAction ,chartOfAccountList } from '../actions/settingAction';
import { useDispatch ,useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import Loader from '../Components/Loader';
import { FcApprove } from "react-icons/fc";
import { FcHighPriority } from "react-icons/fc";
import DataTable from 'react-data-table-component'

function ChartOfAccount() {

    const [show, setShow] = useState(false);

    const ChartOfAccountReducer = useSelector(state => state. ChartOfAccountReducer)
    const {loadingchartOfAccount ,chartOfAccountsu ,chartOfAccount}= ChartOfAccountReducer

    const handleCloseModal = () => setShow(false);


    const handleShowmModal = () =>{
      {loadingchartOfAccount && <Loader/>}
      setEdit(false)
      setShow(true)
      clear()
      dispatch(chartOfAccountList())

    } 

    const [acc_type ,setacc_type]=useState('')
    const [acc_statement ,setacc_statement]=useState('')
    const [detail_type ,setdetail_type]=useState('')
    const [name ,setname]=useState('')
    const [description ,setdescription]=useState('')
    const [Status ,setStatus]=useState(true)
    const [trans_code ,settrans_code]=useState('')

    const dispatch=useDispatch()

   

    const saveChartOfAccount = useSelector(state => state. saveChartOfAccount)
    const {chartOfAccountSave ,chartOfAccountSaveLoading}= saveChartOfAccount


    const[save ,setSave]=useState(false)
    const[edit ,setEdit]=useState(false)

    
    const successMessage=(message) => {
      Swal.fire({
        position: "center",
        icon: "success",
        title: message ,
        showConfirmButton: false,
        timer: 10000
      });
    }
  
  
  const ErrorMessage=(message) => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: message ,
        showConfirmButton: false,
        timer: 1500000
        
      
      });
  
    }

    const SSaveChartOfAccount =()=>{

      if(acc_type=='Select' || acc_statement=='Select' || detail_type=='Select' || name==''){

        ErrorMessage('Please All Required Fields Must Be Fill Out')

      }else {

        dispatch(chartOfAccountList())
        
        setSave(true)

        
      }

    } 

    useEffect(()=>{

      if(chartOfAccountSave){
       
        dispatch(chartOfAccountList())
      }
      

    },[saveChartOfAccount])


    useEffect(()=>{

    if(chartOfAccount && save){

      let acc_code=0

      if(acc_type=='Asset'){

        acc_code=1000

      }else if(acc_type=='Liability'){

        acc_code=2000

      }else if(acc_type=='Equity'){

        acc_code=3000

      }else if(acc_type=='Revenue'){

        acc_code=4000

      }else if(acc_type=='Expense'){

        acc_code=5000

      }
      const selectChartCount = chartOfAccount.filter(chart => chart.acc_type===acc_type).length
      
      acc_code=acc_code+selectChartCount+1

      console.log(acc_code)


      dispatch(saveChartOfAccountAction(acc_type ,acc_statement ,detail_type ,name ,description ,acc_code))
      setSave(false)

      successMessage('Account Successfully Created')
      setname('')
      setdescription('')
      }

    },[ChartOfAccountReducer])

    
  const clear=()=>{

    setname('')
    setacc_type('Select')
    setacc_statement('Select')
    setdetail_type('Select')
    setdescription('')
    settrans_code('')

  }


  const chartColoum =[
    {
     name:'Id' ,
     selector:row=>row.trans_code ,
     width: "80px" 
     
     ,wrap:true 

    } ,

     {
      name:'Account Code' ,
      selector:row=>row.acc_code,
      width: "120px" 
      ,wrap:true
     },

     {
      name:'Account Type',
      selector:row=>row.acc_type ,
      width: "130px" 
      ,wrap:true ,
      sortable:true
     },

     {
        name:'Statement',
        selector:row=>row.acc_statement ,
        width: "200px" 
        ,wrap:true ,
        sortable:true
       },

     {
        name:'Details Type',
        selector:row=>row.detail_type ,
        width: "180px" 
        ,wrap:true ,
        sortable:true
       },

       
       {
        name:'Account',
        selector:row=>row.name,
        width: "180px" 
        ,wrap:true ,
        sortable:true
       },
     
       {
        name:'Description',
        selector:row=>row.description ,
        width: "180px" 
        ,wrap:true ,
        sortable:true
       },
      

       {
       name:'Edit' , cell:row=> <button onClick={()=>getSelectedRow(row)}  className='btt'> <FcApprove className='preview-btn'/> </button>,
       width:"70px"
      }  ,

      {
        
       name:'Status' , cell:row=> <div className="form-check form-switch">
       <input class="form-check-input" type="checkbox"  checked={row.Status} />
      
     </div>,
       width:"70px"
      }  ,
    

  ]


  const getSelectedRow=(row)=>{

    const selectedChartInfo=chartOfAccount.find(obj=>obj.trans_code==row.trans_code)

    handleShowmModal()

    setname(selectedChartInfo.name)
    setacc_type(selectedChartInfo.acc_type)
    setacc_statement(selectedChartInfo.acc_statement)
    setdetail_type(selectedChartInfo.detail_type)
    setdescription(selectedChartInfo.description)
    setStatus(selectedChartInfo.Status)
    settrans_code(selectedChartInfo.trans_code)

    setEdit(true)

  }

  const handleChange=(e)=>{
       
    setStatus(!Status)
     
    //console.log(is_active)
 }


 const updateChartOfAccount=()=>{

  
  if(acc_type=='Select' || acc_statement=='Select' || detail_type=='Select' || name==''){

    ErrorMessage('Please All Required Fields Must Be Fill Out')

  }else {

    dispatch(updateChartOfAccountAction({trans_code:trans_code,
      acc_type ,
      acc_statement,
      detail_type,
      name,
      description,
      Status,
    }))

    successMessage('Account Successfully Updated')

  }

 

 }

  
  return (
    <div className='container p-0'>
        <h3>Chart Of Account</h3>
<hr/>

<button onClick={handleShowmModal} className='btn-class py-2 px-4 mt-2 mb-3'>Add New COA Account</button>

<div className='top-layout'>
        <div className='layout-search'>
        <div className='form-group '>
                    
        <input type='text' name='product Name' required className='form-control search__input mt-3 mb-2 ms-4' placeholder='Search'  />
        </div>
        </div>

        <div className='layout-report'>

        <button  className='show_info_btn py-2 px-4 mt-2 mb-3 me-5'>Report</button>

        </div>
    </div>
    
   

<div className='solcon overflow-auto vh-100 mb-5'>
  
<div className='card table_buttom_margin'>


    {loadingchartOfAccount && <Loader/>}

     <DataTable className='rdt_Table p-3 ' columns={chartColoum}  data={chartOfAccount=='' ? chartOfAccount : chartOfAccount}   
     selectableRowsHighlight 
     highlightOnHover
      fixedHeader
       pagination 
        paginationPerPage={10}
        //onSelectedRowsChange={getSelectedSubjects}
        selectableRows  
         //selectableRowSelected={getPatientInfo} 
        //</div> onRowClicked={getPatientInfo}
        responsive={true}
        >

     </DataTable>

</div>
      
    </div>


    
    {chartOfAccountSaveLoading && <Loader/>}

    <Modal size="lg" show={show} onHide={handleCloseModal}  backdrop="static" keyboard={false}>                                                                                                                                 
                    <Modal.Header closeButton>
                    <Modal.Title>Accounts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <div className='row'>
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Account Type</label>
                          <select required  className='form-control' value={acc_type} onChange={(e)=>setacc_type(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Asset">Asset</option>
                                  <option value="Liability">Liability</option>
                                  <option value="Equity">Equity</option>
                                  <option value="Revenue">Revenue</option>
                                  <option value="Expense">Expense</option>
                              </select>
                        </div>

                        <div className="col-md-6 col-lg-6 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Name<samp className='text-primary'></samp> </label>
                          <input name="Name" required type="text" className="form-control"  value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>
                        </div>

                        <div className='row'>
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Account Statement</label>
                          <select required  className='form-control'  value={acc_statement} onChange={(e)=>setacc_statement(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Balance Sheet">Balance Sheet</option>
                                  <option value="Income Statement">Income Statement</option>
                              </select>
                        </div>

                        <div className="col-md-6 col-lg-6 mb-3">
                          <label className='lable' for='Surname'>Description</label>
                          <input name="Name" required type="text" className="form-control" value={description}
                           onChange={(e)=>setdescription(e.target.value)} />
                       </div>

                        </div>

                        <div className='row'>
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Detail Type</label>
                          <select required  className='form-control' value={detail_type} onChange={(e)=>setdetail_type(e.target.value)}>
                                  <option selected value="Select" >Select</option>
                                  <option value="Liquid Funds">Liquid Funds</option>
                                  <option value="Liquid Investment">Liquid Investment</option>
                                  <option value="Other Investment">Other Investment</option>
                                  <option value="Startups Loans">Startups Loans</option>
                                  <option value="Accounts Receivables">Accounts Receivables</option>
                                  <option value="Customer Deposits">Customer Deposits</option>
                                  <option value="Other Fee">Other Fee</option>
                                  <option value="Non-Financial Income">Non-Financial Income</option>
                                  <option value="Interst Income">Interst Income</option>
                                  <option value="Cost Of Funds / Interest Expense">Cost Of Funds / Interest Expense</option>
                                  <option value="Provision For Loan Loss">Provision For Loan Loss</option>
                                  <option value="Personnel Cost">Personnel Cost</option>
                                  <option value="Occupancy Cost">Occupancy Cost</option>
                                  <option value="Organisational Cost">Organisational Cost</option>
                                  <option value="Security Cost">Security Cost</option>
                                  <option value="Administrative Cost">Administrative Cost</option>
                                  <option value="Other Expense">Other Expense</option>
                              </select>
                        </div>

                        <div className='col-md-6 col-lg-6'>

                        <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Activate /Inactive</label>
                        <div class="form-check form-switch">
                         
                  <input class="form-check-input" type="checkbox"  checked={Status} onChange={handleChange}
                   />
                  
                </div>
                         
                          </div>
                        

                        </div>

                        <div className='row'>
                        <div className='col-md-6 col-lg-6'>
                         
                          </div>

                          <div className='col-md-6 col-lg-6'>
                            {!edit ? <button onClick={SSaveChartOfAccount} className="show_info_btn">Save Account</button> 
                            :
                            <button onClick={updateChartOfAccount} className="show_info_btn">Update Account</button>}
                         
                          </div>
                        </div>

                        <hr/>

                        
                    </Modal.Body>
                   
    </Modal>



    </div>
  )
}

export default ChartOfAccount
