import React  ,{useState ,useEffect}from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import Stepper from "awesome-react-stepper";
import ImageUploading from "react-images-uploading";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Loader from '../Components/Loader';
import moment from 'moment'
import { successMessage ,ErrorMessage  } from './Message';
import { createCustomercompanyDetail ,createCustomercompanysignatory } from '../actions/customerAccountAction';
import { CREATE_CORACCOUNT_RESET ,CREATE_SIGNATORY_RESET ,CREATE_ACCOUNT_RESET } from '../constants/customerAccountConstants';
import { loadCustomerAccountAction  ,createCustomerBankAccounAction   ,CustomerAccountTypeAction} from '../actions/customerAccountAction';
import { Modal } from 'react-bootstrap';
import Message from '../Components/Message';
import sigd from '../images/sigd.jpg'
import profile from '../images/profile-img.jpg'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { MEMBER_RESET_DETAILS } from '../constants/memberConstants';
import DataTable from 'react-data-table-component'
import { BiSolidReport } from "react-icons/bi";
import { MdOutlineEdit } from "react-icons/md";
import { resizeFile } from './Message';
import useSound from 'use-sound';
import messagersent from '../images/messagersent.wav'
import { aprMessageAction ,messageListAction } from '../actions/approvalAction';

function CoporateAcc() {

  const [playOn] = useSound(
    messagersent ,
    { volume: 0.25 }
  );

  const dispatch=useDispatch()

  const [getpic ,setgetpic] =useState('')

  var zeroFill = require('zero-fill')

  const [images1, setImages1] = useState([]);

  const [images2, setImages2] = useState([]);

  const [images3, setImages3] = useState([]);

  const [approval_byb, setapproval_byb]=useState('')


  const [picImages1, setpicImages1] = useState([]);

  const [picImages2, setpicImages2] = useState([]);

  const [picImages3, setpicImages3] = useState([]);

  const maxNumber = 69;

  const [prouser, setprouser] = useState([])

  const[assig1, setassig1]= useState('')
  const[assig2 , setassig2]= useState('')
  const[assig3 , setassig3]= useState('')

  const[picone , setpicone]= useState('')
  const[pic2 , setpic2]= useState('')
  const[pic3 , setpic3]= useState('')

 
  
 const [product_name ,setGetProduct]=useState('')
 const [initial,setInitial]=useState('')



  const onChange = async (imageList, addUpdateIndex) => {
    // data for submit
    setImages1(imageList);

    if(imageList.length < 1){

      //setImage(bankInfo.image)

    }else {

      const image = await resizeFile(imageList[0].file);
      setassig1(image)
      
    }
   
  };


  const onChange2 = async (imageList, addUpdateIndex) => {
    // data for submit
    setImages2(imageList);

    if(imageList.length < 1){

      //setImage(bankInfo.image)

    }else {

      const image = await resizeFile(imageList[0].file);
      setassig2(image)
      //console.log(imageList[0].data_url);

    }
   
  };


  const onChange3 = async (imageList, addUpdateIndex) => {
    // data for submit
    setImages3(imageList);

    if(imageList.length < 1){

      //setImage(bankInfo.image)

    }else {

      const image = await resizeFile(imageList[0].file);
      setassig3(image)
      //console.log(imageList[0].data_url);

    }
   
  };


  const picOnChange1 = async (imageList, addUpdateIndex) => {
    // data for submit
    setpicImages1(imageList);

    if(imageList.length < 1){

      setpicone('')

    }else {

      const image = await resizeFile(imageList[0].file);
      setpicone(image)
      //console.log(imageList[0].data_url);

    }
   
  };


  const picOnChange2 = async (imageList, addUpdateIndex) => {
    // data for submit
    setpicImages2(imageList);

    if(imageList.length < 1){

      setpic2('')

    }else {

      const image = await resizeFile(imageList[0].file);
      setpic2(image)
      //console.log(imageList[0].data_url);

    }
   
  };

  const picOnChange3 =  async (imageList, addUpdateIndex) => {
    // data for submit
    setpicImages3(imageList);

    if(imageList.length < 1){

      setpic3('');

    }else {

      const image = await resizeFile(imageList[0].file);
      setpic3(image)
      //console.log(imageList[0].data_url);

    }
   
  };

  const[code,setCode]=useState('')
  const [memberid ,setmemberid]=useState('')
  const [pic ,setpic]=useState('')
  const [sex ,SetSex]=useState('')
  const [fullname ,setfullname]=useState('')
  const [r_add ,setr_add]=useState('')
  const [pob ,setpob]=useState('')
  const[reg ,setreg]=useState('')
  const [nland ,setnland]=useState('')
  const[tel ,settel]=useState('')
  const[email ,setemail]=useState('')
  const[dob ,setdob]=useState('')
  const [city ,setcity]=useState('')
  const [edu ,setedu]=useState('')
  const[mar ,setmar]=useState('')
  const[card_Id ,setcard_Id]=useState('')
  const[card_ty,setcard_ty]=useState('')
  const[card_is_dat ,setcard_is_dat]=useState('')
  const[card_ex_date,setcard_ex_date]=useState('')


  const [member_id  ,setmember_id]=useState('')
  const [company ,setcompany]=useState('')
  const [cer_reg ,setcer_reg]=useState('')
  const [dat_reg ,setdat_reg]=useState('')
  const [jur_reg ,setjur_reg]=useState('')
  const [cat_bus ,setcat_bus]=useState('')
  const [nat_bus ,setnat_bus]=useState('')
  const [sec ,setsec]=useState('')
  const [bus_add,setbus_add]=useState('')
  const[cor_bus_add,setcor_bus_add]=useState('')
  const [busemail,setbusemail]=useState('')
  const[web,setweb]=useState('')
  const [phone,setphone]=useState('')
  const [tin,settin]=useState('')
  const [bus_no,setbus_no]=useState('')
  const [other_refe ,setother_refe]=useState('')
  const[anu_turn ,setanu_turn]=useState('')


  const [asname1  ,setasname1]=useState('')
  const [asdob1 ,setasdob1]=useState('')
  const [asgen1 ,setasgen1]=useState('')
  const [asmei1 ,setasmei1]=useState('')
  const [asnat1 ,setasnat1]=useState('')
  const [aspn1 ,setaspn1]=useState('')
  const [asid1 ,setasid1]=useState('')
  const [asiddat1 ,setasiddat1]=useState('')
  const [asidno1,setasidno1]=useState('')
  const[asidex1,setasidex1]=useState('')
  const [asocc1,setasocc1]=useState('')
  const[asjt1,setasjt1]=useState('')
  const [aspos1,setaspos1]=useState('')
  const [asresadd1,setasresadd1]=useState('')
  const [asnea1,setasnea1]=useState('')
  const [ascity1 ,setascity1]=useState('')
  const[asemail1 ,setasemail1]=useState('')


  const [asname2  ,setasname2]=useState('')
  const [asdob2 ,setasdob2]=useState('')
  const [asgen2 ,setasgen2]=useState('')
  const [asmei2 ,setasmei2]=useState('')
  const [asnat2 ,setasnat2]=useState('')
  const [aspn2 ,setaspn2]=useState('')
  const [asid2 ,setasid2]=useState('')
  const [asiddat2 ,setasiddat2]=useState('')
  const [asidno2,setasidno2]=useState('')
  const[asidex2,setasidex2]=useState('')
  const [asocc2,setasocc2]=useState('')
  const[asjt2,setasjt2]=useState('')
  const [aspos2,setaspos2]=useState('')
  const [asresadd2,setasresadd2]=useState('')
  const [asnea2,setasnea2]=useState('')
  const [ascity2 ,setascity2]=useState('')
  const[asemail2 ,setasemail2]=useState('')


  const [asname3  ,setasname3]=useState('')
  const [asdob3 ,setasdob3]=useState('')
  const [asgen3 ,setasgen3]=useState('')
  const [asmei3 ,setasmei3]=useState('')
  const [asnat3 ,setasnat3]=useState('')
  const [aspn3 ,setaspn3]=useState('')
  const [asid3 ,setasid3]=useState('')
  const [asiddat3 ,setasiddat3]=useState('')
  const [asidno3,setasidno3]=useState('')
  const[asidex3,setasidex3]=useState('')
  const [asocc3,setasocc3]=useState('')
  const[asjt3,setasjt3]=useState('')
  const [aspos3,setaspos3]=useState('')
  const [asresadd3,setasresadd3]=useState('')
  const [asnea3,setasnea3]=useState('')
  const [ascity3 ,setascity3]=useState('')
  const[asemail3 ,setasemail3]=useState('')
  const [branch_code ,setbranch_code]=useState('')

  const Accountcolumns =[
    {
     name:'Id' ,
     selector:row=>row.trans_code ,
     width: "100px" 
     
     ,wrap:true 

    } ,

     {
      name:'Product Name' ,
      selector:row=>row.product_name,
      width: "150px" 
      ,wrap:true
     },


     {
        name:'Account Name' ,
        selector:row=>row.account_name,
        width: "px" 
        ,wrap:true
       },

     {
      name:'Account Number',
      selector:row=>row.account_number ,
      width: "200px" 
      ,wrap:true ,
      sortable:true
     },

     {
        name:'Branch',
        selector:row=>row.branch ,
        width: "150px" 
        ,wrap:true ,
        sortable:true
       },

     {
        name:'Date Created',
        selector:row=>row.dat ,
        width: "220px" 
        ,wrap:true ,
        sortable:true
       },

        {
        
       name:'Status' , cell:row=> <div className="form-check form-switch">
       <input class="form-check-input" type="checkbox"  checked={row.save_aproval} />

     </div>,
       width:"70px"
      }  ,


      {
        name:'Report' , cell:row=> <button className='btt'> <BiSolidReport className='preview-btn'/> </button>,
       width:"70px"
      }  ,

     
    

  ]

  const selete_Account_details=(row)=>{
    

  }


    const MembereDetailsReducer = useSelector(state => state.MembereDetailsReducer)
    const {MemberDetailsInfo ,MemberDetailssuccess ,memberDetailsLoading}= MembereDetailsReducer

  
    const createCorAccountReducer = useSelector(state => state.createCorAccountReducer)
    const {coraccsuff ,coraccLoading }= createCorAccountReducer

    const createsignatoryReducer = useSelector(state => state.createsignatoryReducer)
    const {Signatory ,SignatoryLoading }= createsignatoryReducer

    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo }=userProfileReducer

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

    const CorporateAccountDetailsReducer = useSelector(state => state.CorporateAccountDetailsReducer)
    const {CorporateAccountDetailsLoading ,CorporateAccountDetails }= CorporateAccountDetailsReducer

    const CorporateAccountsignatoryDetailsReducer = useSelector(state => state. CorporateAccountsignatoryDetailsReducer)
    const {CorporateAccountsignatoryDetailsLoading ,CorporateAccountsignatoryDetails }= CorporateAccountsignatoryDetailsReducer

    const productReducer = useSelector(state => state.productReducer)
    const { products ,loadingproduct }=productReducer

    const [branch ,setBranch]=useState(userProfileInfo.branch)
    const [product_code,setProductCode]=useState('')

    const [bank_code ,setbank_code]=useState('')
    const [customer_number ,setcustomer_number]=useState('')

    const[account_number ,setaccount_number]=useState('')

    const CustomerAccountTypeDetailsReducer = useSelector(state => state. CustomerAccountTypeDetailsReducer)
    const {AccountTypeDetails}= CustomerAccountTypeDetailsReducer 

    const  branchDetailsReducer = useSelector(state => state. branchDetailsReducer)
    const {branchsInfo}= branchDetailsReducer

    const  bankInfoReducer = useSelector(state => state. bankInfoReducer)
    const {bankInfo}= bankInfoReducer

    const  createBankAccountReducer = useSelector(state => state. createBankAccountReducer)
    const {customerAccountUpdateLoading ,creataccout ,coraccsufferror ,error ,accountInfo}= createBankAccountReducer

    
    const CustomerAccountReducer = useSelector(state => state. CustomerAccountReducer)
    const {customerAccounts ,customerAccountsLoading}= CustomerAccountReducer

    
    const ALLUserReducer = useSelector(state => state. ALLUserReducer)
    const {AllUser ,UserLoading ,UserSuccess}= ALLUserReducer


    const [getIndAcc ,setgetIndAcc]=useState()

    

    const [show, setShow] = useState(false);

        
    function handleCameraStop () {
      console.log('handleCameraStop');
    }

    const handleCloseModal = () =>{

      

      setShow(false);
      

    } 

  
  
    const handleShowmModal1 = () => {
      setgetpic(1)
      setShow(true)

    }

    const handleShowmModal2 = () => {
      setgetpic(2)
      setShow(true)

    }

    const handleShowmModal3 = () => {
      setgetpic(3)
      setShow(true)

    }
    


    
const handleTakePhoto=(dataUri)=>{
  
  if(getpic==1){

   

    setpicone(dataUri)
   // console.log(ppiicc )
    

  }else if(getpic==2) {
   

    setpic2(dataUri)
    //console.log(getpic +  pic2)

  }else if(getpic==3){

    setpic3(dataUri)
    //console.log(pic3)

  }
  
}
   
  useEffect(()=>{

    dispatch(loadCustomerAccountAction())
 
    if(MemberDetailssuccess){

      setmemberid(MemberDetailsInfo.trans_code)
        setCode(zeroFill(7,MemberDetailsInfo.trans_code))
        setfullname(MemberDetailsInfo.name)
        setpic(MemberDetailsInfo.pic)
        SetSex(MemberDetailsInfo.sex)
        setr_add(MemberDetailsInfo.r_add)
        setpob(MemberDetailsInfo.pob)
        setreg(MemberDetailsInfo.reg)
        setnland(MemberDetailsInfo.nland)
        settel(MemberDetailsInfo.tel)
        setemail(MemberDetailsInfo.email)
        setdob(MemberDetailsInfo.dob)
        setcity(MemberDetailsInfo.city)
        setedu(MemberDetailsInfo.edu)
        setmar(MemberDetailsInfo.mar)
        setcard_Id(MemberDetailsInfo.card_Id)
        setcard_ty(MemberDetailsInfo.card_ty)
        setcard_is_dat(MemberDetailsInfo.card_is_dat)
        setcard_ex_date(MemberDetailsInfo.card_ex_date)
        setmember_id(MemberDetailsInfo.trans_code)
    }

    

  },[MemberDetailsInfo ])


  useEffect(()=>{
    if(CorporateAccountsignatoryDetails.member_id==MemberDetailsInfo.trans_code){

      setasname1(CorporateAccountsignatoryDetails.asname1)
      setasdob1(CorporateAccountsignatoryDetails.asdob1)
      setasgen1(CorporateAccountsignatoryDetails.asgen1)
      setasmei1(CorporateAccountsignatoryDetails.asmei1)
      setasnat1(CorporateAccountsignatoryDetails.asnat1)
      setaspn1(CorporateAccountsignatoryDetails.aspn1)
      setasid1(CorporateAccountsignatoryDetails.asid1)
      setasidno1(CorporateAccountsignatoryDetails.asidno1)
      setasiddat1(CorporateAccountsignatoryDetails.asiddat1)
      setasidex1(CorporateAccountsignatoryDetails.asidex1)
      setasocc1(CorporateAccountsignatoryDetails.asocc1)
      setasjt1(CorporateAccountsignatoryDetails.asjt1)
      setaspos1(CorporateAccountsignatoryDetails.aspos1)
      setasresadd1(CorporateAccountsignatoryDetails.asresadd1)
      setasnea1(CorporateAccountsignatoryDetails.asnea1)
      setascity1(CorporateAccountsignatoryDetails.ascity1)
      setasemail1(CorporateAccountsignatoryDetails.asemail1)
      setassig1(CorporateAccountsignatoryDetails.assig1)

      setasname2(CorporateAccountsignatoryDetails.asname2)
      setasdob2(CorporateAccountsignatoryDetails.asdob2)
      setasgen2(CorporateAccountsignatoryDetails.asgen2)
      setasmei2(CorporateAccountsignatoryDetails.asmei2)
      setasnat2(CorporateAccountsignatoryDetails.asnat2)
      setaspn2(CorporateAccountsignatoryDetails.aspn2)
      setasid2(CorporateAccountsignatoryDetails.asid2)
      setasidno2(CorporateAccountsignatoryDetails.asidno2)
      setasiddat2(CorporateAccountsignatoryDetails.asiddat2)
      setasidex2(CorporateAccountsignatoryDetails.asidex2)
      setasocc2(CorporateAccountsignatoryDetails.asocc2)
      setasjt2(CorporateAccountsignatoryDetails.asjt2)
      setaspos2(CorporateAccountsignatoryDetails.aspos2)
      setasresadd2(CorporateAccountsignatoryDetails.asresadd2)
      setasnea2(CorporateAccountsignatoryDetails.asnea2)
      setascity2(CorporateAccountsignatoryDetails.ascity2)
      setasemail2(CorporateAccountsignatoryDetails.asemail2)
      setassig2(CorporateAccountsignatoryDetails.assig2)

      setasname3(CorporateAccountsignatoryDetails.asname3)
      setasdob3(CorporateAccountsignatoryDetails.asdob3)
      setasgen3(CorporateAccountsignatoryDetails.asgen3)
      setasmei3(CorporateAccountsignatoryDetails.asmei3)
      setasnat3(CorporateAccountsignatoryDetails.asnat3)
      setaspn3(CorporateAccountsignatoryDetails.aspn3)
      setasid3(CorporateAccountsignatoryDetails.asid3)
      setasidno3(CorporateAccountsignatoryDetails.asidno3)
      setasiddat3(CorporateAccountsignatoryDetails.asiddat3)
      setasidex3(CorporateAccountsignatoryDetails.asidex3)
      setasocc3(CorporateAccountsignatoryDetails.asocc3)
      setasjt3(CorporateAccountsignatoryDetails.asjt3)
      setaspos3(CorporateAccountsignatoryDetails.aspos3)
      setasresadd3(CorporateAccountsignatoryDetails.asresadd3)
      setasnea3(CorporateAccountsignatoryDetails.asnea3)
      setascity3(CorporateAccountsignatoryDetails.ascity3)
      setasemail3(CorporateAccountsignatoryDetails.asemail3)
      setassig3(CorporateAccountsignatoryDetails.asemail3)
     

    }else {

      setasname1(MemberDetailsInfo.name)
      setasdob1(MemberDetailsInfo.dob)
      setasgen1(MemberDetailsInfo.sex)
      setaspn1(MemberDetailsInfo.tel)
      setasid1(MemberDetailsInfo.card_ty)
      setasiddat1(MemberDetailsInfo.card_is_dat)
      setasidno1(MemberDetailsInfo.card_Id)
      setasidex1(MemberDetailsInfo.card_ex_date)
      setasresadd1(MemberDetailsInfo.r_add)
      setasnea1(MemberDetailsInfo.nland)
      setascity1(MemberDetailsInfo.city)
      setasemail1(MemberDetailsInfo.email)

     
      setasmei1('')
      setasnat1('')
      setasocc1('')
      setascity1('')
      setasjt1('')

      setasname2('')
      setasdob2('')
      setasgen2('')
      setasmei2('')
      setasnat2('')
      setaspn2('')
      setasid2('')
      setasidno2('')
      setasiddat2('')
      setasidex2('')
      setasocc2('')
      setasjt2('')
      setaspos2('')
      setasnea2('')
      setascity2('')
      setasemail2('')

      setasname3('')
      setasdob3('')
      setasgen3('')
      setasmei3('')
      setasnat3('')
      setaspn3('')
      setasid3('')
      setasidno3('')
      setasiddat3('')
      setasidex3('')
      setasocc3('')
      setasjt3('')
      setaspos3('')
      setasresadd3('')
      setasnea3('')
      setascity3('')
      setasemail3('')

      setassig1('')
  setassig2('')
  setassig3('')


      
    }
  },[CorporateAccountsignatoryDetailsReducer])


  useEffect(()=>{

    if(CorporateAccountDetails.member_id==MemberDetailsInfo.trans_code){

      setcompany(CorporateAccountDetails.company)
      setcer_reg(CorporateAccountDetails.cer_reg)
      setdat_reg(CorporateAccountDetails.dat_reg)
      setjur_reg(CorporateAccountDetails.jur_reg)
      setcat_bus(CorporateAccountDetails.cat_bus)
      setnat_bus(CorporateAccountDetails.nat_bus)
      setbus_add(CorporateAccountDetails.bus_add)
      setsec(CorporateAccountDetails.sec)
      setcor_bus_add(CorporateAccountDetails.cor_bus_add)
      setweb(CorporateAccountDetails.web)
      settin(CorporateAccountDetails.tin)
      setbus_no(CorporateAccountDetails.bus_no)
      setother_refe(CorporateAccountDetails.other_refe)
      setanu_turn(CorporateAccountDetails.anu_turn)
      setphone(CorporateAccountDetails.phone)
      setbusemail(CorporateAccountDetails.busemail)

  }else {

    setcompany('')
      setcer_reg('')
      setdat_reg('')
      setjur_reg('')
      setcat_bus('')
      setnat_bus('')
      setbus_add('')
      setcor_bus_add('')
      setweb('')
      settin('')
      setbus_no('')
      setother_refe('')
      setanu_turn('')
      setphone('')
      setbusemail('')
      setsec('')

      
  }


    },[CorporateAccountDetailsReducer,MemberDetailsInfo])


    useEffect(()=>{

      try {
        
        setgetIndAcc(customerAccounts.filter(obj=>obj.acc_type=='Corporate Account'))
      } catch (error) {
        
      }

      
  
    },[CustomerAccountReducer])
   


  useEffect(()=>{

    if(coraccsuff && Signatory || creataccout){

      successMessage('Customer Account Loogged For Authorization')


      dispatch(loadCustomerAccountAction())


    //Send Account Apropval
    let table = 'Account Table'
    let level = 10
    let action = 1
    let sent_by = userInfo.username
    let table_pk = accountInfo.trans_code;
    let branch = userProfileInfo.branch
    let pic = userProfileInfo.image
    let dat = moment().format('YYYY-MM-DD')
    let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
    let title = 'Account Edit Request'
    let message_sum = 'Request For Account Opening Authorization'
    let ty = accountInfo.acc_type
    let name = accountInfo.account_name
    let accnumber = accountInfo.account_number
    let RequestMessage = "Requesting For Your Authorization or Approval Of This Account"


    dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_byb, ty, name, accnumber, '-', '-', '-'))

     
      dispatch({type:CREATE_SIGNATORY_RESET})
      dispatch({type:CREATE_CORACCOUNT_RESET})
      dispatch({type:CREATE_ACCOUNT_RESET})
      dispatch({type:MEMBER_RESET_DETAILS})

      
      

      //dispatch(getMemberCorAaccountDetailsAction(MemberDetailsInfo.trans_code))
      //dispatch(getMemberCorAaccountSignatoryDetailsAction(MemberDetailsInfo.trans_code))

      playOn();

      clear();
      

    }

    if(coraccsufferror){

      ErrorMessage('Network Error.Please Re-Save')
      
      
    }

  },[createBankAccountReducer])



  
  useEffect(()=>{
    
    setprouser(AllUser.filter(obj => (obj.loginstatus == 'System Administrator' || obj.loginstatus == 'Branch Manager' || obj.loginstatus == 'Operations Manager'
        || obj.loginstatus == 'General Manager' || obj.loginstatus == 'Bank Administrator') && obj.username != userInfo.username))

  } ,[dispatch])
  

  const createBankAccount=()=>{

    if(company==''||cat_bus==''|| nat_bus==''|| phone==''){
      ErrorMessage('All Required Company Details Must Be Filled')

    }else if(!(phone.match('^[0-9]{10}$'))){

      ErrorMessage('Please provide a valid Company Telephone Number')


    }else if(asname1=='' || asdob1=='' || asgen1==''|| asmei1==''|| asnat1==''|| aspn1==''|| asid1==''|| asidno1==''|| asiddat1==''|| asidex1==''|| asocc1==''|| 
      asjt1==''||aspos1==''|| asresadd1=='' || asnea1==''|| ascity1=='' || asemail1==''| assig1==''){
      
      ErrorMessage('All Required Company Account Signatory Details (1) Must Be Filled')

    }else if(asname2=='' || asdob2=='' || asgen2==''|| asmei2==''|| asnat2==''|| aspn2==''|| asid2==''|| asidno2==''|| asiddat2==''|| asidex2==''|| asocc2==''|| 
      asjt2==''||aspos2==''|| asresadd2=='' || asnea2==''|| ascity2=='' || asemail2==''| assig2==''){
      
      ErrorMessage('All Required Company Account Signatory Details (2) Must Be Filled')
    
    }else if(picone=='' || pic2=='' || assig1==''|| assig2=='' ){

      ErrorMessage('All Required Signatory Pictures And Signature Must Be Filled')

    }else if(product_name=='' || product_code=='' || account_number=='' || branch=='' ||  initial=='' ){

      ErrorMessage('All Required Product And Account Details Must Be Filled')


    }else if(approval_byb==''){
    
      ErrorMessage('Please Select Request Account Approval User')

      }else {
        

        let acc_type='Corporate Account'
            let customer_id_id=memberid
            let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
            let level=userProfileInfo.Authorisation_Level
            let save_by=userInfo.username
            let sms_number=phone

        if(CorporateAccountDetails.member_id==MemberDetailsInfo.trans_code){

          

        }else {

          dispatch(createCustomercompanyDetail(member_id , company ,cer_reg ,dat_reg,jur_reg,cat_bus,nat_bus,sec,bus_add,cor_bus_add,busemail,web,phone,tin,bus_no,other_refe,anu_turn))

        }
      
      

        if(CorporateAccountsignatoryDetails.member_id==MemberDetailsInfo.trans_code){


        }else{

          dispatch(createCustomercompanysignatory(member_id, 
        
            asname1 ,asdob1 ,asgen1,asmei1,asnat1,aspn1,asid1,asidno1,asiddat1,asidex1,asocc1,asjt1,aspos1,asresadd1,asnea1,ascity1,asemail1,assig1 , 
    
             asname2 ,asdob2 ,asgen2,asmei2,asnat2,aspn2,asid2,asidno2,asiddat2,asidex2,asocc2,asjt2,aspos2,asresadd2,asnea2,ascity2,asemail2,assig2 ,
    
             asname3 ,asdob3 ,asgen3,asmei3,asnat3,aspn3,asid3,asidno3,asiddat3,asidex3,asocc3,asjt3,aspos3,asresadd3,asnea3,ascity3,asemail3,assig3 
             
             ,card_ty ,card_Id ,asid1, asidno1 , asid2 , asidno2 ))


        }
 
         dispatch(createCustomerBankAccounAction(customer_id_id ,product_name ,product_code , account_number,company ,branch ,dat,initial ,assig1,level 
          ,save_by ,acc_type ,bank_code ,branch_code ,customer_number ,assig2,assig3 ,picone ,pic2 ,pic3,sms_number ,card_ty ,card_Id ,asid1 ,asidno1
           ,asid2 ,asidno2 ,asname1 ,aspn1 ,asname2 ,aspn2 ,asname3 ,aspn3))
    }

  }

  const clear=()=>{

  setCode('')
  setmemberid('')
  setpic('')
  SetSex('')
  setfullname('')
  setr_add('')
  setpob('')
  setreg('')
  setnland('')
  settel('')
  setemail('')
  setdob('')
  setcity('')
  setedu('')
  setmar('')
  setcard_Id('')
  setcard_ty('')
  setcard_is_dat('')
 setcard_ex_date('')


  setmember_id('')
  setcompany('')
  setcer_reg('')
  setdat_reg('')
  setjur_reg('')
  setcat_bus('')
  setnat_bus('')
  setsec('')
  setbus_add('')
  setcor_bus_add('')
  setbusemail('')
  setweb('')
  setphone('')
  settin('')
  setbus_no('')
  setother_refe('')
  setanu_turn('')


  setasname1('')
  setasdob1('')
  setasgen1('')
  setasmei1('')
  setasnat1('')
  setaspn1('')
  setasid1('')
  setasiddat1('')
  setasidno1('')
  setasidex1('')
  setasocc1('')
  setasjt1('')
  setaspos1('')
  setasresadd1('')
  setasnea1('')
  setascity1('')
  setasemail1('')


  setasname2('')
  setasdob2('')
  setasgen2('')
  setasmei2('')
  setasnat2('')
 setaspn2('')
  setasid2('')
  setasiddat2('')
  setasidno2('')
  setasidex2('')
  setasocc2('')
  setasjt2('')
 setaspos2('')
  setasresadd2('')
 setasnea2('')
  setascity2('')
  setasemail2('')


 setasname3('')
 setasdob3('')
  setasgen3('')
 setasmei3('')
  setasnat3('')
  setaspn3('')
  setasid3('')
  setasiddat3('')
  setasidno3('')
  setasidex3('')
  setasocc3('')
  setasjt3('')
  setaspos3('')
  setasresadd3('')
  setasnea3('')
  setascity3('')
  setasemail3('')
  setbranch_code('')

  setpicone('')
  setpic2('')
  setpic3('')

  setassig1('')
  setassig2('')
  setassig3('')
  
  setGetProduct("")
  setInitial('')
  setaccount_number('')
  setProductCode("")

  }

  useEffect(()=>{

    try {

        if(AccountTypeDetails){

   
            if(AccountTypeDetails.length >0){
        
                const fineAccount=AccountTypeDetails.find(obj=>obj.product_name==product_name)
        
                console.log(fineAccount)
                
                if(fineAccount){
          
                    ErrorMessage('Customer Already Has a ' + product_name)
        
                   // setGetProduct("Select")
                    //setProductCode("")
                    setInitial("")
                    setaccount_number("")
                    
        
                }else{
        
                    loadCodeIn()
        
                }
        
        
            }else {
        
                loadCodeIn()
        
            }
        }else {
            loadCodeIn()
        }
        
    } catch (error) {
        
    }

   


  },[CustomerAccountTypeDetailsReducer])

  const loadCodeIn=()=>{
    
    try {

        const selectedProduct=products.find(obj=>obj.product_name==product_name)

        //console.log(selectedProduct)
    
        setProductCode(selectedProduct.product_code)
        setInitial(selectedProduct.idr_ind)
    
        const getBranchCode=branchsInfo.find(obj=>obj.name==branch)
    
        setaccount_number(bankInfo.code + getBranchCode.branch_code+selectedProduct.product_code+code)
        setbank_code(bankInfo.code)
        setbranch_code(getBranchCode.branch_code)
        setcustomer_number(selectedProduct.product_code+code)
        
    } catch (error) {
        
    }
   

  }


  const accountexit=(e)=>{
    e.preventDefault()
    setGetProduct(e.target.value)
    dispatch(CustomerAccountTypeAction(memberid))
  }
  
  
  
  return (
    <div>

        {memberDetailsLoading && <Loader/>}
        {CorporateAccountDetailsLoading && <Loader/>}
        {CorporateAccountsignatoryDetailsLoading && <Loader/>}
        {SignatoryLoading && <Loader/>}
        {coraccLoading && <Loader/>}
        {customerAccountUpdateLoading && <Loader/>}
        
       
        

        <h3>Corporate Account Opening Information</h3>

      <div className='card'>
        <div className=''>
       
      <Tabs
      defaultActiveKey="New Account"
      id="justify-tab-example"
      className="mb-3"
      justify
    >


      <Tab eventKey="New Account" title="New Account">
      <h4 className="card-title ms-5 mb-4"> Account Opening Form</h4>
      <div className='solcon overflow-auto vh-100'> 

      <div className="tab-pane fade show active profile-overview ms-5 me-5" id="profile-overview">

         {coraccsufferror && <Message variant={'danger'}>Network Error.Please Re-Save</Message>}
             
              
      <Stepper
         showProgressBar="false"
         fillStroke="#012970"
         activeColor="#06A3DA"
         activeProgressBorder="2px solid #06A3DA"
         defaultActiveStep='true'

         submitBtn={<button className="btn-class page1">Save</button>}
         continueBtn={<button className="show_info_btn page1">Next Page</button>}
         backBtn={<button className="show_info_btn">Previous Page</button>}
         onSubmit={

             (step) => createBankAccount()

         }
      >
       


        <div className="stepperSubDiv">

            <Link to="/SearchMemberInfomation"><button className='btn-class py-2 px-4 mt-2 mb-3' type="button"><CiSearch size={25} />Search Member Information</button></Link>

                <h3>1.Key Contact Preson / Principal Officer Details</h3>
                
                <div className="row mt-4">

                <div className="col-md-9 col-lg-9">
                <label className='lable' for='Surname'>Full Name </label>
                <input name="Name" required type="text"  disabled className="form-control" value={fullname} onChange={(e)=>setfullname(e.target.value)}  />
                </div>


                <div className="col-md-3 col-lg-3">
                <lable className='lable' for='Login Status'>Gender</lable>
                    <select required disabled  className='form-control' value={sex} >
                        <option disabled selected value="" >Select</option>
                        <option value="Male">Male</option>
                        <option value="Famale">Famale</option>
                    </select>
                </div>

        
                </div>



                <div className="row mt-4">
            

                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'>Residential Address</label>
                <input name="Name" disabled required type="text" className="form-control" value={r_add} 
                    onChange={(e)=>setr_add(e.target.value)} />
                </div>


                <div className="col-md-3 col-lg-3">
                <label  className='lable' for='Surname'>Place Of Birth</label>
                <input name="Name" disabled required type="text" className="form-control" value={pob}  
                    onChange={(e)=>setpob(e.target.value)}/>
                </div>

                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'>Region</label>
                <input name="Name" disabled required type="text" className="form-control" value={reg} 
                    onChange={(e)=>setreg(e.target.value)}/>
                </div>

                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'>Nearest Landmark</label>
                <input name="Name" disabled  required  type="text" className="form-control" value={nland} 
                    onChange={(e)=>setnland(e.target.value)} />
                </div>


                </div>

            

                <div className="row mt-4">

                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'>Telephone Number</label>
                <input name="number" disabled required type="number" className="form-control"  value={tel} 
                    onChange={(e)=>settel(e.target.value)}/>
                </div>

                <div className="col-md-3 col-lg-3">
                <label for='Surname'>Email</label>
                <input name="email" disabled type="email" className="form-control" value={email} 
                    onChange={(e)=>setemail(e.target.value)} />
                </div>


                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'>Date Of Birth</label>
                <input name="number" disabled type="date" className="form-control" value={dob} 
                    onChange={(e)=>setdob(e.target.value)} />
                </div>
            
            
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'>City</label>
                <input name="Place"  disabled type="text" className="form-control" value={city} 
                    onChange={(e)=>setcity(e.target.value)}/>
                </div>
                
                </div>

                <div className="row mt-4 ">

                <div className="col-md-3 col-lg-3">
                <lable className='lable' for='Login Status'>Type Of Identification</lable>
                        <select required disabled  className='form-control' value={card_ty}  onChange={(e)=>setcard_ty(e.target.value)}  >
                            <option selected value="Select" >Select</option>
                            <option value="Voters Card">Voters Card</option>
                            <option value="Ghana Card">Ghana Card</option>
                            <option value="Drivers License">Drivers License</option>
                            <option value="Passport">Passport</option>
                            <option value="SSNIT Card">SSNIT Card</option>
                        </select>
                </div>

                <div className="col-md-3 col-lg-3">
                <label className='lable' for='ID Number'>ID N0.</label>
                    <input name="Name" disabled required type="text" className="form-control" value={card_Id}
                    onChange={(e)=>setcard_Id(e.target.value)} />
                </div>

                <div className="col-md-3 col-lg-3">
                    <label className='lable' for='Id Issue Date'>Id Issue Date</label>
                    <input name="Name" disabled required type="date" className="form-control" value={card_is_dat}  
                    onChange={(e)=>setcard_is_dat(e.target.value)} />
                </div>

                <div className="col-md-3 col-lg-3">
                    <label className='lable' for='Id Expiry Date'>Id Expiry Date</label>
                    <input name="Name" disabled required type="date" className="form-control" value={card_ex_date} 
                    onChange={(e)=>setcard_ex_date(e.target.value)} />
                </div>


                </div>
            
                <div className="row mt-4">


                <div className="col-md-3 col-lg-3">
                <lable className='lable' for='Login Status'>Educational Status</lable>
                        <select required disabled className='form-control' value={edu} 
                        onChange={(e)=>setedu(e.target.value)} >
                            <option disabled selected value="" >Select</option>
                            <option value="Tertiary">Tertiary</option>
                            <option value="Secondary">Secondary</option>
                            <option value="Others">Others</option>
                        </select>
                </div>


                <div className="col-md-3 col-lg-3">
                <lable className='lable' for='Login Status'>Marital Status</lable>
                        <select required disabled className='form-control' value={mar} 
                        onChange={(e)=>setmar(e.target.value)} >
                            <option disabled selected value="" >Select</option>
                            <option value="Married">Married</option>
                            <option value="Single">Single</option>
                            <option value="Divorce">Divorce</option>
                        </select>
                </div>
                </div>

        
        </div>

      

        <div className="stepperSubDiv">
          
        <h3>2.Company Details</h3>
            
            <div className="row mt-4">
                  
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Surname'> <samp className='text-danger'>* </samp> Company/Business Name</label>
                  <input name="Name" required type="text" className="form-control" value={company} onChange={(e)=>setcompany(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='First Name'>Certification oF Incorporation/Registration</label>
                  <input name="Name" required type="text" className="form-control" value={cer_reg} onChange={(e)=>setcer_reg(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Middle Name(s)'>Date oF Incorporation/Registration </label>
                  <input name="Name" required type="date" className="form-control"  value={dat_reg} onChange={(e)=>setdat_reg(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
                  <label className='lable' for='Date Of Birth'>Jurisdiction of Incorporation/Registration </label>
                    <input name="Name" required type="text" className="form-control"  value={jur_reg} onChange={(e)=>setjur_reg(e.target.value)}  />
                </div>
  
             </div>
  
             
  
  
  
             <div className="row mt-4">
                  
             <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Category Of Business</lable>
                    <select required  className='form-control' value={cat_bus} onChange={(e)=>setcat_bus(e.target.value)} >
                        <option disabled selected value="" >Select</option>
                        <option value="Limited Liability Company">Limited Liability Company</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                        <option value="MMDA's">MMDA's</option>
                        <option value="MMDA's">Charities</option>
                    </select>
              </div>
                      
              
  
                
    
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Mother(s) Meiden Name'><samp className='text-danger'>* </samp> Type or Nature Of Business</label>
                    <input name="Name" required type="text" className="form-control" value={nat_bus} onChange={(e)=>setnat_bus(e.target.value)}  />
                </div>
  
                <div className="col-md-3 col-lg-3">
                  <label className='lable' for='Nationality'>Sectory/Industry</label>
                    <input name="Name" required type="text" className="form-control" value={sec} onChange={(e)=>setsec(e.target.value)}  />
                </div>
    
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Resident Permit N0'>Operating Business Address</label>
                    <input name="Name" required type="text" className="form-control" value={bus_add} onChange={(e)=>setbus_add(e.target.value)} />
                </div>
    
               </div>
  
               
  
               <div className="row mt-4 ">
  
               <div className="col-md-3 col-lg-3">
                <lable  className='lable' for='Login Status'>Corporate Business Address (Office)</lable>
                  <input name="Name" required type="text" className="form-control" value={cor_bus_add} onChange={(e)=>setcor_bus_add(e.target.value)} />
                </div>
  
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='ID Number'>Email</label>
                    <input name="Name" required type="email" className="form-control" value={busemail} onChange={(e)=>setbusemail(e.target.value)}  />
                </div>
  
                <div className="col-md-3 col-lg-3">
                  <label className='lable' for='Id Issue Date'>Website'</label>
                    <input name="Name" required type="text" className="form-control" value={web} onChange={(e)=>setweb(e.target.value)}  />
                </div>
  
                <div className="col-md-3 col-lg-3">
                  <label className='lable' for='Id Expiry Date'><samp className='text-danger'>* </samp>Telephone Number</label>
                    <input name="Name" required type="text" className="form-control" value={phone} onChange={(e)=>setphone(e.target.value)} />
                </div>
              
    
               </div>
  
               
  
               <div className="row mt-4">
                  
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Occupation'>Tax Identification N0.(Tin)</label>
                  <input name="Occupation" required type="text" className="form-control" value={tin} onChange={(e)=>settin(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Job Titl'>Certification to commence Business N0.</label>
                  <input name="Name" required type="text" className="form-control" value={bus_no} onChange={(e)=>setbus_no(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Position'>Other Reference N0.</label>
                  <input name="Name" required type="text" className="form-control" value={other_refe} onChange={(e)=>setother_refe(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'>Annual Turnover</lable>
                    <select required  className='form-control' value={anu_turn} onChange={(e)=>setanu_turn(e.target.value)} >
                        <option disabled selected value="" >Select</option>
                        <option value="GH 0-9,99">GHS 0-9,99</option>
                        <option value="GHS 10,000-49,99">GHS 10,000-49,99</option>
                        <option value="GHS 50,000-49,99">GHS 50,000-49,99</option>
                        <option value="GHS 100,000 and above">GHS 100,000 and above</option>
                       
                    </select>
              </div>
  
             </div>

        </div>


        <div className="stepperSubDiv">
          <h3>3.Account Signatory's Details (1)</h3>
            
          <div className="row mt-4">
                
              <div className="col-md-9 col-lg-9">
              <label className='lable' for='Surname'><samp className='text-danger'>* </samp> Full Name  <samp className='text-primary'> [Surname / First Name / Middle Name(s) ]</samp> </label>
                <input name="Name" required type="text" className="form-control" value={asname1} onChange={(e)=>setasname1(e.target.value)}  />
            </div>

            <div className="col-md-3 col-lg-3">
                <label className='lable' for='Date Of Birth'><samp className='text-danger'>* </samp> Date Of Birth</label>
                  <input name="Name" required type="date" className="form-control" value={asdob1} onChange={(e)=>setasdob1(e.target.value)} />
              </div>

           </div>

           
           <div className="row mt-4">
                
              <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Gender</lable>
                    <select required  className='form-control' value={asgen1} onChange={(e)=>setasgen1(e.target.value)}>
                        <option disabled selected value="" >Select</option>
                        <option value="Bank Administrator">Male</option>
                        <option value="Customer service representatives">Famale</option>
                    </select>
              </div>
                    
            

              
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Mother(s) Meiden Name'><samp className='text-danger'>* </samp> Mother(s) Meiden Name</label>
                  <input name="Name" required type="text" className="form-control" value={asmei1} onChange={(e)=>setasmei1(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Nationality'><samp className='text-danger'>* </samp> Nationality</label>
                  <input name="Name" required type="text" className="form-control" value={asnat1} onChange={(e)=>setasnat1(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Landmark'><samp className='text-danger'>* </samp> Phone Number</label>
                  <input name="Landmark" required type="number" className="form-control" value={aspn1} onChange={(e)=>setaspn1(e.target.value)} />
              </div>
  
             </div>

             

             <div className="row mt-4 ">

             <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
                    <select required  className='form-control' value={asid1} onChange={(e)=>setasid1(e.target.value)}>
                        <option disabled selected value="" >Select</option>
                        <option value="Voters Card">Voters Card</option>
                        <option value="Ghana Card">Ghana Card</option>
                        <option value="Drivers License">Drivers License</option>
                        <option value="Passport">Passport</option>
                        <option value="SSNIT Card">SSNIT Card</option>
                    </select>
              </div>

              <div className="col-md-3 col-lg-3">
              <label className='lable' for='ID Number'><samp className='text-danger'>* </samp> ID N0.</label>
                  <input name="Name" required type="text" className="form-control" value={asidno1} onChange={(e)=>setasidno1(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Id Issue Date'><samp className='text-danger'>* </samp> Id Issue Date</label>
                  <input name="Name" required type="date" className="form-control" value={asiddat1} onChange={(e)=>setasiddat1(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Id Expiry Date'><samp className='text-danger'>* </samp> Id Expiry Date</label>
                  <input name="Name" required type="date" className="form-control" value={asidex1} onChange={(e)=>setasidex1(e.target.value)}  />
              </div>
            
  
             </div>

             

             <div className="row mt-4">
                
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Occupation'><samp className='text-danger'>* </samp> Occupation</label>
                <input name="Occupation" required type="text" className="form-control" value={asocc1} onChange={(e)=>setasocc1(e.target.value)} />
            </div>

            <div className="col-md-3 col-lg-3">
            <label className='lable' for='Job Titl'><samp className='text-danger'>* </samp> Job Title</label>
                <input name="Name" required type="text" className="form-control" value={asjt1} onChange={(e)=>setasjt1(e.target.value)} />
            </div>

            <div className="col-md-3 col-lg-3">
            <label className='lable' for='Position'><samp className='text-danger'>* </samp> Position</label>
                <input name="Name" required type="text" className="form-control" value={aspos1} onChange={(e)=>setaspos1(e.target.value)}  />
            </div>

            <div className="col-md-3 col-lg-3">
                <label className='lable' for='Residential Address'><samp className='text-danger'>* </samp> Residential Address</label>
                  <input name="Name" required type="text" className="form-control" value={asresadd1} onChange={(e)=>setasresadd1(e.target.value)} />
              </div>

           </div>


           <div className="row mt-4">
                
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Landmark'><samp className='text-danger'>* </samp> Nearest Landmark</label>
                  <input name="Landmark" required type="text" className="form-control" value={asnea1} onChange={(e)=>setasnea1(e.target.value)}  />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Job Titl'><samp className='text-danger'>* </samp> City/Town</label>
                  <input name="Name" required type="text" className="form-control"  value={ascity1} onChange={(e)=>setascity1(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label  className='lable' for='Position'><samp className='text-danger'>* </samp> Email</label>
                  <input name="Name" required type="em" className="form-control" value={asemail1} onChange={(e)=>setasemail1(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Date'><samp className='text-danger'>* </samp> Upload Signature</label>
              <ImageUploading 
                    multiple
                    value={images1}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                    className='mt-4'
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            images1.length ==1 ? '' :(
                                <button className='btn-class'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse for Signature
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image1, index) => (
                        <div key={index} className="image-item">
                            <img src={image1.data_url} alt="" width="120" />
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
              </ImageUploading>
              </div>
  
             </div>


            


            
 
        </div>


        <div className="stepperSubDiv">
          <h3>4.Account Signatory's Details (2)</h3>
            
          <div className="row mt-4">
                
              <div className="col-md-9 col-lg-9">
              <label className='lable' for='Surname'><samp className='text-danger'>* </samp> Full Name  <samp className='text-primary'> [Surname / First Name / Middle Name(s) ]</samp> </label>
                <input name="Name" required type="text" className="form-control" value={asname2} onChange={(e)=>setasname2(e.target.value)}  />
            </div>

            <div className="col-md-3 col-lg-3">
                <label className='lable' for='Date Of Birth'><samp className='text-danger'>* </samp> Date Of Birth</label>
                  <input name="Name" required type="date" className="form-control" value={asdob2} onChange={(e)=>setasdob2(e.target.value)} />
              </div>

           </div>

           
           <div className="row mt-4">
                
              <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Gender</lable>
                    <select required  className='form-control' value={asgen2} onChange={(e)=>setasgen2(e.target.value)}>
                        <option disabled selected value="" >Select</option>
                        <option value="Bank Administrator">Male</option>
                        <option value="Customer service representatives">Famale</option>
                    </select>
              </div>
                    
            

              
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Mother(s) Meiden Name'><samp className='text-danger'>* </samp> Mother(s) Meiden Name</label>
                  <input name="Name" required type="text" className="form-control" value={asmei2} onChange={(e)=>setasmei2(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Nationality'><samp className='text-danger'>* </samp> Nationality</label>
                  <input name="Name" required type="text" className="form-control" value={asnat2} onChange={(e)=>setasnat2(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Landmark'><samp className='text-danger'>* </samp> Phone Number</label>
                  <input name="Landmark" required type="number" className="form-control" value={aspn2} onChange={(e)=>setaspn2(e.target.value)} />
              </div>
  
             </div>

             

             <div className="row mt-4 ">

             <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
                    <select required  className='form-control' value={asid2} onChange={(e)=>setasid2(e.target.value)}>
                        <option disabled selected value="" >Select</option>
                        <option value="Voters Card">Voters Card</option>
                        <option value="Ghana Card">Ghana Card</option>
                        <option value="Drivers License">Drivers License</option>
                        <option value="Passport">Passport</option>
                        <option value="SSNIT Card">SSNIT Card</option>
                    </select>
              </div>

              <div className="col-md-3 col-lg-3">
              <label className='lable' for='ID Number'><samp className='text-danger'>* </samp> ID N0.</label>
                  <input name="Name" required type="text" className="form-control" value={asidno2} onChange={(e)=>setasidno2(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Id Issue Date'><samp className='text-danger'>* </samp> Id Issue Date</label>
                  <input name="Name" required type="date" className="form-control" value={asiddat2} onChange={(e)=>setasiddat2(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Id Expiry Date'><samp className='text-danger'>* </samp> Id Expiry Date</label>
                  <input name="Name" required type="date" className="form-control" value={asidex2} onChange={(e)=>setasidex2(e.target.value)}  />
              </div>
            
  
             </div>

             

             <div className="row mt-4">
                
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Occupation'><samp className='text-danger'>* </samp> Occupation</label>
                <input name="Occupation" required type="text" className="form-control" value={asocc2} onChange={(e)=>setasocc2(e.target.value)} />
            </div>

            <div className="col-md-3 col-lg-3">
            <label className='lable' for='Job Titl'><samp className='text-danger'>* </samp> Job Title</label>
                <input name="Name" required type="text" className="form-control" value={asjt2} onChange={(e)=>setasjt2(e.target.value)} />
            </div>

            <div className="col-md-3 col-lg-3">
            <label className='lable' for='Position'><samp className='text-danger'>* </samp> Position</label>
                <input name="Name" required type="text" className="form-control" value={aspos2} onChange={(e)=>setaspos2(e.target.value)}  />
            </div>

            <div className="col-md-3 col-lg-3">
                <label className='lable' for='Residential Address'><samp className='text-danger'>* </samp> Residential Address</label>
                  <input name="Name" required type="text" className="form-control" value={asresadd2} onChange={(e)=>setasresadd2(e.target.value)} />
              </div>

           </div>


           <div className="row mt-4">
                
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Landmark'><samp className='text-danger'>* </samp> Nearest Landmark</label>
                  <input name="Landmark" required type="text" className="form-control" value={asnea2} onChange={(e)=>setasnea2(e.target.value)}  />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Job Titl'><samp className='text-danger'>* </samp> City/Town</label>
                  <input name="Name" required type="text" className="form-control"  value={ascity2} onChange={(e)=>setascity2(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label  className='lable' for='Position'><samp className='text-danger'>* </samp> Email</label>
                  <input name="Name" required type="em" className="form-control" value={asemail2} onChange={(e)=>setasemail2(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Date'><samp className='text-danger'>* </samp> Upload Signature</label>

              <ImageUploading 
                    multiple
                    value={images2}
                    onChange={onChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                    className='mt-4'
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            images2.length ==1 ? '' :(
                                <button className='btn-class'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse for Signature
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image2, index) => (
                        <div key={index} className="image-item">
                            <img src={image2.data_url} alt="" width="120" />
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
              </ImageUploading>
              </div>
  
             </div>


            


            
 
        </div>

        <div className="stepperSubDiv">
          <h3>5.Account Signatory's Details (3)</h3>
            
          <div className="row mt-4">
                
              <div className="col-md-9 col-lg-9">
              <label className='lable' for='Surname'> Full Name  <samp className='text-primary'> [Surname / First Name / Middle Name(s) ]</samp> </label>
                <input name="Name" required type="text" className="form-control" value={asname3} onChange={(e)=>setasname3(e.target.value)}  />
            </div>

            <div className="col-md-3 col-lg-3">
                <label className='lable' for='Date Of Birth'> Date Of Birth</label>
                  <input name="Name" required type="date" className="form-control" value={asdob3} onChange={(e)=>setasdob3(e.target.value)} />
              </div>

           </div>

           
           <div className="row mt-4">
                
              <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'> Gender</lable>
                    <select required  className='form-control' value={asgen3} onChange={(e)=>setasgen3(e.target.value)}>
                        <option disabled selected value="" >Select</option>
                        <option value="Bank Administrator">Male</option>
                        <option value="Customer service representatives">Famale</option>
                    </select>
              </div>
                    
            
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Mother(s) Meiden Name'> Mother(s) Meiden Name</label>
                  <input name="Name" required type="text" className="form-control" value={asmei3} onChange={(e)=>setasmei3(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Nationality'> Nationality</label>
                  <input name="Name" required type="text" className="form-control" value={asnat3} onChange={(e)=>setasnat3(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Landmark'> Phone Number</label>
                  <input name="Landmark" required type="number" className="form-control" value={aspn3} onChange={(e)=>setaspn3(e.target.value)} />
              </div>
  
             </div>

             

             <div className="row mt-4 ">

             <div className="col-md-3 col-lg-3">
              <lable className='lable' for='Login Status'> Type Of Identification</lable>
                    <select required  className='form-control' value={asid3} onChange={(e)=>setasid3(e.target.value)}>
                        <option disabled selected value="" >Select</option>
                        <option value="Voters Card">Voters Card</option>
                        <option value="Ghana Card">Ghana Card</option>
                        <option value="Drivers License">Drivers License</option>
                        <option value="Passport">Passport</option>
                        <option value="SSNIT Card">SSNIT Card</option>
                    </select>
              </div>

              <div className="col-md-3 col-lg-3">
              <label className='lable' for='ID Number'> ID N0.</label>
                  <input name="Name" required type="text" className="form-control" value={asidno3} onChange={(e)=>setasidno3(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Id Issue Date'> Id Issue Date</label>
                  <input name="Name" required type="date" className="form-control" value={asiddat3} onChange={(e)=>setasiddat3(e.target.value)} />
              </div>

              <div className="col-md-3 col-lg-3">
                <label className='lable' for='Id Expiry Date'> Id Expiry Date</label>
                  <input name="Name" required type="date" className="form-control" value={asidex3} onChange={(e)=>setasidex3(e.target.value)}  />
              </div>
            
  
             </div>

             

             <div className="row mt-4">
                
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Occupation'> Occupation</label>
                <input name="Occupation" required type="text" className="form-control" value={asocc3} onChange={(e)=>setasocc3(e.target.value)} />
            </div>

            <div className="col-md-3 col-lg-3">
            <label className='lable' for='Job Titl'> Job Title</label>
                <input name="Name" required type="text" className="form-control" value={asjt3} onChange={(e)=>setasjt3(e.target.value)} />
            </div>

            <div className="col-md-3 col-lg-3">
            <label className='lable' for='Position'> Position</label>
                <input name="Name" required type="text" className="form-control" value={aspos3} onChange={(e)=>setaspos3(e.target.value)}  />
            </div>

            <div className="col-md-3 col-lg-3">
                <label className='lable' for='Residential Address'> Residential Address</label>
                  <input name="Name" required type="text" className="form-control" value={asresadd3} onChange={(e)=>setasresadd3(e.target.value)} />
              </div>

           </div>


           <div className="row mt-4">
                
                <div className="col-md-3 col-lg-3">
                <label className='lable' for='Landmark'> Nearest Landmark</label>
                  <input name="Landmark" required type="text" className="form-control" value={asnea3} onChange={(e)=>setasnea3(e.target.value)}  />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Job Titl'> City/Town</label>
                  <input name="Name" required type="text" className="form-control"  value={ascity3} onChange={(e)=>setascity3(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label  className='lable' for='Position'> Email</label>
                  <input name="Name" required type="em" className="form-control" value={asemail3} onChange={(e)=>setasemail3(e.target.value)} />
              </div>
  
              <div className="col-md-3 col-lg-3">
              <label className='lable' for='Date'> Upload Signature</label>
              <ImageUploading 
                    multiple
                    value={images3}
                    onChange={onChange3}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                    className='mt-4'
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            images3.length ==1 ? '' :(
                                <button className='btn-class'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse for Signature
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image3, index) => (
                        <div key={index} className="image-item">
                            <img src={image3.data_url} alt="" width="120" />
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
              </ImageUploading>
              </div>
  
             </div>

 
        </div>

        <div className="stepperSubDiv">
          <h3>6.Account Details </h3>

          <div className='row'>

            <div className='col-md-4'>
            <div className="card">
            <div className='card-header'>Account Signatory's (1)</div>
            <div className="card-body profile-card"> 
              <div className='row'>
                <div className='col-md-4'>
                   <samp><samp className='text-danger'>* </samp> Picture</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {picone !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100"  src={picone} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100" src={profile} />)}
                  </div>
                </div>
                
                <ImageUploading 
                    multiple
                    value={picImages1}
                    onChange={picOnChange1}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            picImages1.length ==1 ? '' :(
                                <button className='show_info_btn mt-3'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                           
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>

                <button onClick={handleShowmModal1} className='show_info_btn' >Camera</button>
                
                </div>
                <div className='col-md-4'>
                <samp><samp className='text-danger'>* </samp> Signature</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {assig1 !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100" src={assig1} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item"  width="80" height="100" src={sigd} />)}
                  </div>
                </div>
               
                </div>
                <div className='col-md-4'>
                  <samp>fingerprint</samp>
                  <button className="show_info_btn mt-1">Take print</button>
                </div>
              </div>
            </div> 
            </div>
            </div>


            <div className='col-md-4'>
            <div className="card">
            <div className='card-header'>Account Signatory's (2)</div>
            <div className="card-body profile-card"> 
              <div className='row'>
                <div className='col-md-4'>
                   <samp><samp className='text-danger'>* </samp> Picture</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {pic2 !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100"  src={pic2} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100" src={profile} />)}
                  </div>
                </div>

                  <ImageUploading 
                    multiple
                    value={picImages2}
                    onChange={picOnChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            picImages2.length ==1 ? '' :(
                                <button className='show_info_btn mt-3'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                           
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>
                <button onClick={handleShowmModal2} className='show_info_btn mt-2' >Camera</button>
                


                
                </div>
                <div className='col-md-4'>
                <samp><samp className='text-danger'>* </samp> Signature</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {assig2 !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100" src={assig2} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item" src={sigd} />)}
                  </div>
                </div>
               
                </div>
                <div className='col-md-4'>
                  <samp>fingerprint</samp>
                  <button className="show_info_btn mt-1">Take print</button>
                </div>
              </div>
            </div> 
            </div>
            </div>

            <div className='col-md-4'>
            <div className="card">
            <div className='card-header'>Account Signatory's (3)</div>
            <div className="card-body profile-card"> 
              <div className='row'>
                <div className='col-md-4'>
                   <samp>Picture</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {pic3 !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item"  width="80" height="100" src={pic3} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100" src={profile} />)}
                  </div>
                </div>

                 <ImageUploading 
                    multiple
                    value={picImages3}
                    onChange={picOnChange3}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            picImages3.length ==1 ? '' :(
                                <button className='show_info_btn mt-3'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                           
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>
                <button onClick={handleShowmModal3} className='show_info_btn mt-2' >Camera</button>
                


                
                </div>
                <div className='col-md-4'>
                <samp>Signature</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {assig3 !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item" width="80" height="100" src={assig3} /> ) 
                        : (<img  alt="Card image cap" className="card-img-top embed-responsive-item"  width="80" height="100" src={sigd} />)}
                  </div>
                </div>
                
                </div>
                <div className='col-md-4'>
                  <samp>fingerprint</samp>
                  <button className="show_info_btn mt-1">Take print</button>
                </div>
              </div>
            </div> 
            </div>
            </div>

          </div>


          <div className='row'>
          <div className='col-md-6'>
              <div className='card mt-3'>
                  <div className='card-header'>Product Infomation</div>
                  <div className='card-body'>

                      <div className="row">
                          <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Product Name:</label>
                          <div className="col-md-8 col-lg-8">
                          <select required  className='form-control'onChange={accountexit} value={product_name} >
                          <option selected disabled value="" >Select</option>
                          {products.map((product)=>(
                              <option value={product.product_name}>{product.product_name}</option>
                          ))} 
                              
                          </select>
                          </div>
                      </div>

                      <div className="row mt-1">
                          <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Product Code:</label>
                          <div className="col-md-8 col-lg-8">
                              <input name="Name"  disabled type="number" className="form-control" value={product_code} 
                              onChange={(e)=>setProductCode(e.target.value)}/>
                          </div>
                      </div>

                      <div className="row mt-1">
                          <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Initial Deposit (IRD):</label>
                          <div className="col-md-8 col-lg-8" >
                              <input name="Name" disabled  type="text" className="form-control" value={initial} />
                          </div>
                      </div>

                                                  
                  </div>
              </div>
          </div>
          

          
          <div className='col-md-6'>
              <div className='card mt-3'>
                  <div className='card-header'>Accounts</div>
                  <div className='card-body'>

                      <div className="row">
                          <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Account Number:</label>
                          <div className="col-md-8 col-lg-8">
                              <input name="Name"  type="number" disabled className="form-control" value={account_number} />
                          </div>
                      </div>

                      <div className="row mt-1">
                          <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Account Name:</label>
                          <div className="col-md-8 col-lg-8">
                              <input name="Name" type="text" disabled className="form-control" value={company} />
                          </div>
                      </div>

                      <div className="row mt-1">
                          <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Branch:</label>
                          <div className="col-md-8 col-lg-8">
                              <input name="Name" type="text"  disabled className="form-control" value={branch}  />
                          </div>
                      </div>

                      
                  </div>
              </div>

              
          </div>

          <div className='row'>
          <div className='col-md-6'>

          <div className='mt-4'>
                <label className='' for='Utility'><samp className='text-danger'>* </samp>Request Account Approval</label>
                    <select required className='form-control' value={approval_byb} onChange={(e) => setapproval_byb(e.target.value)}>
                    <option selected disabled value="" >Select</option>
                    {prouser.map((users) => (
                        <option value={users.username}>{users.name} - {users.loginstatus}</option>
                    ))}

                </select>
            </div>

          </div>
          
          
                </div>

          </div>
            
        </div>
          

        
    
      </Stepper>

              


        </div>
        </div>
      </Tab>
     

     


      <Tab eventKey="Edit Profile" title="Corporate Account Information" >

<div className='solcon overflow-auto vh-100'>
  

  <div className='container'>

  <div className='row'>
  <div className='col-md-6'>
  <div className='form-group '>
              
  <input type='text' name='product Name' required className='form-control search__input mt-3 mb-3' placeholder='Search'  />
  </div>
  </div>
</div>


  <div className='card table_buttom_margin  '>


  {customerAccountsLoading && <Loader/>}

  
  <DataTable className='rdt_Table' columns={Accountcolumns}  data={getIndAcc=='' ? getIndAcc : getIndAcc}   
      selectableRowsHighlight 
      highlightOnHover
      fixedHeader
      pagination 
      paginationPerPage={10}
          //onSelectedRowsChange={getSelectedSubjects}
      selectableRows  
          //selectableRowSelected={getPatientInfo} 
          //</div> onRowClicked={getPatientInfo}
      responsive={true}
  >

</DataTable>


  </div>

  </div>
  </div>
          
</Tab>

    </Tabs>
    
        </div>
      </div>

   

      <Modal size="lg" show={show} onHide={handleCloseModal}  backdrop="static" keyboard={false}>                                                                                                                                 
        
        <Modal.Header closeButton>
        <Modal.Title>Take picture</Modal.Title>
        </Modal.Header>
        <Modal.Body >
            
        <Camera onTakePhoto={(dataUri)=>{handleTakePhoto(dataUri)}} 
        idealResolution = {{width: 680, height: 460}} 
        idealFacingMode = {FACING_MODES.ENVIRONMENT}
          
          imageType = {IMAGE_TYPES.JPG}
          imageCompression = {0.97}
          isMaxResolution = {false}
          isImageMirror = {false}
          isDisplayStartCameraError = {true}
        />

        </Modal.Body>
                   
    </Modal>
     
    
        
    </div>
        
      
  )
}

export default CoporateAcc
