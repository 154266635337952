import React  ,{useState ,useEffect ,useRef}from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDispatch ,useSelector } from 'react-redux';
import { getCustomerDetailsAction } from '../actions/customerAccountAction';
import { getMemberDetailsAction } from '../actions/memberAction';
import { loadCustomerAccountNumberAction } from '../actions/customerAccountAction';
import Multiselect from 'multiselect-react-dropdown';
import Swal from 'sweetalert2'
import { SaveAutoAction } from '../actions/customerAccountAction';
import Loader from '../Components/Loader';
import moment from 'moment'
import { CustomerAccountAutoServicesAction } from '../actions/customerAccountAction';
import { LOAD_AUTO_RESET ,CUSTOMER_ACCOUNTS_RESET_DETAILS } from '../constants/customerAccountConstants';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { FcCheckmark } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { approvalAction } from '../actions/approvalAction';
import { FaUserTie } from "react-icons/fa";


function BankAccountServices() {

  const [show, setShow] = useState(false);

  const handleCloseModal = () => setShow(false);


  const handleShowmModal = () =>{
 
    setShow(true)

  } 

let save_aproval="True"
const dispatch=useDispatch()
const multiselectRefTracker=useRef()


const [autoService ,setautoService]=useState()
const [serviceOnDeman , setServiceOnDeman]=useState()

const MembereDetailsReducer = useSelector(state => state. MembereDetailsReducer)
const {MemberDetailsInfo ,MemberDetailssuccess ,memberDetailsLoading}= MembereDetailsReducer


const CustomerAccountDetailsReducer = useSelector(state => state. CustomerAccountDetailsReducer)
const {CustomerAccountDetails ,CustomerAccountDetailsLoading}= CustomerAccountDetailsReducer


const seriveReducer = useSelector(state => state.seriveReducer)
const { services ,loadingservices }=seriveReducer

const CustomerAccountNumberReducer = useSelector(state => state.CustomerAccountNumberReducer)
const {accountNumber}=CustomerAccountNumberReducer


const saveAutoService = useSelector(state => state.saveAutoService)
const { autoSaveLoading ,autoSave }=saveAutoService

const CustomerAutoServiceReducer = useSelector(state => state.CustomerAutoServiceReducer)
const { autoServicesLoading ,autoServices }=CustomerAutoServiceReducer

const userLogin = useSelector(state => state.userLogin)
const { userInfo }=userLogin

const userProfileReducer = useSelector(state => state.userProfileReducer)
const { userProfileInfo }=userProfileReducer

const [select,setselect]=useState(autoServices)


const [account_number ,setaccount_number]=useState(CustomerAccountDetails.account_number)
const [account_name ,setaccount_name]=useState(CustomerAccountDetails.account_name)
const [product_name ,setproduct_name]=useState(CustomerAccountDetails.product_name)
const [branch ,setbranch]=useState(CustomerAccountDetails.branch)
const [acc_type ,setacc_type]=useState(CustomerAccountDetails.acc_type)
const [service_code ,setservice_code]=useState()
const[service ,setservice]=useState()
const [amount ,setamount]=useState()
const[dat ,setdat]=useState(moment().format('MMMM Do YYYY, h:mm:ss a'))


const AutoServicecolumns =[
  {
   name:'Id' ,
   selector:row=>row.trans_code ,
   width: "100px" 
   
   ,wrap:true 

  } ,

   {
    name:'Digital Services' ,
    selector:row=>row.service_name,
    width: "450px" 
    ,wrap:true
   },

    {
      
     name:'Active' , cell:row=> <div className="form-check form-switch">
     <input class="form-check-input" type="checkbox"  checked={row.save_aproval} />
    
   </div>,
     width:"100px"
    }  ,


    {
      name:'Edit' , cell:row=> <button onClick={()=>editservice(row)}   className='btt'>  {row.save_aproval ? <FcCancel className='preview-btn'/>: <FcCheckmark className='preview-btn'/>}  </button> ,
        
      width:"100px"
     } ,
]

const editservice=(row)=>{

  if(row.save_aproval){

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Digital Services Authorized",
      text: "Do You Want To Request For Authorization to Cancel The Selected Digital Services ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
   

  }).then((result) => {

      if (result.isConfirmed) {

  
  let table='request_Services_Autoo'
  let level=userProfileInfo.Authorisation_Level
  let action=5
  let sent_by=userInfo.username
  let narration="Authorization For The Cancellation Of " + row.service_name +" Digital Services .Request By " +row.acc_type + " With Account Number " +row.account_number +" And Account Name " +row.account_name
  let table_pk=row.trans_code;
  let branch=userProfileInfo.branch
 

  dispatch(approvalAction(table ,level ,action ,sent_by ,narration ,table_pk ,branch))

  
  successMessage('Digital Services Information Successfully Loogged For Authorization')
          

      } else if (result.dismiss === Swal.DismissReason.cancel) {

     
      }
      
  });  

  }else {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Digital Services Authorized",
      text: "Do You Want To Request For Authorization to Activate The Selected Digital Services ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
   

  }).then((result) => {

      if (result.isConfirmed) {

  
  let table='request_Services_Autoo'
  let level=userProfileInfo.Authorisation_Level
  let action=4
  let sent_by=userInfo.username
  let narration="Authorization To Activate " + row.service_name +" Digital Services.Request By " +row.acc_type + " With Account Number " +row.account_number +" And Account Name " +row.account_name
  let table_pk=row.trans_code;
  let branch=userProfileInfo.branch
 

  dispatch(approvalAction(table ,level ,action ,sent_by ,narration ,table_pk ,branch))

  
  successMessage('Digital Services Information Successfully Loogged For Authorization')
          

      } else if (result.dismiss === Swal.DismissReason.cancel) {

     
      }
      
  }); 

  }

}

const successMessage=(message) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: message ,
    showConfirmButton: false,
    timer: 10000
  });
}


const ErrorMessage=(message) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: message ,
    showConfirmButton: false,
    timer: 1500000
    
  });

}

const getAutoSevice=()=>{
  try {
    
    setautoService(services.filter(obj=>obj.Service_type==='Auto'))
   // const selectChartCount = chartOfAccount.filter(chart => chart.acc_type===acc_type).length
    //console.log(auto)
    //setautoService(auto)

  } catch (error) {
    
  }
}


const getOnDemanService=()=>{
  try {
    
    setServiceOnDeman(services.filter(obj=>obj.Service_type=='On Demand'))
    //console.log(onDeman)
    

  } catch (error) {
    
  }
}


useEffect(()=>{

        multiselectRefTracker.current.resetSelectedValues()
        setselect(autoServices)
        dispatch({type:LOAD_AUTO_RESET})
        dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
        getAutoSevice()
        getOnDemanService()

        dispatch(loadCustomerAccountNumberAction(save_aproval))
         
},[dispatch])

useEffect(()=>{

  if(autoSave){
  dispatch(CustomerAccountAutoServicesAction(CustomerAccountDetails.account_number))
}

},[saveAutoService])



const selectedAutoService=(selected_data ,selectItem) => {
  //const getselecteddataLength = selected_data.length
 // console.log(getselecteddataLength)

 // console.log(selectItem.path)
 try {

  if(CustomerAccountDetails.length <=0){

 
    ErrorMessage('No Account Details Found')
    multiselectRefTracker.current.resetSelectedValues()
  
   }else {

  // multiselectRefTracker.current.resetSelectedValues()
  
   setservice_code(selectItem.service_code)
   setservice(selectItem.service_name)
  
   let level=userProfileInfo.Authorisation_Level
   let sent_by=userInfo.username
  
   let amount=0
  
   if(CustomerAccountDetails.acc_type=='Individual Account'){
      setamount(selectItem.Charges_ind)
      amount=selectItem.Charges_ind
   }else {
    setamount(selectItem.Charges_corpor)
    amount=selectItem.Charges_corpor
   }
   
  
    dispatch(SaveAutoAction(CustomerAccountDetails.account_number,CustomerAccountDetails.account_name ,CustomerAccountDetails.product_name 
      ,CustomerAccountDetails.branch ,CustomerAccountDetails.acc_type,selectItem.service_code,selectItem.service_name ,amount ,dat,level,sent_by))
  
    }
  
 } catch (error) {
  
 }
}

const removeselectedAutoService =(selected_data ,removedItem )  =>{

  console.log(removedItem.trans_code)
  
  //dispatch(deleteUserMenuSelected(removedItem.trans_code))
}



const handleOnSearch = (string, results) => {
  // onSearch will have as the first callback parameter
  // the string searched and for the second the results.
  //console.log(string, results)
}

const handleOnHover = (result) => {
  // the item hovered
 // console.log(result)
}


const handleOnSelect = (accountNumber) => {
  // the item selected
// console.log(accountNumber.account_number)
 try {
 
  //multiselectRefTracker.current.resetSelectedValues()
  dispatch(CustomerAccountAutoServicesAction(accountNumber.account_number))
  dispatch(getMemberDetailsAction(accountNumber.customer_id_id))
  dispatch(getCustomerDetailsAction(accountNumber.trans_code))
  
 // navigate(-1)
 } catch (error) {
  
 }
 



}

const handleOnFocus = () => {
  //console.log('Focused')
}

const formatResult = (accountNumber) => {
  return (
    <>

      <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.name} </span>
      <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.account_name} </span>
      
    </>
  )
}

const clear=()=>{

  dispatch({type:LOAD_AUTO_RESET})
  dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
  
}

const viewAutoServices =()=>{

  if(CustomerAccountDetails.length <=0){

    ErrorMessage('No Account Details Found')

  }else {

    handleShowmModal()
   // dispatch(CustomerAccountAutoServicesAction(accountNumber.account_number))

  }

  

}

  return (
    <div>
        <h3>Individual And Corporate Account Services</h3>
        <hr/>

        <div className='solcon overflow-auto vh-100'>
        <div className='container'>

        {CustomerAccountDetailsLoading && <Loader/>}
        {memberDetailsLoading && <Loader/>}
        {autoSaveLoading && <Loader/>}

            <div className='row table_buttom_margin'>
                <div className='col-md-4'>
                    <div className='card mb-4'>

                        <div className='card-header'>Account Infomation</div>
                        <div className='section profile'>

                        <div className="card">

                          <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">

                          <header className="App-header mb-3 mt-3">
                            <div style={{ width: 300 }}>
                            
                              <ReactSearchAutocomplete
                              items={accountNumber}
                              // fuseOptions={{ keys: ["trans_code", "tel"] }}
                              // resultStringKeyName="tel"
                              
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              onFocus={handleOnFocus}
                              onClear={clear}
                              autoFocus
                              formatResult={formatResult}
                              placeholder='Search Account Number'
                              />
                            </div>
                          </header>

                            {
                          
                              CustomerAccountDetails.length <=0 ?  <FaUserTie size={100} /> : 
                              
                              MemberDetailsInfo.pic=='' ? <FaUserTie size={100} />
                              
                              : <img  width={100} height={100}  src={MemberDetailsInfo.pic} alt="Profile" className="rounded-circle"/> 
                             
                              
                            }
                            
                          
                            <h3 className='pt-2'>{ CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx' :CustomerAccountDetails.account_number}</h3>
                            <h3 className='pt-2'>{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx':CustomerAccountDetails.account_name}</h3>
                            <h3 className='pt-2'>{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx':CustomerAccountDetails.product_name}</h3>
                            <h3 className='pt-2'>{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx' :CustomerAccountDetails.branch}</h3>
                            <h3 className='pt-2'>{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx' :CustomerAccountDetails.acc_type}</h3>

                            {
                          
                              //CustomerAccountDetails.length <=0 ?  'xxxxxxxxxxxxx' :  
                              //<img width={100} height={100}  src={CustomerAccountDetails.signature} alt="Profile "/> 
                              
                             } 
                           
                          </div>
                        </div>

                        
                        </div>


                    </div>
                </div>

                
                <div className='col-md-8 '>

                <div className='card'>

                  <div className='card-header'>Digital Services</div>
                  <div className='card-body'>

                        <Multiselect
                          displayValue="service_name"
                          onKeyPressFn={function noRefCheck(){}}
                          disablePreSelectedValues
                          onSearch={function noRefCheck(){}}
                          onSelect={selectedAutoService}
                          options={autoService}
                          selectedValues={autoServices}
                          ref={multiselectRefTracker}
                          />

                  <button onClick={viewAutoServices} className="show_info_btn mt-3">View Service Information</button>

                         
                  </div>

                </div>

                <div className='card mt-5'>

                <div className='card-header'>On Demand Service</div>
                <div className='card-body'>

                   <Multiselect
                            displayValue="service_name"
                            onKeyPressFn={function noRefCheck(){}}
                          // onRemove={removeMenuItem}
                            onSearch={function noRefCheck(){}}
                            //onSelect={addMenuItem}
                            options={serviceOnDeman}
                            //selectedValues={MenuItemsAdmin}
                          // ref={multiselectRefTracker}
                            />
                  
                </div>


                </div>
                </div>
            </div>

        </div>
        </div>




<Modal size="lg" show={show} onHide={handleCloseModal}  backdrop="static" keyboard={false}>                                                                                                                                 
                <Modal.Header closeButton>
                <Modal.Title>Digital Services</Modal.Title>
                </Modal.Header>
                <Modal.Body >

            <DataTable className='rdt_Table' columns={AutoServicecolumns}  data={autoServices=='' ? autoServices : autoServices}   
             selectableRowsHighlight 
             highlightOnHover
              fixedHeader
               pagination 
                paginationPerPage={10}
                //onSelectedRowsChange={getSelectedSubjects}
               // selectableRows  
                 //selectableRowSelected={getPatientInfo} 
                //</div> onRowClicked={getPatientInfo}
                responsive={true}
                >
 
             </DataTable>

                 

                  

                  
                    
                </Modal.Body>
               
</Modal>


      
    </div>
  )
}

export default BankAccountServices
