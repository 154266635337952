import React ,{useState ,useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch ,useSelector } from 'react-redux';
import { registerservice,serviceList ,updateSerivceAction } from '../actions/settingAction';
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2'
import { SERVICE_RESET_UPDATE } from '../constants/settingConstants';

function ChageServicer() {

    const [creatNewPService ,setCreatNewPService]=useState(false)

  const registerServiceReducer = useSelector(state => state.registerServiceReducer)
  const { registerSerivceInfo  ,success,registerseriverLoading   ,error}=registerServiceReducer

  const updateService = useSelector(state => state.updateService)
  const { serviceUpdate ,serviceUpdateLoading  ,serviceSucces   ,serviceError}=updateService


  const seriveReducer = useSelector(state => state.seriveReducer)
  const { services ,loadingservices }=seriveReducer

  const ChartOfAccountReducer = useSelector(state => state. ChartOfAccountReducer)
  const {loadingchartOfAccount ,chartOfAccountsu ,chartOfAccount}= ChartOfAccountReducer


    const [service_code ,setservice_code]=useState()
    const [service_name ,setservice_name]=useState()
    const [Charges_ind ,setCharges_ind]=useState()
    const [Charges_sme ,setCharges_sme]=useState()
    const [Charges_corpor ,setCharges_corpor]=useState()
    const [Service_type ,setService_type]=useState()

    const[saveUpdate ,setsaveUpdate]=useState(true)

    const [account ,setaccount]=useState('Select')
    const[acc_code ,setacc_code]=useState()
    const [acc_type ,setacc_type]=useState()
    const[acc_statement ,setacc_statement]=useState()

    const createNewServiceFunctionOpen=() =>{
      
      setservice_code('')
        setservice_name('')
        setCharges_ind('')
        setCharges_sme('')
        setCharges_corpor('')
        setService_type('')

        setaccount('Select')
        setacc_code('')
        setacc_type('')
        setacc_statement('')
        

      setCreatNewPService(true)
      setsaveUpdate(true)
    }

    const createNewServiceFunctionClose=() =>{
        setCreatNewPService(false)
      }


      const successMessage=(message) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message ,
          showConfirmButton: false,
          timer: 1500
        });
      }
    
    
      const successError=(message) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: message ,
          showConfirmButton: false,
          timer: 1500000
          
        
        });
    
      }


    const dispatch=useDispatch()

    const saveService=(e) =>{
        e.preventDefault()

        if(saveUpdate){

          dispatch(registerservice(service_code ,service_name , Charges_ind ,Charges_corpor ,Service_type ,account , acc_code , acc_type , acc_statement))

        }else {
  
          dispatch(updateSerivceAction({service_code:service_code ,
            service_name ,
            Charges_ind ,
            Charges_corpor ,
            Service_type ,
            account ,
            acc_code,
            acc_type,
            acc_statement
          }))


        }
       
    }

    useEffect(()=>{

      if(success){

        successMessage('Product Service Successfully Saved')
        setservice_code('')
        setservice_name('')
        setCharges_ind('')
        setCharges_sme('')
        setCharges_corpor('')
        setService_type('')

      setaccount('Select')
      setacc_code('')
      setacc_type('')
      setacc_statement('')


        dispatch(serviceList())

      }

      if(error){

        successError('Service Registration Error.Pleace Check The Service Code')

      }



    },[registerServiceReducer])


    useEffect(()=>{

      if(serviceSucces){

        successMessage('Product Service Successfully Updated')

        dispatch(serviceList())

        dispatch({type:SERVICE_RESET_UPDATE})
      }

    },[updateService])

    const getServiceDetails =(service_code) => {

      try {

        const getservice=services.filter(ser=>{
          return ser.service_code==(service_code)  
      })

      createNewServiceFunctionOpen()

      setservice_code(getservice[0].service_code)
      setservice_name(getservice[0].service_name)
      setCharges_ind(getservice[0].Charges_ind)
      setCharges_sme(getservice[0].Charges_sme)
      setCharges_corpor(getservice[0].Charges_corpor)
      setService_type(getservice[0].Service_type)
      setaccount(getservice[0].account)
      setacc_code(getservice[0].acc_code)
      setacc_type(getservice[0].acc_type)
      setacc_statement(getservice[0].acc_statement)

      setsaveUpdate(false)
        
      } catch (error) {
        
      }

    }

    const mapAccount=(e)=>{

      const selectedAccount=chartOfAccount.find(obj=>obj.name==e.target.value)
      console.log(selectedAccount)
  
      setaccount(selectedAccount.name)
   
     setacc_code(selectedAccount.acc_code)
     setacc_type(selectedAccount.acc_type)
     setacc_statement(selectedAccount.acc_statement)
  
    }
   
  return (
    <div>

        <h3>Product Services</h3>

                    
        <hr/>

        <button  onClick={createNewServiceFunctionOpen} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Service </button>


        <div className='solcon overflow-auto vh-100'>

        {loadingservices && <Loader/>}
        {registerseriverLoading && <Loader/>}
        {serviceUpdateLoading && <Loader/>}

        
        <div className='bra row me-2'>

                {services.map(service=>(

                <div className='col-md-4 mb-4'>
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">{service.service_name}</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Service Code : {service.service_code}</li>
                            <li className="list-group-item"> Service Type: {service.Service_type} Charge Service</li>
                            
                            
                        </ul>

                        </div>
                        <button onClick={()=>getServiceDetails(service.service_code)}   className='show_info_btn'>View Product Details</button>
                    </div>
                    </div>

                                
                ))}

                </div>
                
        </div>
      
    <Modal size='lg' show={creatNewPService} onHide={createNewServiceFunctionClose} backdrop='static' keyboard={false}>

        <Modal.Header  closeButton>
                <Modal.Title>Service Infomation</Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <form onSubmit={saveService}>

              <div className='row'>

                <div className='col-md-4'>
                <div className='form-group'>
                    <label className='lable' for='service code'><samp className='text-danger'>* </samp>Service Code</label>
                    <input type='number' value={service_code} onChange={(e)=>setservice_code(e.target.value)} required className='form-control' name='service code'/>
                  
                </div>
                </div>
                
              
                <div className='col-md-4'>
                <div className='form-group'>

                <label className='lable' for='service name'><samp className='text-danger'>* </samp>Service Name</label>
                <input type='type' value={service_name} onChange={(e)=>setservice_name(e.target.value)} required className='form-control' name='service Name'/>

                </div>
                </div>

                <div className='col-md-4'>

                <div className='form-group '>
                                <label className='lable' for='product Name'><samp className='text-danger'>* </samp> Map Accounts</label>
                                
                                    <select required  className='form-control'onChange={mapAccount} value={account}  >
                                            <option required disabled selected value="Select" >Select</option>
                                            {chartOfAccount.map((chart)=>(
                                                <option value={chart.name}>{chart.name}</option>
                                            ))} 
                                                
                                        </select>
                                </div>

                </div>
                
                </div>

                <div className='card mt-4'>
                 <div className='card-body'>
                  <div className='mb-3'><strong>Service Charge</strong></div>
                    <div className='row'>
                      <div className='col-md-6'>

                      <div className='form-group'>
                  
                        <label className='lable' for='Individual Account'><samp className='text-danger'>* </samp>Individual Account</label>
                        <input type='number' value={Charges_ind} onChange={(e)=>setCharges_ind(e.target.value)} required className='form-control' name='Individual Account'/>
                
                     </div>

                      </div>

                      
                      <div className='col-md-6'>
                      <div className='form-group'>
                  
                          <label className='lable' for='SME Account'><samp className='text-danger'>* </samp>Corporate Account</label>
                          <input type='number' value={Charges_corpor} onChange={(e)=>setCharges_corpor(e.target.value)} required className='form-control' name='SME Account'/>
                  
                      </div>
                      </div>

                    </div>

                 </div>
                </div>

                <div className='card mt-4'>
                        <div className='card-body'>
                          <div className='mt-3'><strong><samp className='text-danger'>* </samp> Service Type </strong></div>
                          
                          <div className='row mt-3'>
                            <div className='col-md-6'>

                            <div className='form-group'>
                  
                                <div class="form-check">
                                  <input class="form-check-input"  type="radio" name="Auto"  value='Auto' checked={Service_type =='Auto'}
                                  onChange={(e)=>setService_type(e.target.value)}/>
                                  <label className='lable' class="form-check-label" for="Auto">Automatic Charge Service (Per Month)</label>
                                </div>
                        
                            </div>

                            </div>


                            <div className='col-md-6'>

                            <div class="form-check">
                                  <input class="form-check-input" type="radio" name="Auto" id="Auto" value='On Demand' checked={Service_type =='On Demand'}
                                  onChange={(e)=>setService_type(e.target.value)}/>
                                  <label className='lable' class="form-check-label" for="Auto">Service Charge On Demand</label>
                              </div>
                              
                            </div>
                          </div>

                        </div>
                      </div>


                      {
                        saveUpdate ? 
                        <button type='submit' className="show_info_btn mt-5">Save Service Information</button> 
                        :
                        <button type='submit' className="show_info_btn mt-5">Update Service Information</button>
                      }
                      

              </form>

            </Modal.Body>


    </Modal>




    </div>
  )
}

export default ChageServicer




