import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { logout } from '../actions/userAction'


const Logout = () => {
    const navigate =useNavigate()
    const dispatch=useDispatch()
  
    const logoutHandler = ()=>{
      dispatch(logout())
  
      navigate('/')
  }

  useEffect(()=>{
    logoutHandler()
  } ,[])
  
  return (
    <div>
      
    </div>
  )
}

export default Logout
