import { VAULT_SET_REQUEST ,VAULT_SET_SUCCESS ,VAULT_SET_FAIL ,

    VAULT_INFO_REQUEST ,VAULT_INFO_SUCCESS ,VAULT_INFO_FAIL ,

    VAULT_DETAILS_REQUEST ,VAULT_DETAILS_SUCCESS ,VAULT_DETAILS_FAIL ,

    VAULT_UPDATE_REQUEST,VAULT_UPDATE_SUCCESS ,VAULT_UPDATE_FAIL  ,

    ACTIVE_BRANCH_VAULT_REQUEST, ACTIVE_BRANCH_VAULT_SUCCESS ,ACTIVE_BRANCH_VAULT_FAIL ,

    VAULT_BALANCE_REQUEST ,VAULT_BALANCE_SUCCESS ,VAULT_BALANCE_FAIL ,

    USER_VAULT_REQUEST_SAVE ,USER_VAULT_BALANCE_SUCCESS_SAVE ,USER_VAULT_BALANCE_FAIL_SAVE ,

    TREASURE_TODAY_REQUEST ,TREASURE_TODAY_SUCCESS ,TREASURE_TODAY_FAIL  ,

    TREASURE_SETTING_REQUEST ,TREASURE_SETTING_SUCCESS ,TREASURE_SETTING_FAIL ,

    TREASURE_SETTING_REQUEST_LOAD ,TREASURE_SETTING_SUCCESS_LOAD ,TREASURE_SETTING_FAIL_LOAD ,

    VAULT_REQUEST_SAVE,VAULT_SUCCESS_SAVE ,VAULT_FAIL_SAVE  ,

    UPDATE_USER_VAULT_REQUEST , UPDATE_USER_VAULT_SUCCESS , UPDATE_USER_VAULT_FAIL  ,

    USER_VAULT_REQUEST ,USER_VAULT_SUCCESS ,USER_VAULT_FAIL 

} from "../constants/vaultConstants";

import axios from "axios";



export const SaveNewVault = (code , name , balance , dat ,dat2 , map_acc_code , map_acc_name , map_acc_statment , map_acc_type ,branch ,level ,save_by) => async(dispatch) =>{
   
    try {
 
       dispatch({type:VAULT_SET_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/saveVaultSetup/' , 
       {'code': code ,'name':name, 'balance':balance  ,'dat':dat ,'dat2':dat2 , 'map_acc_code':map_acc_code ,
          'map_acc_name':map_acc_name , 'map_acc_statment':map_acc_statment , 'map_acc_type':map_acc_type,'branch':branch , 'level':level ,'save_by':save_by} ,config)
 
       dispatch({type: VAULT_SET_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
    } catch (error) {
 
       dispatch({type:VAULT_SET_FAIL    , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }

  export const LoadVaultInfo= () =>async(dispatch) =>{

    try {

        dispatch({type:VAULT_INFO_REQUEST})

        const {data} = await axios.get('/api/getAllVault/')

        dispatch({type:VAULT_INFO_SUCCESS , payload:data})

        //localStorage.setItem('MemberInfo' ,JSON.stringify(data))

        
    } catch (error) {

        dispatch({
            type : VAULT_INFO_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
        
        
    }

}

export const allUserVault= () =>async(dispatch) =>{

   try {

       dispatch({type:VAULT_INFO_REQUEST})

       const {data} = await axios.get('/api/VaultInfor/')

       dispatch({type:VAULT_INFO_SUCCESS , payload:data})

       //localStorage.setItem('MemberInfo' ,JSON.stringify(data))

       
   } catch (error) {

       dispatch({
           type : VAULT_INFO_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
       
   }

}


export const UserVault= (user) =>async(dispatch) =>{

   try {

       dispatch({type:VAULT_INFO_REQUEST})

       const {data} = await axios.get(`/api/userUnaprovedVault/?user=${user}`)

       dispatch({type:VAULT_INFO_SUCCESS , payload:data})

       //localStorage.setItem('MemberInfo' ,JSON.stringify(data))

       
   } catch (error) {

       dispatch({
           type : VAULT_INFO_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
       
   }

}

export const getVaultDetailsAction=(code)=>async(dispatch)=>{
    try {

        dispatch({type:VAULT_DETAILS_REQUEST})

    
        const {data} = await axios.get(`api/getVaultAccountDetails/${code}`)

        dispatch({type:VAULT_DETAILS_SUCCESS , payload:data})

       // localStorage.setItem('bankInfo' , JSON.stringify(data))
        
    } catch (error) {
        dispatch({
            type : VAULT_DETAILS_FAIL , 
            payload : error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    
    })
    }

}

export const vaultUpdateActions =(vault_setup) => async (dispatch)=>{

    try {
 
       dispatch({type:VAULT_UPDATE_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/approveVaultSetUp/${vault_setup.code}/`,vault_setup ,config)
       
       dispatch({type:VAULT_UPDATE_SUCCESS , payload:data})
 
      
    } catch (error) {
 
       dispatch({type:VAULT_UPDATE_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }

export const branchValutAction =(branch ,save_aproval) => async (dispatch)=>{

    try {
 
       dispatch({type:ACTIVE_BRANCH_VAULT_REQUEST})
 
       const {data} = await axios.get(`api/load_active_branch_Vault/?branch=${branch}&save_aproval=${save_aproval}`)
 
       
       dispatch({type:ACTIVE_BRANCH_VAULT_SUCCESS , payload:data})
 
 
    } catch (error) {
 
       dispatch({type:ACTIVE_BRANCH_VAULT_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


  export const ValutBalanceAction =(vault_code) => async (dispatch)=>{

    try {
 
       dispatch({type:VAULT_BALANCE_REQUEST})
 
      

       const {data} = await axios.get(`api/vaultBalance/?vault_code=${vault_code}`)
 
       
       dispatch({type:VAULT_BALANCE_SUCCESS , payload:data})
 
 
    } catch (error) {
 
       dispatch({type:VAULT_BALANCE_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


  export const SaveUserVault = (vault_name,vault_code,dat,amount,cr,dr,branch,des,dat2,user,vault_taller
   ,n200,n100,n50,n20,n10,n5,n2,n1,c2,c1,c05,c02,c01,amount_word) => async(dispatch) =>{
   
   try {

      dispatch({type:USER_VAULT_REQUEST_SAVE})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveUserVault/' , 
      {'vault_name': vault_name ,'vault_code':vault_code, 'dat':dat  ,'amount':amount ,'cr':cr , 'dr':dr ,'branch':branch ,
         'des':des , 'dat2':dat2 , 'user':user, 'vault_taller':vault_taller ,'n200':n200, 'n100':n100 , 'n50':n50 ,'n20':n20 , 'n10':n10 ,'n5':n5 , 'n2':n2 , 'n1':n1 ,'c2':c2 ,'c1':c1 ,'c05':c05, 
         'c02':c02, 'c01':c01, 'amount_word':amount_word} ,config)

      dispatch({type: USER_VAULT_BALANCE_SUCCESS_SAVE , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:USER_VAULT_BALANCE_FAIL_SAVE    , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
 

 export const TreasureTodayAction =(dat2) => async (dispatch)=>{

   try {

      dispatch({type:TREASURE_TODAY_REQUEST})

     

      const {data} = await axios.get(`api/user_Vault_today/?dat2=${dat2}`)

      
      dispatch({type:TREASURE_TODAY_SUCCESS , payload:data})


      localStorage.setItem('TreasureToday' ,JSON.stringify(data))


   } catch (error) {

      dispatch({type:TREASURE_TODAY_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const SaveUserVaultSetting =(dat,dat2,user,loginstatus,email,deposit,withdrawal,maxdeposit,maxwithdrawal) => async(dispatch) =>{
   
   try {

      dispatch({type:TREASURE_SETTING_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveUserVault_Setting/' , 
      {'dat': dat ,'dat2':dat2, 'user':user  ,'loginstatus':loginstatus ,'email':email , 'deposit':deposit ,'withdrawal':withdrawal ,
         'maxdeposit':maxdeposit , 'maxwithdrawal':maxwithdrawal } ,config)

      dispatch({type: TREASURE_SETTING_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:TREASURE_SETTING_FAIL    , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
 
 export const TreasureTodayActionSetting =(dat2) => async (dispatch)=>{

   try {

      dispatch({type:TREASURE_SETTING_REQUEST_LOAD})

     

      const {data} = await axios.get(`api/user_Vault_today_Settiing/?dat2=${dat2}`)

      
      dispatch({type:TREASURE_SETTING_SUCCESS_LOAD , payload:data})


   } catch (error) {

      dispatch({type:TREASURE_SETTING_FAIL_LOAD , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }



 export const SaveUserTranactions =(dat,dat2,amount,cr,dr,branch,vault_code,vault_name,des,user) => async(dispatch) =>{
   
   try {

      dispatch({type:VAULT_REQUEST_SAVE})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveVault_transaction/' , 
      {'dat': dat ,'dat2':dat2, 'amount':amount  ,'cr':cr ,'dr':dr , 'branch':branch ,'vault_code':vault_code ,
         'vault_name':vault_name , 'des':des, 'user':user} ,config)

      dispatch({type: VAULT_SUCCESS_SAVE , payload: data})
      
      

   } catch (error) {

      dispatch({type:VAULT_FAIL_SAVE    , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const valutAprovalAction =(vault_setup) => async (dispatch)=>{

   try {

      dispatch({type:UPDATE_USER_VAULT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/AprovalUserTreasure/${vault_setup.trans_code}/`,vault_setup ,config)

      
      dispatch({type:UPDATE_USER_VAULT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:UPDATE_USER_VAULT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const userVaultBalanceAction =(dat2 ,user) => async (dispatch)=>{

   try {

      dispatch({type:USER_VAULT_REQUEST})

     
      const {data} = await axios.get(`api/User_vault_balances/?dat2=${dat2}&user=${user}`)

      
      dispatch({type:USER_VAULT_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:USER_VAULT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
 

 