import React  ,{useState ,useEffect}from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDispatch ,useSelector } from 'react-redux';
import { getCustomerDetailsAction } from '../actions/customerAccountAction';
import { getMemberDetailsAction } from '../actions/memberAction';
import { CiSearch } from "react-icons/ci";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CustomerAccountAutoServicesAction } from '../actions/customerAccountAction';
import Loader from '../Components/Loader';


function SearchByAccountNumber() {

let save_aproval="True"
const dispatch=useDispatch()

const CustomerAccountNumberReducer = useSelector(state => state.CustomerAccountNumberReducer)
const {accountNumber}=CustomerAccountNumberReducer

const CustomerAutoServiceReducer = useSelector(state => state.CustomerAutoServiceReducer)
const { autoServicesLoading ,autoServices }=CustomerAutoServiceReducer

const [showsearch ,setshowsearch]=useState(true)

const navigate = useNavigate();

const opensearch=()=>{
    setshowsearch(true)
  }
 
  const closersearch=()=>{
    //setshowsearch(false)
     navigate(-1)
     //dispatch({type:MEMBER_RESET_DETAILS})
  }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //console.log(string, results)
      }
    
      const handleOnHover = (result) => {
        // the item hovered
       // console.log(result)
      }
  
   
      const handleOnSelect = (accountNumber) => {
        // the item selected
      // console.log(accountNumber.account_number)
       try {
       
        dispatch(CustomerAccountAutoServicesAction(accountNumber.account_number))
        dispatch(getMemberDetailsAction(accountNumber.customer_id_id))
        dispatch(getCustomerDetailsAction(accountNumber.trans_code))
        
       // navigate(-1)
       } catch (error) {
        
       }
       

    

      }
    
      const handleOnFocus = () => {
        //console.log('Focused')
      }
  
      const formatResult = (accountNumber) => {
        return (
          <>

            <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.name} </span>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.account_name} </span>
            
          </>
        )
      }

      

  return (
    <div>


    
            <Modal fullscreen={true} show={showsearch} onHide={closersearch} backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
             <Modal.Title>Search Customer Account</Modal.Title>
              </Modal.Header>
               <Modal.Body className='d-flex justify-content-center' >


               {autoServicesLoading && <Loader/>}
                    
                <header className="App-header mb-3">
                    <div style={{ width: 600 }}>
                      <ReactSearchAutocomplete
                        items={accountNumber}
                      // fuseOptions={{ keys: ["trans_code", "tel"] }}
                      // resultStringKeyName="tel"
                      
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      placeholder='Search Account Number'
                      />
                    </div>
                  </header>
              
                  
              </Modal.Body>
                   
    </Modal>





    </div>



  )
}

export default SearchByAccountNumber
