export const CREATE_ACCOUNT_REQUEST='CREATE_ACCOUNT_REQUEST'
export const CREATE_ACCOUNT_SUCCESS='CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAIL='CREATE_ACCOUNT_FAIL'
export const CREATE_ACCOUNT_RESET='CREATE_ACCOUNT_RESET'


export const CUSTOMER_ACCOUNTS_REQUEST='CUSTOMER_ACCOUNTS_REQUEST'
export const CUSTOMER_ACCOUNTS_SUCCESS='CUSTOMER_ACCOUNTS_SUCCESS'
export const CUSTOMER_ACCOUNTS_FAIL='CUSTOMER_ACCOUNTS_FAIL'
export const CUSTOMER_ACCOUNTS_RESET='CUSTOMER_ACCOUNTS_RESET'



export const CUSTOMER_ACCOUNTS_REQUEST_DETAILS='CUSTOMER_ACCOUNTS_REQUEST_DETAILS'
export const CUSTOMER_ACCOUNTS_SUCCESS_DETAILS='CUSTOMER_ACCOUNTS_SUCCESS_DETAILS'
export const CUSTOMER_ACCOUNTS_FAIL_DETAILS='CUSTOMER_ACCOUNTS_FAIL_DETAILS'
export const CUSTOMER_ACCOUNTS_RESET_DETAILS='CUSTOMER_ACCOUNTS_RESET_DETAILS'



export const CUSTOMER_ACCOUNTS_TYPE_REQUEST_DETAILS='CUSTOMER_ACCOUNTS_TYPE_REQUEST_DETAILS'
export const CUSTOMER_ACCOUNTS_TYPE_SUCCESS_DETAILS='CUSTOMER_ACCOUNTS_TYPE_SUCCESS_DETAILS'
export const CUSTOMER_ACCOUNTS_TYPE_FAIL_DETAILS='CUSTOMER_ACCOUNTS_TYPE_FAIL_DETAILS'
export const CUSTOMER_ACCOUNTS_TYPE_RESET_DETAILS='CUSTOMER_ACCOUNTS_TYPE_RESET_DETAILS'


export const CUSTOMER_ACCOUNTS_NUMBER_REQUEST='CUSTOMER_ACCOUNTS_NUMBER_REQUEST'
export const CUSTOMER_ACCOUNTS_NUMBER_SUCCESS='CUSTOMER_ACCOUNTS_NUMBER_SUCCESS'
export const CUSTOMER_ACCOUNTS_NUMBER_FAIL='CUSTOMER_ACCOUNTS_NUMBER_FAIL'
export const CUSTOMER_ACCOUNTS_NUMBER_RESET='CUSTOMER_ACCOUNTS_NUMBER_RESET'


export const SAVE_AUTO_REQUEST='SAVE_AUTO_REQUEST'
export const SAVE_AUTO_SUCCESS='SAVE_AUTO_SUCCESS'
export const SAVE_AUTO_FAIL='SAVE_AUTO_FAIL'
export const SAVE_AUTO_RESET='SAVE_AUTO_RESET'


export const LOAD_AUTO_REQUEST='LOAD_AUTO_REQUEST'
export const LOAD_AUTO_SUCCESS='LOAD_AUTO_SUCCESS'
export const LOAD_AUTO_FAIL='LOAD_AUTO_FAIL'
export const LOAD_AUTO_RESET='LOAD_AUTO_RESET'


export const DEPOSIT_REQUEST='DEPOSIT_REQUEST'
export const DEPOSIT_SUCCESS='DEPOSIT_SUCCESS'
export const DEPOSIT_FAIL='DEPOSIT_FAIL'
export const DEPOSIT_RESET='DEPOSIT_RESET'


export const ACCOUNT_TRANSACTION_REQUEST='ACCOUNT_TRANSACTION_REQUEST'
export const ACCOUNT_TRANSACTION_SUCCESS='ACCOUNT_TRANSACTION_SUCCESS'
export const ACCOUNT_TRANSACTION_FAIL='ACCOUNT_TRANSACTION_FAIL'
export const ACCOUNT_TRANSACTION_RESET='ACCOUNT_TRANSACTION_RESET'


export const ACCOUNT_BALANCE_REQUEST='ACCOUNT_BALANCE_REQUEST'
export const ACCOUNT_BALANCE_SUCCESS='ACCOUNT_BALANCE_SUCCESS'
export const ACCOUNT_BALANCE_FAIL='ACCOUNT_BALANCE_FAIL'
export const ACCOUNT_BALANCE_RESET='ACCOUNT_BALANCE_RESET'



export const USER_DEPOSIT_TOTAL_REQUEST='USER_DEPOSIT_TOTAL_REQUEST'
export const USER_DEPOSIT_TOTAL_SUCCESS='USER_DEPOSIT_TOTAL_SUCCESS'
export const USER_DEPOSIT_TOTAL_FAIL='USER_DEPOSIT_TOTAL_FAIL'
export const USER_DEPOSIT_TOTAL_RESET='USER_DEPOSIT_TOTAL_RESET'


export const CREATE_CORACCOUNT_REQUEST='CREATE_CORACCOUNT_REQUEST'
export const CREATE_CORACCOUNT_SUCCESS='CREATE_CORACCOUNT_SUCCESS'
export const CREATE_CORACCOUNT_FAIL='CREATE_CORACCOUNT_FAIL'
export const CREATE_CORACCOUNT_RESET='CREATE_CORACCOUNT_RESET'


export const CREATE_SIGNATORY_REQUEST='CREATE_SIGNATORY_REQUEST'
export const CREATE_SIGNATORY_SUCCESS='CREATE_SIGNATORY_SUCCESS'
export const CREATE_SIGNATORY_FAIL='CREATE_SIGNATORY_FAIL'
export const CREATE_SIGNATORY_RESET='CREATE_SIGNATORY_RESET'


export const DETAILS_CORACCOUNT_REQUEST='DETAILS_CORACCOUNT_REQUEST'
export const DETAILS_CORACCOUNT_SUCCESS='DETAILS_CORACCOUNT_SUCCESS'
export const DETAILS_CORACCOUNT_FAIL='DETAILS_CORACCOUNT_FAIL'
export const DETAILS_CORACCOUNT_RESET='DETAILS_CORACCOUNT_RESET'


export const DETAILS_SIGNATORY_REQUEST='DETAILS_SIGNATORY_REQUEST'
export const DETAILS_SIGNATORY_SUCCESS='DETAILS_SIGNATORY_SUCCESS'
export const DETAILS_SIGNATORY_FAIL='DETAILS_SIGNATORY_FAIL'
export const DETAILS_SIGNATORY_RESET='DETAILS_SIGNATORY_RESET'


export const ACCOUNT_AUTO_SERVICE_REQUEST='ACCOUNT_AUTO_SERVICE_REQUEST'
export const ACCOUNT_AUTO_SERVICE_SUCCESS='ACCOUNT_AUTO_SERVICE_SUCCESS'
export const ACCOUNT_AUTO_SERVICE_FAIL='ACCOUNT_AUTO_SERVICE_FAIL'
export const ACCOUNT_AUTO_SERVICE_RESET='ACCOUNT_AUTO_SERVICE_RESET'


export const ACCOUNT_HISTORY_REQUEST='ACCOUNT_HISTORY_REQUEST'
export const ACCOUNT_HISTORY_SUCCESS='ACCOUNT_HISTORY_SUCCESS'
export const ACCOUNT_HISTORY_FAIL='ACCOUNT_HISTORY_FAIL'
export const ACCOUNT_HISTORY_RESET='ACCOUNT_HISTORY_RESET'







