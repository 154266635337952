import { APPROVAL_REQUEST ,APPROVAL_SUCCESS ,APPROVAL_FAIL ,APPROVAL_REST ,
    APPROVAL_MEMBER_SAVE_REQUEST,APPROVAL_MEMBER_SAVE_SUCCESS,APPROVAL_MEMBER_SAVE_FAIL,APPROVAL_MEMBER_SAVE_FAIL_REST ,
    APPROVAL_SAVE_REQUEST ,APPROVAL_SAVE_SUCCESS ,APPROVAL_SAVE_FAIL,APPROVAL_SAVE_REST,

    APPROVAL_SENT_REQUEST ,APPROVAL_SENT_SUCCESS ,APPROVAL_SENT_FAIL ,APPROVAL_SENT_REST ,

    APPROVAL_MESSAGE_SENT_REQUEST ,APPROVAL_MESSAGE_SENT_SUCCESS ,APPROVAL_MESSAGE_SENT_FAIL ,APPROVAL_MESSAGE_SENT_REST ,

    APPROVAL_MESSAGE_SAVE_REQUEST ,APPROVAL_MESSAGE_SAVE_SUCCESS ,APPROVAL_MESSAGE_SAVE_FAIL ,APPROVAL_MESSAGE_SAVE_REST ,

    USER_MESSAGE_REQUEST ,USER_MESSAGE_SUCCESS ,USER_MESSAGE_FAIL ,USER_MESSAGE_REST
} from "../constants/approvalConstants";



export const user_Message_Reducer=(state={usermessage:{}},action)=>{

    switch(action.type){
        case USER_MESSAGE_REQUEST :
            return{usermessageLoading:true  }

        case USER_MESSAGE_SUCCESS :
            return{usermessageLoading:false , success:true , usermessage:action.payload}  

        case USER_MESSAGE_FAIL :
            return {usermessageLoading:false , error:action.payload}

        case USER_MESSAGE_REST:
            return{usermessage:{}}
        
        default:
            return state

    }

}

export const apro_Message_Reducer=(state={message:{}},action)=>{

    switch(action.type){
        case APPROVAL_MESSAGE_SENT_REQUEST :
            return{aproMessageLoading:true  }

        case APPROVAL_MESSAGE_SENT_SUCCESS :
            return{aproMessageLoading:false , success:true , message:action.payload}  

        case APPROVAL_MESSAGE_SENT_FAIL :
            return {aproMessageLoading:false , error:action.payload}

        case APPROVAL_MESSAGE_SENT_REST:
            return{message:{}}
        
        default:
            return state

    }

}


export const save_Message_Reducer=(state={messagesave:{}},action)=>{

    switch(action.type){
        case APPROVAL_MESSAGE_SAVE_REQUEST :
            return{messagesaveLoading:true  }

        case APPROVAL_MESSAGE_SAVE_SUCCESS :
            return{messagesaveLoading:false , savesuccess:true , messagesave:action.payload}  

        case APPROVAL_MESSAGE_SAVE_FAIL :
            return {messagesaveLoading:false , error:action.payload}

        case APPROVAL_MESSAGE_SAVE_REST:
            return{messagesave:{}}
        
        default:
            return state

    }

}

export const approvalReducer=(state={loadApprovals:[]},action)=>{

    switch(action.type){
        case APPROVAL_REQUEST :
            return{ApprovalsLoading:true }

        case APPROVAL_SUCCESS :
            return{ApprovalsLoading:false , loadApprovals:action.payload}  

        case APPROVAL_FAIL :
            return {ApprovalsLoading:false , error:action.payload}

        case APPROVAL_REST:
            return{loadApprovals:{}}
        
        default:
            return state

    }

}  


export const approveMemberSaveReducer=(state={},action)=>{

    switch(action.type){
        case APPROVAL_MEMBER_SAVE_REQUEST :
            return{approvMemberSaveLoading:true }

        case APPROVAL_MEMBER_SAVE_SUCCESS :
            return{approvMemberSaveLoading:false , approvMemberSave:action.payload}  

        case APPROVAL_MEMBER_SAVE_FAIL :
            return {approvMemberSaveLoading:false , error:action.payload}

        case APPROVAL_MEMBER_SAVE_FAIL_REST:
            return{approvMemberSave:{}}
        
        default:
            return state

    }

}  



export const memberApproveReducer=(state={memberApprove:{}},action)=>{

    switch(action.type){
        case APPROVAL_SAVE_REQUEST :
            return{memberApproveLoading:true }

        case APPROVAL_SAVE_SUCCESS :
            return{memberApproveLoading:false , memberApprove:action.payload}  

        case APPROVAL_SAVE_FAIL :
            return {memberApproveLoading:false , error:action.payload}

        case APPROVAL_SAVE_REST:
            return{memberApprove:{}}
        
        default:
            return state

    }

}  



export const approvalsReducer=(state={},action)=>{

    switch(action.type){
        case APPROVAL_SENT_REQUEST :
            return{approvalRequestLoading:true }

        case APPROVAL_SENT_SUCCESS :
            return{approvalRequestLoading:false , approvalsu:true, approvalRequest:action.payload}  

        case APPROVAL_SENT_FAIL :
            return {approvalRequestLoading:false , error:action.payload}

        case APPROVAL_SENT_REST:
            return{approvalRequest:{}}
        
        default:
            return state

    }

}  