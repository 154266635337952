import React from 'react'

function Investment() {
  return (
    <div>


<h3>Investments Products</h3>

                    
<hr/>

<button className='btn-class py-2 px-4 mt-2 mb-3'>Create New Investments </button>


<div className='solcon overflow-auto vh-100'>

        
    
</div>
      
    </div>
  )
}

export default Investment
