import { APPROVAL_REQUEST ,APPROVAL_SUCCESS ,APPROVAL_FAIL ,
    APPROVAL_MEMBER_SAVE_REQUEST,APPROVAL_MEMBER_SAVE_SUCCESS,APPROVAL_MEMBER_SAVE_FAIL ,
    APPROVAL_SAVE_REQUEST ,APPROVAL_SAVE_SUCCESS ,APPROVAL_SAVE_FAIL,

    APPROVAL_SENT_REQUEST ,APPROVAL_SENT_SUCCESS ,APPROVAL_SENT_FAIL  ,

    APPROVAL_MESSAGE_SENT_REQUEST ,APPROVAL_MESSAGE_SENT_SUCCESS ,APPROVAL_MESSAGE_SENT_FAIL  ,

    APPROVAL_MESSAGE_SAVE_REQUEST ,APPROVAL_MESSAGE_SAVE_SUCCESS ,APPROVAL_MESSAGE_SAVE_FAIL  ,

    USER_MESSAGE_REQUEST ,USER_MESSAGE_SUCCESS ,USER_MESSAGE_FAIL 

 } from "../constants/approvalConstants";

 import {  SAVE_AUTO_REQUEST,SAVE_AUTO_SUCCESS ,SAVE_AUTO_FAIL }  from "../constants/customerAccountConstants";


import axios from 'axios'


export const UserMessageListAction= (approval_by ,approval_status) =>async(dispatch) =>{

   try {

       dispatch({type:USER_MESSAGE_REQUEST})

       const {data} = await axios.get(`/api/user_message/?approval_by=${approval_by}&approval_status=${approval_status}`)

       dispatch({type:USER_MESSAGE_SUCCESS , payload:data})

       //localStorage.setItem('MemberInfo' ,JSON.stringify(data))

   } catch (error) {

       dispatch({
           type : USER_MESSAGE_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
       
   }

}

export const messageListAction= (sent_by) =>async(dispatch) =>{

   try {

       dispatch({type:APPROVAL_MESSAGE_SENT_REQUEST})

       const {data} = await axios.get(`/api/apro_message/?sent_by=${sent_by}`)

       dispatch({type:APPROVAL_MESSAGE_SENT_SUCCESS , payload:data})

       //localStorage.setItem('MemberInfo' ,JSON.stringify(data))

   } catch (error) {

       dispatch({
           type : APPROVAL_MESSAGE_SENT_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
       
       
   }

}

export const approvalUserMessActions =(message) => async (dispatch)=>{

   try {

      dispatch({type:APPROVAL_MESSAGE_SAVE_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/AprovalUsermess/${message.trans_code}/`,message ,config)

      
      dispatch({type:APPROVAL_MESSAGE_SAVE_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:APPROVAL_MESSAGE_SAVE_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


export const aprMessageAction = (table,action,sent_by,message,table_pk,pic,dat,dat2,title,message_sum,approval_by,acc_ty,account_name,account_number ,tel ,email,other_pk) => async(dispatch) =>{
   try {
      dispatch({type:APPROVAL_MESSAGE_SAVE_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveapro_message/' , 
      {'table':table,'action':action ,'sent_by':sent_by,'message':message,'table_pk':table_pk,'pic':pic,'dat':dat ,'dat2':dat2,'title':title,'message_sum':message_sum,
         'approval_by':approval_by ,'acc_ty':acc_ty , 'account_name':account_name , 'account_number':account_number ,'tel':tel ,'email':email ,'other_pk':other_pk} ,config)

      dispatch({type: APPROVAL_MESSAGE_SAVE_SUCCESS , payload: data})
   

   } catch (error) {

      dispatch({type:APPROVAL_MESSAGE_SAVE_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

export const AloadApprovalAction =(approval_status) => async (dispatch)=>{

    try {
 
       dispatch({type:APPROVAL_REQUEST})
 
      
       const {data} = await axios.get(`api/getPendingApprovals/?approval_status=${approval_status}`)
 
       
       dispatch({type:APPROVAL_SUCCESS , payload:data})
 
       localStorage.setItem('loadApprovals' ,JSON.stringify(data))
    
 
    } catch (error) {
 
       dispatch({type:APPROVAL_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


  export const approveMemberActions =(member) => async (dispatch)=>{

    try {
 
       dispatch({type:APPROVAL_MEMBER_SAVE_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/approveMemberSave/${member.trans_code}/`,member ,config)
 
       
       dispatch({type:APPROVAL_MEMBER_SAVE_SUCCESS , payload:data})
 
      
    } catch (error) {
 
       dispatch({type:APPROVAL_MEMBER_SAVE_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }
 


  export const approveSaveActions =(approval) => async (dispatch)=>{

    try {
 
       dispatch({type:APPROVAL_SAVE_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/approve/${approval.trans_code}/`,approval ,config)
 
       
       dispatch({type:APPROVAL_SAVE_SUCCESS , payload:data})
 
      
    } catch (error) {
 
       dispatch({type:APPROVAL_SAVE_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }
 


  export const approvalAction = (table,level,action,sent_by,narration,table_pk,branch) => async(dispatch) =>{
    try {
       dispatch({type:APPROVAL_SENT_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 
 
       const { data } = await axios.post('api/approvals/' , 
       {'table':table,'level':level ,'action':action,'sent_by':sent_by,'narration':narration,'table_pk':table_pk,'branch':branch },config)
 
       dispatch({type: APPROVAL_SENT_SUCCESS , payload: data})
       
       //localStorage.setItem('userInfo' ,JSON.stringify(data))
 
    } catch (error) {
 
       dispatch({type:APPROVAL_SENT_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


  export const approveAutoServicerActions =(service) => async (dispatch)=>{

   try {

      dispatch({type:SAVE_AUTO_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/approveDigitalAccountSave/${service.trans_code}/`,service ,config)

      
      dispatch({type:SAVE_AUTO_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:SAVE_AUTO_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }



 export const StopAutoServicerActions =(service) => async (dispatch)=>{

   try {

      dispatch({type:SAVE_AUTO_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.delete(`api/delete_customer_service/${service.trans_code}/`,service ,config)

      
      dispatch({type:SAVE_AUTO_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:SAVE_AUTO_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const deleteEditSigInfo =(editSig) => async (dispatch)=>{

   try {

      dispatch({type:SAVE_AUTO_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.delete(`api/delete_Edit_Account_Signatory/${editSig.trans_code}/`,editSig ,config)

      
      dispatch({type:SAVE_AUTO_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:SAVE_AUTO_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
