import React ,{useState ,useEffect} from 'react'
import {branchValutAction } from '../actions/vaultAction';
import { useDispatch ,useSelector } from 'react-redux';
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2'
import moment from 'moment'
import { ValutBalanceAction ,SaveUserVault ,TreasureTodayAction ,SaveUserVaultSetting ,SaveUserTranactions,valutAprovalAction,allUserVault} from '../actions/vaultAction';
import { FcCheckmark } from "react-icons/fc";
import Multiselect from 'multiselect-react-dropdown';
import { USER_VAULT_BALANCE_RESET_SAVE ,VAULT_RESET_SAVE } from '../constants/vaultConstants';
import { FcCancel } from "react-icons/fc";
import DataTable from 'react-data-table-component'
import { MdEdit } from "react-icons/md";
import { cash ,cashGH ,numberFormat} from './NumberFormat';
import { TreasureTodayActionSetting } from '../actions/vaultAction';
import { Modal } from 'react-bootstrap';
import { FcHighPriority } from "react-icons/fc";
import { GiMoneyStack } from "react-icons/gi";
import CurrencyInput from 'react-currency-input-field';
import { ToWords } from 'to-words'


function Vault() {

  const userProfileReducer = useSelector(state => state.userProfileReducer)
  const { userProfileInfo }=userProfileReducer

  let branch=userProfileInfo.branch
  let save_aproval ='True'

  let dat2 =moment().format('YYYY-MM-DD')

  const [showVbala ,setshowVbala]=useState(false)

  const [showUnapproved ,setshowUnapproved]=useState(false)

  const [outDatedTr ,setoutDatedTr]=useState([])

  const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: 'Ghana Cedis',
        plural: 'Ghana Cedis',
        symbol: '₹',
        fractionalUnit: {
          name: 'Pesewas',
          plural: 'Pesewas',
          symbol: '',
        },
      },
    },
  })


  const cloaseUnaprove=()=>{
    setshowUnapproved(false)
  }

  const handleCloseModalshowVbala=()=>{setshowVbala(false)}
    const handleOpenModalshowVbala=()=>{
      setgetdat(dat2) 
      setshowVbala(true)
    }

  const dispatch=useDispatch()
  const [name ,setname]=useState('')
  const [loginstatus ,setloginstatus]=useState()
  const [deposit ,setdeposit]=useState('Yes')
  const [withdrawal ,setWithdrawal]=useState('Yes')
  const [maxwithdrawal ,setmaxwithdrawal]=useState(0.00)
  const [maxdeposit ,setmaxdeposit]=useState(0.00)
  const [userbranch ,setuserbranch]=useState(userProfileInfo.branch)
  const[email ,setemail]=useState()
  const [amount ,setamount]=useState(0)
  const [vaultamount ,setvaultamount]=useState(0.00)

  const [n200 ,setn200]=useState('')
  const [t200 ,sett200]=useState(0)

  const [n100 ,setn100]=useState('')
  const [t100 ,sett100]=useState(0)
 
  const [n50 ,setn50]=useState('')
  const [t50 ,sett50]=useState(0)

  const [n20 ,setn20]=useState('')
  const [t20 ,sett20]=useState(0)

  const [n10 ,setn10]=useState('')
  const [t10 ,sett10]=useState(0)

  const [n5 ,setn5]=useState('')
  const [t5 ,sett5]=useState(0)

  const [n2 ,setn2]=useState('')
  const [t2 ,sett2]=useState(0)

  const [n1 ,setn1]=useState('')
  const [t1 ,sett1]=useState(0)



  const [c2 ,setc2]=useState('')
  const [tc2 ,settc2]=useState(0)

  const [c1 ,setc1]=useState('')
  const [tc1 ,settc1]=useState(0)

  const [c05 ,setc05]=useState('')
  const [tc05 ,settc05]=useState(0)

  const [c02 ,set02]=useState('')
  const [tcc02 ,setc02]=useState(0)

  const [c01 ,setc01]=useState('')
  const [tcc01 ,settcc01]=useState(0)

  const [notetotal ,setnotetotal]=useState(0)

  const [Coinstotal ,setCoinstotal]=useState(0)

  const [runtotal , setruntotal]=useState(false)

  const [gradeotal , setgradeotal]=useState(0)

  const [amountToWord ,setamountToWord]=useState('') 
  
 
  const totals =()=>{

    var note_total=(Number(t200) + Number(t100) +Number(t50) + Number(t20) + Number(t10) + Number(t5) +Number(t2) + Number(t1))
    setnotetotal(note_total)

    var Coins_total=(Number(tc2) + Number(tc1) +Number(tc05) + Number(tcc02) + Number(tcc01) )
    setCoinstotal(Coins_total)

    setruntotal(false)


    setgradeotal(Number(note_total) + Number(Coins_total) )

    setamount(Number(note_total) + Number(Coins_total))

  }

 useEffect(()=>{

  if(runtotal){
    totals()
  }
  

 },[runtotal])


 useEffect(()=>{

  let words = toWords.convert(gradeotal, { currency: true });
  
    setamountToWord(words)


 },[gradeotal])

 
  const branchVaultReducer = useSelector(state => state.branchVaultReducer)
  const { vaultLbranchActiveVaultoading ,branchActiveVault}=branchVaultReducer


  const VaultBalanceReducer = useSelector(state => state.VaultBalanceReducer)
  const { VaultBalance , VaultBalanceLoading}=VaultBalanceReducer


  const ALLUserReducer = useSelector(state => state. ALLUserReducer)
    const {AllUser ,UserLoading ,UserSuccess}= ALLUserReducer


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

    const createUserVaultReducer = useSelector(state => state.createUserVaultReducer)
    const { creatUserValutLoading,Uservaultsu }=createUserVaultReducer


    const TreasureTodayReducer = useSelector(state => state.TreasureTodayReducer)
    const { TreasureToday,TreasureTodayLoading }=TreasureTodayReducer

  
    const TreasureTodaySettingReducer = useSelector(state => state.TreasureTodaySettingReducer)
    const { TreasureSettingToday,TreasureTodaySettingLoading }=TreasureTodaySettingReducer


    const vaultReducer = useSelector(state => state.vaultReducer)
    const { vaultLoading,vault }=vaultReducer


    const saveVaultTransactionReducer = useSelector(state => state.saveVaultTransactionReducer)
    const { saveVaultTransactionLoading,saveVault }=saveVaultTransactionReducer


    const [getdat ,setgetdat]=useState(dat2)

    const [getdatTr ,setgetdatTr]=useState(TreasureToday)

    const [show ,setShow]=useState(false)

    const [vaultUser ,setvaultUser]=useState()
    const [vaultdeposit ,setvaultdeposit]=useState()
    const [vaultwithdrawal ,setvaultwithdrawal]=useState()
    const [vaultmaxdeposit ,setvaulmaxdeposit]=useState()
    const [vaulmaxwithdrawal ,setvaulmaxwithdrawal]=useState()
    const [vaultUseramount ,setvaultUseramount]=useState()

    const [activateTreasuer ,setActivateTreasuer]=useState()

    const handleCloseModal = () =>{

      setShow(false)
      setgetSaveDe(false)
      clear()
    } 


    const handleShowmModal = () =>{
      setShow(true)
    } 


    const [showsearch ,setshowsearch]=useState(false)
    
    const denomination=()=>{
        setshowsearch(true)
      }
     
      const closersearch=()=>{
        setshowsearch(false)
       
       
      } 

    const columns =[
      {
       name:'Id' ,
       selector:row=>row.trans_code ,
       width: "70px" 
       ,wrap:true 
  
      } ,

       {
        name:'User' ,
        selector:row=>row.user,
        width: "130px" 
        ,wrap:true
       },

       {
        name:'Treasure Amount',
        selector:row=> cash(row.dr) ,
        width: "150px" 
        ,wrap:true ,
        sortable:true
       } ,

       {
        name:'Status' , cell:row=> <button className='btt'>  {row.save_aproval ? <FcCheckmark className='preview-btn'/>: <FcCancel className='preview-btn'/>}  </button> ,
          
        width:"70px"
       } ,

       {
        name:'Edit' , cell:row=> <button onClick={()=>getsetting(row)} className='btt'><MdEdit  className='preview-btn'/></button> ,
          
        width:"70px"
       } ,
  ]
  

  const userinfor=(selected_data ,selectItem)=>{

    //console.log(selected_data[0].username)

    setname(selected_data[0].username)
    setloginstatus(selected_data[0].loginstatus)
    setemail(selected_data[0].email)
    //console.log(selected_data[0].name)

    setActivateTreasuer(TreasureToday.filter(obj=>obj.user==selected_data[0].username && obj.status==1))


  }

  
const successMessage=(message) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: message ,
    showConfirmButton: false,
    timer: 10000
  });
}


const ErrorMessage=(message) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: message ,
    showConfirmButton: false,
    timer: 1500000
    
  
  });

}

useEffect(()=>{

  if(Uservaultsu){

    successMessage('Vault Treasure Out Information Successfully Saved');
    
    dispatch(TreasureTodayAction(dat2))
    dispatch(TreasureTodayActionSetting(dat2))

    dispatch({type:USER_VAULT_BALANCE_RESET_SAVE})
  }

},[createUserVaultReducer])

  useEffect(()=>{

    dispatch(branchValutAction(branch , save_aproval))
    dispatch(TreasureTodayAction(dat2))
    dispatch(TreasureTodayActionSetting(dat2))
    dispatch(allUserVault())

  },[dispatch ])


  useEffect(()=>{

    const todayTr=TreasureToday.filter(obj=>obj.dat2==getdat)
 
    setgetdatTr(todayTr)
    
   
    if(saveVault){

      successMessage('User Treasure Balances Approved')

      dispatch({type:VAULT_RESET_SAVE})
    }
   

    

  },[saveVaultTransactionReducer ,TreasureTodayReducer])


  useEffect(()=>{

    const uaproveVault=vault.filter(obj=>obj.save_aproval==1 && obj.dat2!=dat2 &&  (obj.close_status==0 || obj.aprove_status==0 ))

    setoutDatedTr(uaproveVault)

    if(uaproveVault.length >0){

      setshowUnapproved(true)
     
    }else {
      setshowUnapproved(false)
    }

    console.log(uaproveVault)

  },[vaultReducer ,createUserVaultReducer])


  const onChangeDat=(e)=>{
    
    setgetdat(e.target.value)
    dispatch(TreasureTodayAction(e.target.value))
   
  }

 
  const [getSaveDe ,setgetSaveDe]=useState(false)

  const getsetting=(row)=>{

    console.log(row)

    setgetSaveDe(true)

    const getUserSettings=TreasureSettingToday.find(obj=>obj.user==row.user)

    //console.log(getUserSettings)

    setvaultUser(row.user)
    setvaultdeposit(getUserSettings.deposit)
    setvaultwithdrawal(getUserSettings.withdrawal)
    setvaulmaxdeposit(getUserSettings.maxdeposit)
    setvaulmaxwithdrawal(getUserSettings.maxwithdrawal)
    setvaultUseramount(row.dr)


    setn200(row.n200)
    sett200((Number(row.n200) * 200))

    setn100(row.n100)
    sett100((Number(row.n100) * 100))

    setn50(row.n50)
    sett50((Number(row.n50) * 50))

    setn20(row.n20)
    sett20((Number(row.n20) * 20))

    setn10(row.n10)
    sett10((Number(row.n10) * 10))

    setn5(row.n5)
    sett5((Number(row.n5) * 5))

    setn2(row.n2)
    sett2((Number(row.n2) * 2))

    setn1(row.n1)
    sett1((Number(row.n1) * 1))

    setc2(row.c2)
    settc2((Number(row.c2) * 2))

    setc1(row.c1)
    settc1((Number(row.c1) * 1))

    setc05(row.c05)
    settc05((Number(row.c05) * 0.5))

    set02(row.c02)
    setc02((Number(row.c02) * 0.2))

    setc01(row.c01)
    settcc01((Number(row.c01) * 0.1))

    setruntotal(true)

    handleShowmModal()

  }

  const vaultAccount=(e)=>{

    const selectVault=branchActiveVault.filter(obj =>obj.name==e.target.value)

    setvaultamount(selectVault[0].balance)

    dispatch(ValutBalanceAction(selectVault[0].code))
  }

  const saveUserVault=()=>{

    //setvaultamount(VaultBalance[0].vault_balance)

   // console.log(activateTreasuer)

   try {

    if(activateTreasuer.length==0){

      let vamoun=Number(vaultamount)
      let eamount=Number(amount)
  
      if(VaultBalance.length <=0){
  
        ErrorMessage('No vault Informatin Found')
  
      }else if(eamount > vamoun){
  
        ErrorMessage('Please Check Vault Account Balance')
  
      }else if(name==''){
  
        ErrorMessage('No User Information Found Select New Treasure Out Users')
  
      }else if(amount==''){
  
        ErrorMessage('Enter Treasure Out Amount')
  
      }else if(deposit=='No' && withdrawal=='No'){
  
        ErrorMessage('Treasure Out Deposit And Withdrawal Permission Can Not Be Both No')
  
      }else if(Number(maxdeposit) <=0 && Number(maxwithdrawal) <=0){
  
        ErrorMessage('Treasure Out Both maximum Deposit And Withdrawal Amount Without Authorization Can Not Be 0')
  
  
      }else {
  
  
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
          confirmButton: "btn btn-success mx-4",
          cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
          title: "Confirm",
          text: "Are You Sure You Want to Save Treasure Out Information?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
       
    
      }).then((result) => {
    
          if (result.isConfirmed) {
            
        let vault_name=VaultBalance[0].vault_name
        let vault_code=VaultBalance[0].vault_code
  
        let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
        
        let cr=0
        let dr = amount
        let des="Treasure Out Amount Approval "
        let user=name
        let vault_taller=userInfo.username
        let save_aproval =false
  
        dispatch(SaveUserVault(vault_name , vault_code ,dat ,amount ,cr,dr, branch,des,dat2 ,user ,vault_taller,n200 , n100 ,n50 ,n20 ,n10 ,n5 ,n2 ,n1, c2 , c1 ,c05 ,c02 , c01 ,amountToWord))
  
        dispatch(SaveUserVaultSetting(dat ,dat2, user ,loginstatus ,email ,deposit ,withdrawal ,maxdeposit ,maxwithdrawal))
  
       clear()
  
          } else if (result.dismiss === Swal.DismissReason.cancel) {
    
         
          }
          
      });
  
  
        
      }
  
     }else {
  
      ErrorMessage('User Already Has an active Treasure Balance')
  
  
     }
    
   } catch (error) {
    
   }
    
   



  }

  const clear =()=>{

    
    setname('')
    setloginstatus('')
    setemail('')

  setn200('')
  sett200(0)

  setn100('')
  sett100(0)
 
  setn50('')
  sett50(0)

  setn20('')
  sett20(0)

  setn10('')
  sett10(0)

  setn5('')
  sett5(0)

  setn2('')
  sett2(0)

  setn1('')
  sett1(0)




  setc2()
  settc2(0)

  setc1()
  settc1(0)

  setc05()
  settc05(0)

  set02()
  setc02(0)

  setc01()
  settcc01(0)

  setnotetotal(0)

  setCoinstotal(0)

  setruntotal(false)

  setgradeotal(0)

 setamountToWord('') 

 setamount(0)


  }


  const treasuresetting=()=>{

  }


  const aproval_Treasure_bal=(row)=>{

    try {

      let vault_taller=userInfo.username
    
      
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Confirm",
      text: "Are You Sure You Want to Approve The Selected User Treasure Balances?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
   

  }).then((result) => {

      if (result.isConfirmed) {

        save_aproval=1
        let close_bala=row.close_bala
        let close_status=1
        let deposit=row.deposit
        let withdrawal=row.withdrawal
        let aprove_status=1
        let status=0
        let cr = close_bala

        dispatch(SaveUserTranactions(row.dat ,row.dat2 , row.amount , 0.00 ,row.close_bala  ,row.branch ,row.vault_code ,row.vault_name ,'Treasure In Amount Approval' ,vault_taller))

       

        dispatch(valutAprovalAction({trans_code:row.trans_code ,
          save_aproval,
          close_bala,
          close_status,
          deposit,
          withdrawal,
          aprove_status,
          status,
          cr

        }))
        
        dispatch(TreasureTodayAction(row.dat2))
        dispatch(branchValutAction(branch , save_aproval))
        dispatch(TreasureTodayAction(row.dat2))
        dispatch(TreasureTodayActionSetting(row.dat2))
        dispatch(allUserVault())
      
      } else if (result.dismiss === Swal.DismissReason.cancel) {

     
      }
      
  });

      
    } catch (error) {
      
    }

  }


  return (
    <div>

              
        <h3>Vault Account & Management </h3>

                            
        <hr/>

        <div className='solcon overflow-auto vh-100'>
          <div className='container'>

          {vaultLbranchActiveVaultoading && <Loader/>}
          {VaultBalanceLoading && <Loader/>}
          {UserLoading && <Loader/>}
          {creatUserValutLoading && <Loader/>}
          {TreasureTodayLoading && <Loader/>}
          {TreasureTodaySettingLoading && <Loader/>}
          {vaultLoading && <Loader/>}
          {saveVaultTransactionLoading && <Loader/>}

          

          <div className='row table_buttom_margin'>

            <div className='col-md-5'> 

                 
                    <div className='card mb-3'>

                        <div className='card-header userbal d-flex flex-column align-items-center'>Select Vault Information</div>
                        <div className='section profile'>

                        <div className="card">
                        <div className="card-body profile-card pt-3 d-flex flex-column">
                        <div className='form-group '>
                          <label  for='product Name'> Select Vault</label>
                                                      
                          <select required  className='form-control'onChange={vaultAccount}   >
                          <option required disabled selected value="Select" >Select</option>
                            {branchActiveVault.map((va)=>(
                            <option value={va.name}>{va.name}</option>
                            ))} 
                                                                      
                              </select>
                            </div>
                            </div>

                          <div className="card-body profile-card pt-3 d-flex flex-column align-items-center">

                            <p className='pt-2'>Vault Code: { VaultBalance.length <=0 ? 'xxxxxxxxxxxxx' :VaultBalance[0].vault_code}</p>
                            <p className='pt-2'>Vault Name: {VaultBalance.length <=0 ? 'xxxxxxxxxxxxx':VaultBalance[0].vault_name}</p>
                            <p className='pt-2'>Branch: {VaultBalance.length <=0 ? 'xxxxxxxxxxxxx' :VaultBalance[0].branch}</p>
                            <p className='pt-2'>Vault Balance:{VaultBalance.length <=0 ? 'xxxxxxxxxxxxx' :cashGH(VaultBalance[0].vault_balance)}</p>

                        
                          </div>
                        </div>

                        
                        </div>

                       
                      <button onClick={handleOpenModalshowVbala}  className="btn-class m-2">User Treasure Closing Balances</button>
                      
                      

                    </div>

                    <div className='card'>
                    <div className='card-header userbal d-flex flex-column align-items-center'>Vault Treasure Out Information Today</div>

                    <div className='card-body'>

                    <DataTable className='rdt_Table' columns={columns}  data={TreasureToday=='' ? TreasureToday : TreasureToday}   
                      selectableRowsHighlight 
                      highlightOnHover
                        fixedHeader
                        pagination 
                          paginationPerPage={2}
                          //onSelectedRowsChange={getSelectedSubjects}
                        // selectableRows  
                          //selectableRowSelected={getPatientInfo} 
                          //</div> onRowClicked={getPatientInfo}
                          responsive={true}
                          >
          
                      </DataTable>
                    </div>

                    </div>

            </div>

            <div className='col-md-7'>

            <div className='card'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Vault Treasure Out Information</div>

              <div className='row'>
                
              <div className='card-body ms-4 me-4'>
              <h5 className='mt-2'>Select Treasure Out Users</h5>
             
              <Multiselect
                          displayValue="username"
                          onKeyPressFn={function noRefCheck(){}}
                          disablePreSelectedValues
                          onSearch={function noRefCheck(){}}
                          onSelect={userinfor}
                          options={AllUser}
                          //selectedValues={autoServices}
                          //ref={multiselectRefTracker}
                          singleSelect='true'
                          />

                </div>
                <div className='col-md-6'>
                <div className='card-header mt-3'><FcCheckmark size={25}/> User Information</div>

                <div className="card-body profile-card pt-3  d-flex flex-column align-items-center"> 
                  
                    <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp> User Name</label>
                          <input name="Name" disabled required type="text" className="form-control" value={name} />
                      </div>

                      <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp> Loginstatus</label>
                          <input name="Name" disabled required type="text" className="form-control" value={loginstatus}/>
                      </div>

                      <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>User Email</label>
                          <input name="Name" disabled required type="text" className="form-control" value={email}/>
                      </div>

                      <div className="col-md-12 col-lg-12">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Branch</label>
                          <input name="Name" disabled required type="text" className="form-control" value={userbranch}/>
                      </div>

                </div>
                </div>
                

                <div className='col-md-6'>
                <div className='card-header mt-3'><FcCheckmark size={25}/> Deposit/Withdrawal Information Access</div>
                <div className="card-body profile-card pt-3  d-flex flex-column align-items-center"> 
                    
                    <div className="col-md-12 col-lg-12 mb-3">
                          <lable className='lable' for='Login Status'>Deposit</lable>
                                <select required  className='form-control' value={deposit}  onChange={(e)=>setdeposit(e.target.value)}  >
                                   <option selected value="Select" >Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                                    
                                </select>
                    </div>

                    <div className="col-md-12 col-lg-12 mb-3">
                          <lable className='lable' for='Login Status'>Withdrawal</lable>
                                <select required className='form-control' value={withdrawal}  onChange={(e)=>setWithdrawal(e.target.value)}  >
                                   <option selected value="Select" >Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                                    
                                </select>
                    </div>


                    <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'>maximum Deposit Without Authorization </label>
                          <input name="Name" required type="number" className="form-control" value={maxdeposit} onChange={(e)=>setmaxdeposit(e.target.value)} />
                      </div>


                      <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'>maximum Withdrawal Without Authorization </label>
                          <input name="Name" required type="number" className="form-control" value={maxwithdrawal} onChange={(e)=>setmaxwithdrawal(e.target.value)} />
                      </div>

                </div>
                </div>

                <div className='col-md-6'>
                <div className='card-header'><FcCheckmark size={25}/> Treasure Out Amount / Amount In Words</div>
                <div className="card-body profile-card pt-3  d-flex flex-column "> 
                
                
                <div className="col-md-12 col-lg-12 mb-2">
                    <label className='lable' for='Surname'>Grand Total GH₵ </label>
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={amount}
                    
                    />
                  
                   </div>

                   <div className="col-md-12 col-lg-12 mb-2">
                          <label className='lable' for='Surname'>Amount In Words</label>
                          <input value={amountToWord} name="Name"  required  className="form-control"  />
                   </div>

                </div>
                </div>

                <div className='col-md-6'>
                <div className='card-header'><GiMoneyStack size={25}/> Treasure Out Denomination </div>
                <div className="card-body profile-card pt-3  d-flex flex-column "> 
                
                
                

                <button onClick={denomination} className="show_info_btn mt-5">Treasure Denomination</button>

                </div>
                </div>
                
              </div>

             


              <button onClick={saveUserVault} className="btn-class">Save Treasure Out Information</button>
            </div>
            </div>

          </div>
              

              

            </div>
        </div>


        <Modal size="lg" show={show} onHide={handleCloseModal}  backdrop="static" keyboard={false}>                                                                                                                                 
                    <Modal.Header closeButton>
                    <Modal.Title>User Treasure Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                    <div className='row mb-3'>
                    <div className="col-md-6 col-lg-6">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp> User Name</label>
                          <input name="Name" disabled required type="text" className="form-control" value={vaultUser} />
                      </div>


                      <div className="col-md-3 col-lg-3 ">
                          <label className='lable' for='Surname'><samp className='text-danger'>* </samp> Treasure Amount</label>
                          <input name="Name" disabled required type="text" className="form-control" value={vaultUseramount} />
                      </div>

                     
                      <div className='col-md-3'>
                          <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Treasure Denomination</label>
                          <button onClick={denomination} className="form-control"><GiMoneyStack  className='preview-btn' /></button>  
                                                  
                      </div>
                    </div>



                      <div className="col-md-12 col-lg-12 mb-3">
                          <lable className='lable' for='Login Status'>Deposit</lable>
                                <select required  className='form-control' value={vaultdeposit}  onChange={(e)=>setvaultdeposit(e.target.value)}  >
                                   <option selected value="Select" >Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                                    
                                </select>
                    </div>

                    <div className="col-md-12 col-lg-12 mb-3">
                          <lable className='lable' for='Login Status'>Withdrawal</lable>
                                <select required className='form-control' value={vaultwithdrawal}  onChange={(e)=>setvaultwithdrawal(e.target.value)}  >
                                   <option selected value="Select" >Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                                    
                                </select>
                    </div>

                  
                    <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'>maximum Deposit Without Authorization </label>
                          <input name="Name" required type="number" className="form-control" value={vaultmaxdeposit} onChange={(e)=>setvaulmaxdeposit(e.target.value)} />
                      </div>


                      <div className="col-md-12 col-lg-12 mb-3">
                          <label className='lable' for='Surname'>maximum Withdrawal Without Authorization </label>
                          <input name="Name" required type="number" className="form-control" value={vaulmaxwithdrawal} onChange={(e)=>setvaulmaxwithdrawal(e.target.value)} />
                      </div>


                      <button onClick={treasuresetting} className="show_info_btn">Save Treasure Settings</button>

                      
                       
                    </Modal.Body>
                   
        </Modal>




    <Modal show={showVbala} onHide={handleCloseModalshowVbala} dialogClassName="my-modal"  backdrop="static" keyboard={false}>                                                                                                                                 
        
        <Modal.Header closeButton>
        <Modal.Title>User Treasure Balances </Modal.Title>
        </Modal.Header>
        <Modal.Body >
       

            <div className='row me-2'>


            <div className='col-md-9'>
              <div className='row'>

              {getdatTr.map(Treasure=>(

            <div className='col-md-4 mb-4'>
                <div className="card">
                    <div className="card-body">
                    <strong className="card-title">{Treasure.dat}</strong>
                    <ul className="list-group">
                        <li className="list-group-item"><i></i> User : {Treasure.user}</li>
                        <li className="list-group-item"><i ></i> Vault: {Treasure.vault_name}</li>
                        <li className="list-group-item"><i ></i> Treasure Out: {cash(Treasure.amount)}</li>
                        <li className="list-group-item"><i ></i> Deposit: {cash(Treasure.deposit)}</li>
                        <li className="list-group-item"><i ></i>  Withdrawal: {cash(Treasure.withdrawal)}</li>
                        <li className="list-group-item"><i ></i> Balance: {cash(Treasure.close_bala)}</li>
                        {Treasure.save_aproval==1 ?
                          
                          <strong className="list-group-item"><i ></i><FcCheckmark size={20}/> Treasure Out Approved </strong>

                          : 

                          Treasure.save_aproval==0 && Treasure.dat2 ==dat2 ?
                        
                          <strong className="list-group-item"><i ></i><FcHighPriority size={20}/> Treasure Out Pending </strong>

                          :

                          <strong className="list-group-item"><i ></i><FcHighPriority size={20}/> Treasure Out Expired </strong>
                          }




                          {Treasure.aprove_status==1 ?
                          
                          <strong className="list-group-item"><i ></i><FcCheckmark size={20}/> Treasure In Approved </strong>
                            :

                            ''
                          }

                    </ul>

                    </div>
                    
                    {

                      Treasure.close_status==1 && Treasure.aprove_status==0 ?  <button onClick={()=>aproval_Treasure_bal(Treasure)} className='show_info_btn'>Approv User Balance </button>
                      :
                      ''
                    }
                    
                </div>
              
            </div>

                            
            ))}

              </div>
              

            </div>

            <div className='col-md-3'>

            <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Search Treasure Balances </h5>
                      <div className="col-md-12 col-lg-12">
                        <label className='lable' for='Surname'>Date</label>
                        <input name="number"  type="date" className="form-control" value={getdat} onChange={onChangeDat}/>
                      </div>
                                            
                    </div>
            </div>
            </div>

            </div>

        </Modal.Body>
                   
    </Modal>



    <Modal size="lg" show={showUnapproved} onHide={cloaseUnaprove} dialogClassName="my-modal-small"  backdrop="static" keyboard={false}>                                                                                                                                 
      <Modal.Header closeButton>
      <Modal.Title>Unapproved Treasure Out Amount </Modal.Title>
      </Modal.Header>
      <Modal.Body >

      
      <div className='row me-2'>


        <div className='col-md-12'>
          <div className='row'>

          {outDatedTr.map(Treasure=>(

        <div className='col-md-4 mb-4'>
            <div className="card">
                <div className="card-body">
                <strong className="card-title">{Treasure.dat}</strong>
                <ul className="list-group">
                    <li className="list-group-item"><i></i> User : {Treasure.user}</li>
                    <li className="list-group-item"><i ></i> Vault: {Treasure.vault_name}</li>
                    <li className="list-group-item"><i ></i> Treasure Out: {cash(Treasure.amount)}</li>
                    <li className="list-group-item"><i ></i> Deposit: {cash(Treasure.deposit)}</li>
                    <li className="list-group-item"><i ></i>  Withdrawal: {cash(Treasure.withdrawal)}</li>
                    <li className="list-group-item"><i ></i> Balance: {cash(Treasure.close_bala)}</li>
                   
                   
                      
                      <strong className="list-group-item"><i ></i><FcCheckmark size={20}/> Treasure Out  </strong>

                      {

                      Treasure.close_status==0 ?
                    
                      <strong className="list-group-item"><i ></i><FcHighPriority size={20}/> Treasure Out Closing Balances Pending... </strong>

                      :

                     
                      <strong className="list-group-item"><i ></i><FcCheckmark size={20}/> Treasure Out Closing Balances Approved </strong>

                     
                      }

                </ul>

                </div>
                
                {

                  Treasure.close_status==1 &&  <button onClick={()=>aproval_Treasure_bal(Treasure)} className='show_info_btn'>Approv User Balance </button>
                  
                }
                
            </div>
          
        </div>

                        
        ))}

          </div>
          

        </div>

        

        </div>

        

        
          
      </Modal.Body>
                   
    </Modal>




    <Modal size="lg" show={showsearch} onHide={closersearch} dialogClassName="my-modal-small"  backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
            <Modal.Title>Denomination</Modal.Title>
            </Modal.Header>
            <Modal.Body  >

            <div className='card'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Treasure Out</div>

              <div className='card-body ms-4 me-4'>
              <div className="card">
              <div className="card-body">
                <div className='row'>

                <div className='col-md-6 col-lg-6'>

                <div className='row'>
                  <div className='col-md-3 col-lg-3'>
                  
                      <h5 className="card-title  text-center ">Note</h5>
                      <hr/>
                      <input disabled name="number"  type="number" className="form-control  text-center" value={200} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={100} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={50} />
                      <input disabled  name="number"  type="number" className="form-control text-center" value={20}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={10}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={5}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={2}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={1}/>

                  </div>
                  <div className='col-md-5 col-lg-5'>
                  <h5 className="card-title text-center">Pieces </h5>
                  <hr/>
                      {getSaveDe ? <input  value={n200}  name="number"  type="number" className="form-control text-center" />
                      : 
                      <input  value={n200}  onChange={(e)=>{
                         setn200(e.target.value)
                         if(e.target.value==''){
                          sett200(0)
                          
                         // setn200(0)
                        }else {

                          sett200((Number(e.target.value) * 200))
                         
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }

                      {getSaveDe ? <input  value={n100}  name="number"  type="number" className="form-control text-center" />
                      :
                     <input value={n100} onChange={(e)=>{
                         setn100(e.target.value)
                         if(e.target.value==''){
                          sett100(0)
                         // setn200(0)
                        }else {

                          sett100((Number(e.target.value) * 100))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }
                       {getSaveDe ? <input  value={n50}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n50} onChange={(e)=>{
                         setn50(e.target.value)
                         if(e.target.value==''){
                          sett50(0)
                         // setn200(0)
                        }else {

                          sett50((Number(e.target.value) * 50))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />

                    }
                      {getSaveDe ? <input  value={n20}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n20} onChange={(e)=>{
                         setn20(e.target.value)
                         if(e.target.value==''){
                          sett20(0)
                         // setn200(0)
                        }else {

                          sett20((Number(e.target.value) * 20))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                      {getSaveDe ? <input  value={n10}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n10} onChange={(e)=>{
                         setn10(e.target.value)
                         if(e.target.value==''){
                          sett10(0)
                         // setn200(0)
                        }else {

                          sett10((Number(e.target.value) * 10))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }
                       {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n5} onChange={(e)=>{
                         setn5(e.target.value)
                         if(e.target.value==''){
                          sett5(0)
                         // setn200(0)
                        }else {

                          sett5((Number(e.target.value) * 5))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                      {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n2} onChange={(e)=>{
                         setn2(e.target.value)
                         if(e.target.value==''){
                          sett2(0)
                         // setn200(0)
                        }else {

                          sett2((Number(e.target.value) * 2))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                    {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                    <input value={n1} onChange={(e)=>{
                         setn1(e.target.value)
                         if(e.target.value==''){
                          sett1(0)
                         // setn200(0)
                        }else {

                          sett1((Number(e.target.value) * 1))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />

                    }

                  </div>
                  <div className='col-md-4 col-lg-4'>
                  <h5 className="card-title text-center">Total</h5>
                  <hr/>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    fixedDecimalLength={2}
                    className='form-control text-center'
                    value={t200}
                    decimalScale={2}
                    />
                    
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t100}
                    decimalScale={2}
                    />
                    
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t50}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t20}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t10}
                    decimalScale={2}
                    />
                     <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t5}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t2}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t1}
                    decimalScale={2}
                    />

                  </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-md-6'>
                    <h5 className="card-title text-center">Note Total GH₵:</h5>
                    </div>

                    <div className='col-md-6'>
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={notetotal}
                    decimalScale={2}
                    />
                    </div>
                  </div>
                  </div>
                  
                  <div className='col-md-6 col-lg-6'>

                  <div className='row'>
                  <div className='col-md-3 col-lg-3'>
                  
                      <h5 className="card-title text-center">Coins</h5>
                      <hr/>
                      <input disabled name="number"  type="number" className="form-control  text-center" value={2} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={1} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={0.5} />
                      <input disabled  name="number"  type="number" className="form-control text-center" value={0.2}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={0.1}/>
                     
                      
                      
                     

                  </div>
                  <div className='col-md-5 col-lg-5'>
                  <h5 className="card-title text-center">Pieces</h5>
                  <hr/>
                  {getSaveDe ? <input  value={c2}  name="number"  type="number" className="form-control text-center" />
                    :
                  <input value={c2} onChange={(e)=>{
                         setc2(e.target.value)
                         if(e.target.value==''){
                          settc2(0)
                          
                         // setn200(0)
                        }else {

                          settc2((Number(e.target.value) * 2))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }
                      {getSaveDe ? <input  value={c1}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c1} onChange={(e)=>{
                         setc1(e.target.value)
                         if(e.target.value==''){
                          settc1(0)
                          
                         // setn200(0)
                        }else {

                          settc1((Number(e.target.value) * 1))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>

                    }
                     {getSaveDe ? <input  value={c05}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c05} onChange={(e)=>{
                         setc05(e.target.value)
                         if(e.target.value==''){
                          settc05(0)
                          
                         // setn200(0)
                        }else {

                          settc05((Number(e.target.value) * 0.5))
                         
                        
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }

                    {getSaveDe ? <input  value={c02}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c02} onChange={(e)=>{
                         set02(e.target.value)
                         if(e.target.value==''){
                          setc02(0)
                          
                         // setn200(0)
                        }else {

                          setc02((Number(e.target.value) * 0.2))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>

                    }

                    {getSaveDe ? <input  value={c01}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c01} onChange={(e)=>{
                         setc01(e.target.value)
                         if(e.target.value==''){
                          settcc01(0)
                          
                         // setn200(0)
                        }else {

                          settcc01((Number(e.target.value) * 0.1))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }
                  </div>
                  <div className='col-md-4 col-lg-4'>
                  <h5 className="card-title text-center ">Total</h5>
                  <hr/>
                  
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc2}
                    decimalScale={2}
                    />
                 
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc1}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc05}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tcc02}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tcc01}
                    decimalScale={2}

                    />



                  </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-md-6'>
                    <h5 className="card-title text-center">Coins Total GH₵:</h5>
                    </div>

                    <div className='col-md-6'>

                    <CurrencyInput
                    
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    fixedDecimalLength={2}
                    value={Coinstotal}
                    decimalScale={2}
                    />
                    </div>
                  </div>

                  <div className='mt-5'>
                    
                  </div>
                  </div>
                  
                </div> 
                </div>
                <hr/>
                <div className='row mb-3'>
                    <div className='col-md-3'>
                    <h5 className="card-title text-center">Grand Total GH₵:</h5>
                    </div>

                    <div className='col-md-2'>

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={gradeotal}
                    decimalScale={2}
                    
                    />
                    </div>


                    <div className='col-md-5'>

                   
                    <input value={amountToWord} name="Name" required type="text" className="form-control text-center" />
                    </div>


                    {getSaveDe ? '' : 
                    <div className='col-md-2'>
                    <button  onClick={closersearch} className="show_info_btn">OK</button>
                    </div>
                    }
                  </div>

                 


                      

                </div>

              </div>

              </div>
                
            
            </Modal.Body>
                        
        </Modal> 




  </div>
  )
}

export default Vault
