import { USER_LOGIN_REQUEST 
       , USER_LOGIN_SUCCESS 
       ,USER_LOGIN_FAIL 


       ,USER_LOGOUT ,

       USER_REGISTER_REQUEST ,
       USER_REGISTER_SUCCESS,
       USER_REGISTER_FAIL ,

       USER_DETAILS_REQUEST,
       USER_DETAILS_SUCCESS,
       USER_DETAILS_FAIL ,
       USER_DETAILS_RESET,

       USER_UPDATE_PROFILE_REQUEST,
       USER_UPDATE_PROFILE_SUCCESS,
       USER_UPDATE_PROFILE_FAIL ,
       USER_UPDATE_PROFILE_RESET , 

       USER_REQUEST,USER_SUCCESS,USER_FAIL,USER_RESET ,

       USER_REQUEST_MENU ,USER_SUCCESS_MENU,USER_FAIL_MENU ,USER_RESET_MENU ,

       SELETED_USER_DETAIL_REQUEST ,SELETED_USER_DETAIL_SUCCESS ,SELETED_USER_DETAIL_FAIL ,SELETED_USER_DETAIL_RESET ,

       USER_REQUEST_MENU_ITEMS ,USER_SUCCESS_MENU_ITEMS , USER_FAIL_MENU_ITEMS ,USER_RESET_MENU_ITEMS ,

       USER_REQUEST_MENU_ITEMS_ADMIN  ,USER_SUCCESS_MENU_ITEMS_ADMIN  ,USER_FAIL_MENU_ITEMS_ADMIN  ,USER_RESET_MENU_ITEMS_ADMIN ,

       USER_REQUEST_MENU_ITEMS_DELETE ,USER_SUCCESS_MENU_ITEMS_DELETE ,USER_FAIL_MENU_ITEMS_DELETE ,USER_RESET_MENU_ITEMS_DELETE ,

       USER_REQUEST_UDATE_BRANNCH_LEVEL,USER_SUCCESS_UDATE_BRANNCH_LEVEL,USER_FAIL_UDATE_BRANNCH_LEVEL,USER_RESET_UDATE_BRANNCH_LEVEL ,

       SELETED_PROFILE_DETAIL_REQUEST , SELETED_PROFILE_DETAIL_SUCCESS , SELETED_PROFILE_DETAIL_FAIL , SELETED_PROFILE_DETAIL_RESET


     

    } from "../constants/userConstants";

    //----------------------------------------------------------Login

    export const userLoginReducer = (state = {userInfo:{}} , action) => {

        switch (action.type) {

            case USER_LOGIN_REQUEST:
                return { loading: true  ,loginSucces:false};
            
            case USER_LOGIN_SUCCESS:
                return { loading: false ,userInfo: action.payload ,loginSucces:true};
            
            case USER_LOGIN_FAIL:
                return { loading: false , error:action.payload };

            case USER_LOGOUT:
                return {}

            default:
                return state

        }

    }



//---------------------------------------------------------------Register
export const userRegisterReducer = (state = {} ,action) =>{

    switch(action.type) {
        case USER_REGISTER_REQUEST:
            return{loading: true}
        
        case USER_REGISTER_SUCCESS:
            return{loading: false , userInfo: action.payload}

        case USER_REGISTER_FAIL:
            return{loading: false, error: action.payload}

        case USER_LOGOUT:
            return{}
        
        default :
            return state
    }


} 


//------------------------------------------------------User profiles

export const userDetailsReducer = (state = {user:{}} ,action) =>{

    switch(action.type){

        case USER_DETAILS_REQUEST :
            return{ ...state , loading: true}

        case USER_DETAILS_SUCCESS:
            return {loading:false ,user:action.payload}

        case USER_DETAILS_FAIL:
            return {loading:false , error:action.payload}

        case USER_DETAILS_RESET:
            return {user:{}}

        
        default:
            return state

        
    }

}

//-------------------------------------------------Update User Profile

export const userUpdateProfileReducer = (state ={} ,action) =>{

    switch(action.type){

        case USER_UPDATE_PROFILE_REQUEST:
            return {loading :true }

        case USER_UPDATE_PROFILE_SUCCESS:
            return {loading : false , success:true , userInfo:action.payload}

        case USER_UPDATE_PROFILE_FAIL:
            return {loading : false , error:action.payload}

        case USER_UPDATE_PROFILE_RESET:
            return {}

        default:
            return state
    }

}


//--------------------------------------------LOAD ALL USER

export const ALLUserReducer=(state={AllUser:[]},action)=>{

    switch(action.type){
        case USER_REQUEST :
            return{UserLoading:true  }

        case USER_SUCCESS :
            return{UserLoading:false , UserSuccess:true , AllUser :action.payload}  

        case USER_FAIL:
            return {UserLoading:false , UserError:action.payload}

        case USER_RESET:
            return{AllUser:{}}
        
        default:
            return state

    }

}


//---------------------------------------------------------------Register
export const userMenuReducer = (state = {} ,action) =>{

    switch(action.type) {
        case USER_REQUEST_MENU:
            return{userMenuloading: true}
        
        case USER_SUCCESS_MENU:
            return{userMenuloading: false , registereduserMenuItem: action.payload}

        case USER_FAIL_MENU:
            return{userMenuloading: false, error: action.payload}

        case USER_RESET_MENU:
            return{}
        
        default :
            return state
    }


} 


export const SelectedUserInfo = (state = {SelectedUserDetails:{}} , action) => {

    switch (action.type) {

        case SELETED_USER_DETAIL_REQUEST:
            return { SelectedUserInfoloading: true  ,loginSucces:false};
        
        case SELETED_USER_DETAIL_SUCCESS:
            return { SelectedUserInfoloading: false ,SelectedUserDetails: action.payload};
        
        case SELETED_USER_DETAIL_FAIL:
            return { SelectedUserInfoloading: false , error:action.payload };

        case SELETED_USER_DETAIL_RESET:
            return {}

        default:
            return state

    }

}

export const userMenuItems = (state = {MenuItems:{}} , action) => {

    switch (action.type) {

        case USER_REQUEST_MENU_ITEMS:
            return { MenuItemsloading: true  ,MenuItems:false};
        
        case USER_SUCCESS_MENU_ITEMS:
            return { MenuItemsloading: false ,MenuItems: action.payload };
        
        case USER_FAIL_MENU_ITEMS:
            return { MenuItemsloading: false , error:action.payload };

        case USER_RESET_MENU_ITEMS:
            return {}

        default:
            return state

    }

}


export const userMenuItemsAdmin = (state = {MenuItemsAdmin:{}} , action) => {

    switch (action.type) {

        case USER_REQUEST_MENU_ITEMS_ADMIN :
            return { MenuItemsAdminloading: true  ,MenuItemsAdmin:false};
        
        case USER_SUCCESS_MENU_ITEMS_ADMIN :
            return { MenuItemsAdminloading: false ,MenuItemsAdmin: action.payload };
        
        case USER_FAIL_MENU_ITEMS_ADMIN :
            return {MenuItemsAdminloading: false , error:action.payload };

        case USER_RESET_MENU_ITEMS_ADMIN :
            return {}

        default:
            return state

    }

}


export const deleteUserMenuItem =(state = {} ,action)=>{
    switch(action.type){
        case USER_REQUEST_MENU_ITEMS_DELETE:
            return{deleted_Menu_Itemloading:true,}
        
        case USER_SUCCESS_MENU_ITEMS_DELETE:
            return{deleted_Menu_Itemloading:false , deleted_Menu_Itemtem:action.payload} 
        
        case USER_FAIL_MENU_ITEMS_DELETE:
            return{deleted_Menu_Itemloading:false , error:action.payload}

            case USER_RESET_MENU_ITEMS_DELETE :
                return {}

        default:
            return state
    }

    
}



export const userUpdateBranchLevelReducer = (state ={} ,action) =>{

    switch(action.type){

        case USER_REQUEST_UDATE_BRANNCH_LEVEL:
            return {loading_update_branch_level :true }

        case USER_SUCCESS_UDATE_BRANNCH_LEVEL:
            return {loading_update_branch_level : false ,  userBranchLevel:action.payload}

        case USER_FAIL_UDATE_BRANNCH_LEVEL:
            return {loading_update_branch_level : false , error:action.payload}

        case USER_RESET_UDATE_BRANNCH_LEVEL:
            return {}

        default:
            return state
    }

}


export const SelectedProfileInfo = (state = {SelectedProfileDetails:{}} , action) => {

    switch (action.type) {

        case SELETED_PROFILE_DETAIL_REQUEST:
            return { SelectedProfileInfoloading: true };
        
        case SELETED_PROFILE_DETAIL_SUCCESS:
            return { SelectedProfileInfoloading: false ,SelectedProfileDetails: action.payload};
        
        case SELETED_PROFILE_DETAIL_FAIL:
            return { SelectedProfileInfoloading: false , error:action.payload };

        case SELETED_PROFILE_DETAIL_RESET:
            return {}

        default:
            return state

    }

}