export const VAULT_SET_REQUEST='VAULT_SET_REQUEST'
export const VAULT_SET_SUCCESS='VAULT_SET_SUCCESS'
export const VAULT_SET_FAIL='VAULT_SET_FAIL'
export const VAULT_SET_RESET='VAULT_SET_RESET'


export const VAULT_INFO_REQUEST='VAULT_INFO_REQUEST'
export const VAULT_INFO_SUCCESS='VAULT_INFO_SUCCESS'
export const VAULT_INFO_FAIL='VAULT_INFO_FAIL'
export const VAULT_INFO_RESET='VAULT_INFO_RESET'


export const VAULT_DETAILS_REQUEST='VAULT_DETAILS_REQUEST'
export const VAULT_DETAILS_SUCCESS='VAULT_DETAILS_SUCCESS'
export const VAULT_DETAILS_FAIL='VAULT_DETAILS_FAIL'
export const VAULT_DETAILS_RESET='VAULT_INFO_RESET'


export const VAULT_UPDATE_REQUEST='VAULT_UPDATE_REQUEST'
export const VAULT_UPDATE_SUCCESS='VAULT_UPDATE_SUCCESS'
export const VAULT_UPDATE_FAIL='VAULT_UPDATE_FAIL'
export const VAULT_UPDATE_RESET='VAULT_UPDATE_RESET'


export const ACTIVE_BRANCH_VAULT_REQUEST='ACTIVE_BRANCH_VAULT_REQUEST'
export const ACTIVE_BRANCH_VAULT_SUCCESS='ACTIVE_BRANCH_VAULT_SUCCESS'
export const ACTIVE_BRANCH_VAULT_FAIL='ACTIVE_BRANCH_VAULT_FAIL'
export const ACTIVE_BRANCH_VAULT_RESET='ACTIVE_BRANCH_VAULT_RESET'


export const VAULT_BALANCE_REQUEST='VAULT_BALANCE_REQUEST'
export const VAULT_BALANCE_SUCCESS='VAULT_BALANCE_SUCCESS'
export const VAULT_BALANCE_FAIL='VAULT_BALANCE_FAIL'
export const VAULT_BALANCE_RESET='VAULT_BALANCE_RESET'


export const USER_VAULT_REQUEST_SAVE='USER_VAULT_REQUEST_SAVE'
export const USER_VAULT_BALANCE_SUCCESS_SAVE='USER_VAULT_BALANCE_SUCCESS_SAVE'
export const USER_VAULT_BALANCE_FAIL_SAVE='USER_VAULT_BALANCE_FAIL_SAVE'
export const USER_VAULT_BALANCE_RESET_SAVE='USER_VAULT_BALANCE_RESET_SAVE'


export const TREASURE_TODAY_REQUEST='TREASURE_TODAY_REQUEST'
export const TREASURE_TODAY_SUCCESS='TREASURE_TODAY_SUCCESS'
export const TREASURE_TODAY_FAIL='TREASURE_TODAY_FAIL'
export const TREASURE_TODAY_RESET='TREASURE_TODAY_RESET'


export const TREASURE_SETTING_REQUEST='TREASURE_SETTING_REQUEST'
export const TREASURE_SETTING_SUCCESS='TREASURE_SETTING_SUCCESS'
export const TREASURE_SETTING_FAIL='TREASURE_SETTING_FAIL'
export const TREASURE_SETTING_RESET='TREASURE_SETTING_RESET'


export const TREASURE_SETTING_REQUEST_LOAD='TREASURE_SETTING_REQUEST_LOAD'
export const TREASURE_SETTING_SUCCESS_LOAD='TREASURE_SETTING_SUCCESS_LOAD'
export const TREASURE_SETTING_FAIL_LOAD='TREASURE_SETTING_FAIL_LOAD'
export const TREASURE_SETTING_RESET_LOAD='TREASURE_SETTING_RESET_LOAD'

export const VAULT_REQUEST_SAVE='VAULT_REQUEST_SAVE'
export const VAULT_SUCCESS_SAVE='VAULT_SUCCESS_SAVE'
export const VAULT_FAIL_SAVE='VAULT_FAIL_SAVE'
export const VAULT_RESET_SAVE='VAULT_RESET_SAVE'

export const UPDATE_USER_VAULT_REQUEST='UPDATE_USER_VAULT_REQUEST'
export const UPDATE_USER_VAULT_SUCCESS='UPDATE_USER_VAULT_SUCCESS'
export const UPDATE_USER_VAULT_FAIL='UPDATE_USER_VAULT_FAIL'
export const UPDATE_USER_VAULT_RESET='UPDATE_USER_VAULT_RESET'


export const USER_VAULT_REQUEST='USER_VAULT_REQUEST'
export const USER_VAULT_SUCCESS='USER_VAULT_SUCCESS'
export const USER_VAULT_FAIL='USER_VAULT_FAIL'
export const USER_VAULT_RESET='USER_VAULT_RESET'


