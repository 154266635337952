import React  ,{useState ,useEffect}from 'react'
import { Modal } from 'react-bootstrap';
import { registerProduct } from '../actions/settingAction';
import { useDispatch ,useSelector } from 'react-redux';
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2'
import { PRODUCT_REGISTRATION_RESET } from '../constants/settingConstants';

import { updateProductAction } from '../actions/settingAction';

import { productList } from '../actions/settingAction';

import Multiselect from 'multiselect-react-dropdown';


function ProductServices() {

    const [creatNewProduct ,setCreatNewProduct]=useState(false)

    const dispatch=useDispatch()


  const registerProductReducer = useSelector(state => state.registerProductReducer)
  const { registerProductLoading ,productSuccess  ,registerProductInfo ,error}=registerProductReducer


  const productReducer = useSelector(state => state.productReducer)
  const { products ,loadingproduct }=productReducer


  const updateProduct = useSelector(state => state.updateProduct)
  const { productUpdateLoading ,productUpdate }=updateProduct


  const ChartOfAccountReducer = useSelector(state => state. ChartOfAccountReducer)
    const {loadingchartOfAccount ,chartOfAccountsu ,chartOfAccount}= ChartOfAccountReducer

  

    const[product_code ,Setproduct_code]=useState()
    const [product_name ,Setproduct_name]=useState()

    const [Charges_Per_Month_ind ,setCharges_Per_Month_ind]=useState()
    const[Charges_Per_Month_corpor ,setCharges_Per_Month_corpor]=useState()


    const [idr_ind ,setidr_ind]=useState()
    const[idr_corpor ,setidr_corpor]=useState()


    const [mob_ind ,setmob_ind]=useState()
    const[mob_corpor ,setmob_corpor]=useState()

    const [withdraw_count_ind ,setwithdraw_count_ind]=useState()
    const[withdraw_count_corpor ,setwithdraw_count_corpor]=useState()

    const [cot_ind ,setcot_ind]=useState()
    const[cot_corpor ,setcot_corpor]=useState()

    const [save ,setSave] = useState(true)
    const [saveUpdate ,setsaveUpdate] = useState(null)
    const [account ,setaccount]=useState('Select')
    const[acc_code ,setacc_code]=useState()
    const [acc_type ,setacc_type]=useState()
    const[acc_statement ,setacc_statement]=useState()


    
  
const successMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: message ,
      showConfirmButton: false,
      timer: 1500
    });
  }


  const successError=(message) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: message ,
      showConfirmButton: false,
      timer: 1500000
      
    
    });

  }

   
    const creatNewProductFunctionOpen=() => {  
        setCreatNewProduct(true)

        Setproduct_code('')
        Setproduct_name('')

        setCharges_Per_Month_ind('')
        setCharges_Per_Month_corpor('')


        setidr_ind('')
        setidr_corpor('')


        setmob_ind('')
        setmob_corpor('')

        setwithdraw_count_ind('')
        setwithdraw_count_corpor('')

        setcot_ind('')
        setcot_corpor('')
        setaccount('Select')

        setacc_code('')
        setacc_type('')
        setacc_statement('')
        

        setSave(true)


    }

    const creatNewProductFunctionClose=() => {
        setCreatNewProduct(false)
    }


  const saveProduct=(e)=>{
    
    e.preventDefault();


    if(account=='Select' ||  account=='' || account=='-' ){

        successError('Please Select Map Account')
        
    }else {


    if(save){

        dispatch(registerProduct(product_code, product_name ,Charges_Per_Month_ind ,Charges_Per_Month_corpor ,idr_ind ,idr_corpor 
            ,mob_ind ,mob_corpor , withdraw_count_ind  ,withdraw_count_corpor ,cot_ind , cot_corpor ,account ,acc_code ,acc_type ,acc_statement
        ))
        
        successMessage('Product Successfully Registered')

    }else {

        dispatch(updateProductAction({product_code:product_code ,
            product_name ,
            Charges_Per_Month_ind ,
            Charges_Per_Month_corpor,

            idr_ind ,
            idr_corpor,

            mob_ind ,
            mob_corpor,

            withdraw_count_ind,
            withdraw_count_corpor,

            cot_ind,
            cot_corpor ,
            account ,
            acc_code ,
            acc_type ,
            acc_statement
        }))

        setsaveUpdate(true)

        successMessage('Product Successfully Updated')
    }
    
}

  }


  useEffect(()=>{

    if(productSuccess){

        Setproduct_code('')
        Setproduct_name('')

        setCharges_Per_Month_ind('')
        setCharges_Per_Month_corpor('')


        setidr_ind('')
        setidr_corpor('')


        setmob_ind('')
        setmob_corpor('')

        setwithdraw_count_ind('')
        setwithdraw_count_corpor('')

        setcot_ind('')
        setcot_corpor('')

        setaccount('Select')

        setacc_code('')
        setacc_type('')
        setacc_statement('')
        
        dispatch({type:PRODUCT_REGISTRATION_RESET})

        dispatch(productList())

        

    }

    if(error){
        successError('Error registering Product. Please Check Product Code')
       dispatch({type:PRODUCT_REGISTRATION_RESET})
    }


    if(saveUpdate){

        
        setsaveUpdate(true)

        dispatch(productList())

    }

  },[productSuccess ,error ,productUpdate])

               
  const getProductDetails=(product)=>{

    try {

        const getproduct=products.filter(pro=>{
            return pro.product_code==(product)  
        })
    
        //console.log(getproduct[0].product_name)
    
        creatNewProductFunctionOpen()

        Setproduct_code(getproduct[0].product_code)
        Setproduct_name(getproduct[0].product_name)
      
        setCharges_Per_Month_ind(getproduct[0].Charges_Per_Month_ind)
        setCharges_Per_Month_corpor(getproduct[0].Charges_Per_Month_corpor)


        setidr_ind(getproduct[0].idr_ind)
        setidr_corpor(getproduct[0].idr_corpor)


        setmob_ind(getproduct[0].mob_ind)
        setmob_corpor(getproduct[0].mob_corpor)

        setwithdraw_count_ind(getproduct[0].withdraw_count_ind)
        setwithdraw_count_corpor(getproduct[0].withdraw_count_corpor)

        setcot_ind(getproduct[0].cot_ind)
        setcot_corpor(getproduct[0].cot_corpor)
        setaccount(getproduct[0].account)

        setacc_code(getproduct[0].acc_code)
        setacc_type(getproduct[0].acc_type)
        setacc_statement(getproduct[0].acc_statement)

        
        setSave(false)
    
        
    } catch (error) {
        
    }
    

  }


  const mapAccount=(e)=>{

    const selectedAccount=chartOfAccount.find(obj=>obj.name==e.target.value)
    console.log(selectedAccount)

    setaccount(selectedAccount.name)
   // console.log(account)
   setacc_code(selectedAccount.acc_code)
   setacc_type(selectedAccount.acc_type)
   setacc_statement(selectedAccount.acc_statement)

  }


  return (
    <div >

       
            <h3>Products</h3>

            <hr/>

            <button  onClick={creatNewProductFunctionOpen} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Product </button>

            <div className='row'>
                <div className='col-md-6'>
                     
                </div>
            </div>

            <div className='solcon overflow-auto vh-100'>

            {loadingproduct && <Loader/>}

                <div className='bra row me-2'>

                {products.map(product=>(

                <div className='col-md-4 mb-4'>
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">{product.product_name}</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Product Code : {product.product_code}</li>

                             <h5 className="card-title">Initial Deposit Required (IDR) GH₵</h5>
                            <li className="list-group-item"> Individual Account: {product.idr_ind}</li>
                            <li className="list-group-item"> Corporate Account: {product.idr_corpor}</li> 
                            
                        </ul>

                        </div>
                        <button  onClick={()=>getProductDetails(product.product_code)} className='show_info_btn'>View Product Details</button>
                    </div>
                    </div>

                                
                ))}

                </div>

            
             </div>

             
        <Modal  size='lg' show={creatNewProduct} onHide={creatNewProductFunctionClose} backdrop="static" keyboard={false}>
            <Modal.Header  closeButton>
                <Modal.Title>Product Information</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className=' card'>
                        <div className='card-body'>

                        
                        <form onSubmit={saveProduct}>

                        {registerProductLoading && <Loader/>}

                        {productUpdateLoading && <Loader/>}

                        
                            <div className='row'>
                                <div className='col-md-4'>

                                <div className='form-group'>
                                <strong className='lable' for='product code'><samp className='text-danger'>* </samp> Product Code</strong>
                               { 
                               save ?  <input type='number' name='product code'  required className='form-control' onChange={(e)=>Setproduct_code(e.target.value)}  value={product_code}/>
                               :
                               <input type='number' name='product code' disabled required className='form-control' onChange={(e)=>Setproduct_code(e.target.value)}  value={product_code}/>

                               }
                            
                            </div>

                                </div>
                                <div className='col-md-4'>

                                <div className='form-group '>
                                <strong className='lable' for='product Name'><samp className='text-danger'>* </samp> Product Name</strong>
                                <input type='text' name='product Name' required className='form-control' onChange={(e)=>Setproduct_name(e.target.value)}  value={product_name}/>
                            </div>

                                </div>

                                <div className='col-md-4'>

                                <div className='form-group '>
                                <label className='lable' for='product Name'><samp className='text-danger'>* </samp> Map Accounts</label>
                                
                                    <select required  className='form-control'onChange={mapAccount} value={account}  >
                                            <option required disabled selected value="Select" >Select</option>
                                            {chartOfAccount.map((chart)=>(
                                                <option value={chart.name}>{chart.name}</option>
                                            ))} 
                                                
                                        </select>
                                </div>

                                </div>
                            </div>
                            

                            <div className='row'>
                                <div className='col-md-6 '>

                                <div className='card mb-3 mt-3'>
                                    
                                    <div className='card-body'>
                                    <div className='mb-2'><strong >Service Charges(P/M) GH₵</strong></div>
                                    <div className='row'>

                                        <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='lable' for='Individual'><samp className='text-danger'>* </samp> Individual Account</label>
                                            <input type='number' name='Individual' onChange={(e)=>setCharges_Per_Month_ind(e.target.value)}  value={Charges_Per_Month_ind} required className='form-control'/>
                                        </div>

                                        </div>


                                        <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='lable' for='SME'><samp className='text-danger'>* </samp> Corporate Account</label>
                                            <input type='number' name='SME' onChange={(e)=>setCharges_Per_Month_corpor(e.target.value)}  value={Charges_Per_Month_corpor} required className='form-control'/>
                                        </div>

                                        </div>
                                            
                                        </div>

                                    </div>
                                    
                                </div>
                                
                                
                                <div className='card mb-3'>
                                    <div className='card-body'>
                                        <div className='mb-3'><strong >Initial Deposit Required (IDR) GH₵</strong></div>

                                        <div className='row'>

                                            <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label className='lable' for='Individual'><samp className='text-danger'>* </samp> Individual Account</label>
                                                <input type='number' value={idr_ind} onChange={(e)=>setidr_ind(e.target.value)} name='Individual' required className='form-control'/>
                                            </div>

                                            </div>

                                           

                                            <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label className='lable' for='SME'><samp className='text-danger'>* </samp> Corporate Account</label>
                                                <input type='number' value={idr_corpor} onChange={(e)=>setidr_corpor(e.target.value)}  name='SME' required className='form-control'/>
                                            </div>

                                            </div>
                                                
                                            </div>
                                    
                                    </div>
                                </div>
                              
                                    <div className='card'>
                                    <div className='card-body'>
                                    <div className='mb-3'><strong >Default Withdrawal Cost</strong></div>
                                    <div className='row '>

                                            <div className='col-md-6'>
                                            <div className='form-group mb-2'>
                                                <label className='lable' for='Individual'><samp className='text-danger'>* </samp>  Individual Account </label>
                                                <input type='number' value={cot_ind} onChange={(e)=>setcot_ind(e.target.value)} name='Individual' required className='form-control'/>
                                            </div>

                                            </div>

                                           

                                            <div className='col-md-6'>
                                            <div className='form-group mb-2'>
                                                <label className='lable' for='SME'> <samp className='text-danger'>* </samp> Corporate Account</label>
                                                <input type='number' value={cot_corpor} onChange={(e)=>setcot_corpor(e.target.value)} name='SME' required className='form-control'/>
                                            </div>

                                        </div>
                                                
                                        </div>
                                        </div>
                                        </div>
                                </div>


                                <div className='col-md-6'>

                                <div className='card mt-3'>
                                
                                <div className='card-body'>

                                <div className='mb-3'><strong >Operating Balance Required (MOB) GH₵</strong></div>

                                    <div className='row'>

                                        <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='lable' for='Individual'> <samp className='text-danger'>* </samp>Individual Account</label>
                                            <input type='number'  value={mob_ind} onChange={(e)=>setmob_ind(e.target.value)} name='Individual' required className='form-control'/>
                                        </div>

                                        </div>

                                       

                                        <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='lable' for='SME'>Corporate Account</label>
                                            <input type='number' value={mob_corpor} onChange={(e)=>setmob_corpor(e.target.value)} name='SME' required className='form-control'/>
                                        </div>

                                        </div>
                                            
                                        </div>
                                        </div>
                                        </div>

                                        
                                        <div className='card mt-3'>
                                        <div className='card-body'>
                                        <div className='mb-3'><strong >Maximum Number of withdrawal (P/M)</strong></div>

                                        <div className='row'>

                                        <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='lable' for='Individual'><samp className='text-danger'>* </samp> Individual Account </label>
                                            <input type='number'  value={withdraw_count_ind} onChange={(e)=>setwithdraw_count_ind(e.target.value)} name='Individual' required className='form-control'/>
                                        </div>

                                        </div>

                                       
                                        <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='lable' for='SME'><samp className='text-danger'>* </samp> Corporate Account</label>
                                            <input type='number'  value={withdraw_count_corpor} onChange={(e)=>setwithdraw_count_corpor(e.target.value)} name='SME' required className='form-control'/>
                                        </div>

                                        </div>
                                            
                                        </div>
                                        </div>
                                        </div>

                                            {
                                                save ? 
                                                 <button type='submit' className="show_info_btn mt-5">Save Product Information</button> 
                                                :
                                                 <button type='submit' className="show_info_btn mt-5">Update Product Information</button>
                                            }
                                       

                                        </div>
                                      </div>
                        </form>
                        </div>
                    </div>

                </Modal.Body>
                


        </Modal>

      
    </div>
  )
}

export default ProductServices
